// ------------- DEPENDENCIES -------------- //

import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {
  withStyles,
  FormControl,
  Input,
  InputLabel,
  Button,
} from '@material-ui/core';

// ------------- LOCAL DEPENDENCIES -------------- //

import { Auth } from '../firebase';
import * as Routes from '../constants/routes';

// ------------- CONSTANTS & GLOBAL VARS -------------- //

const INITIAL_STATE = {
  email: '',
  error: null,
};

const styles = ({
  button: {
    fontSize: 13,
  },
  padding: {
    padding: 10,
  },
  margin: {
    margin: 10,
  },
  title: {
    textAlign: 'left',
    color    : '#707070',
  }
});

// ------------- MAIN -------------- //

const byPropKey = (_PropertyName, _Value) => () => ({
  [_PropertyName]: _Value,
});

const PasswordForgetPage = () =>
  <div>
    <h1>Password Forget Page</h1>
    <PasswordForgetForm />
  </div>

class PasswordForgetFormComponent extends Component {
  constructor(props) {
    super(props);
    
    this.state = { ...INITIAL_STATE };
  }

  onSubmit = (_Event) => {
    const { email } = this.state;

    Auth.doPasswordReset(email)
      .then(() => {
        this.setState({ ...INITIAL_STATE });
      })
      .catch(error => {
        this.setState(byPropKey('error', error));
      });
    
    _Event.preventDefault();
  }

  render() {
    const {
      email,
      error,
    } = this.state;
    const { classes, } = this.props;

    const isInvalid = email === '';

    return (
      <form
        className={ classes.padding }>
        <h5
          className={ [ classes.margin, classes.title ].join(' ') }>
          Forgot your password?
        </h5>
        <FormControl fullWidth>
          <InputLabel htmlFor='password-forget-email'>Email</InputLabel>
          <Input
            className = {[classes.margin].join(' ')}
            id        = 'password-forget-email'
            value     = {email}
            onChange  = {event => this.setState(byPropKey('email', event.target.value))}>
          </Input>
        </FormControl>
        <Button
          className = {[classes.margin, classes.button].join(' ')}
          size      = 'large'
          onClick   = {this.onSubmit}
          color     = 'primary'
          disabled  = {isInvalid}>
          Reset My Password
        </Button>
        { error && <p>{error.message}</p> }
      </form>
    );
  }
}

const PasswordForgetLink = () =>
  <p>
    <Link to={Routes.PASSWORD_FORGET}>Forgot Password</Link>
  </p>

export default withStyles(styles)(PasswordForgetPage);

const PasswordForgetForm = withStyles(styles)(PasswordForgetFormComponent);

export {
  PasswordForgetForm,
  PasswordForgetLink,
};