// ------------- DEPENDENCIES -------------- //

import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
  Typography,
  LinearProgress,
} from '@material-ui/core';
import {
  FilePond,
  registerPlugin,
  File,
} from 'react-filepond';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';

import { Db, } from '../firebase';
import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';
registerPlugin(FilePondPluginImagePreview);

// ------------- MAIN -------------- //

/**
 * Function to display component with slide-up transition.
 *
 * @param {JSON} props -> Parent props.
 */
function Transition(props) {
  return <Slide direction="up" {...props} />;
}

class BulkPayDialog extends React.Component {
  state = {
    open                         : false,
    assignmentContractorPaidFiles: [],
    loading                      : false,
    progress                     : 0,
    numUpdated                   : null,
  };

  /**
   * Monitors "open" prop, and displays component if true.
   *
   * @param {JSON} nextProps -> Updated props.
   * @param {JSON} prevState -> Previous component state.
   */
  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.open !== nextProps.open) {
      return {
        open: nextProps.open,
      };
    }

    return null;
  }
  
  handleFileUpdate = (_FileItems) => {
    this.setState({
      assignmentContractorPaidFiles: _FileItems.map( (fileItem, i) => {
        return {
          file    : fileItem.file,
          fileName: `Contractor Payment File ${i + 1}${fileItem.filename.substring(fileItem.filename.lastIndexOf('.'))}`,
        };
      }),
    });
  }

  doCancel = () => {
    const { onCancelHandler } = this.props;

    this.setState({
      assignmentContractorPaidFiles: [],
    });

    if (onCancelHandler !== null && onCancelHandler !== undefined) {
      onCancelHandler();
    }
  }

  doOK = () => {
    const {
      onOKHandler,
      contractorID,
      excluded,
    } = this.props;
    const {
      assignmentContractorPaidFiles,
    } = this.state;

    this.setState({
      loading : true,
      progress: 0,
    });

    const progCB = (d) => {
      console.log(`PROG: ${d}`);
      this.setState({ progress: d });
    }

    Db.doBulkContractorPay(
      contractorID,
      assignmentContractorPaidFiles,
      progCB,
      excluded,
      ).then((_NumUpdated) => {
        this.setState({
          assignmentContractorPaidFiles: [],
          numUpdated                   : _NumUpdated,
          loading                      : false,
        });
        setTimeout(() => {
          this.setState({
            numUpdated: null,
          });
          onOKHandler();
        }, 3000);
    });
  }

  renderContent = (assignmentContractorPaidFiles, totalFees) => (
    <DialogContent id="alert-dialog-slide-description">
      <Typography
        align = 'center'
        gutterBottom
        variant='h3'>
        {`Total fees: ${totalFees}`}
      </Typography>
      Proof of Payment
      <FilePond
        isMulti={true}
        allowMultiple={true}
        onupdatefiles = {this.handleFileUpdate}>
        {assignmentContractorPaidFiles.map((f) =>
          <File key={f.file} src={f.file} origin="local" />
        )}
      </FilePond>
      <Typography
        align = 'center'
        gutterBottom
        variant='h4'>
        Please ensure the above information is correct, as this action cannot be undone.
      </Typography>
    </DialogContent>
  )

  renderFinishedContent = (numUpdated) => (
    <DialogContent id="alert-dialog-slide-description">
      <Typography
        align = 'center'
        gutterBottom
        variant='h3'>
        {numUpdated > 0
          ? `Payment successful, ${numUpdated} assignments updated`
          : `There was a problem processing the payment. Please try again later.`}
      </Typography>
    </DialogContent>
  )

  render() {
    const {
      totalFees,
      onOKHandler,
    } = this.props;
    const {
      assignmentContractorPaidFiles,
      loading,
      progress,
      numUpdated,
    } = this.state;

    return (
      <div>
        <Dialog
          fullWidth
          open                = {this.state.open}
          TransitionComponent = {Transition}
          keepMounted
          onClose          = {this.handleClose}
          aria-labelledby  = "alert-dialog-slide-title"
          aria-describedby = "alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">
            Contractor Bulk Payment
          </DialogTitle>
            {numUpdated === null ? this.renderContent(assignmentContractorPaidFiles, totalFees) : this.renderFinishedContent(numUpdated)}
            {loading && <LinearProgress value={progress} variant='determinate' />}
          <DialogActions>
            <Button disabled={loading} onClick={() => this.doCancel()} color="primary">Cancel</Button>
            { onOKHandler ? <Button disabled={assignmentContractorPaidFiles.length === 0 || loading} onClick={() => this.doOK()} color="primary">OK</Button> : null }
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default BulkPayDialog;