// ------------- DEPENDENCIES -------------- //

import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Slide,
  Checkbox,
  FormControl,
  InputLabel,
  Input,
  InputAdornment,
} from '@material-ui/core';

import { Db, } from '../firebase';

// ------------- MAIN -------------- //

/**
 * Function to display component with slide-up transition.
 *
 * @param {JSON} props -> Parent props.
 */
function Transition(props) {
  return <Slide direction="up" {...props} />;
}

class RefundDialog extends React.Component {
  state = {
    supervisorPenalties: {},
  };

  doOK = () => {
    const {
      onOKHandler,
      assignmentDocID,
      contractorID,
    } = this.props;
    const {
      supervisorPenalties,
      contractorPenalty,
    } = this.state;

    Db.doFlagAssignmentAsRefunded(assignmentDocID, supervisorPenalties, {
      id     : contractorID,
      penalty: contractorPenalty,
    }).then(() => {
      onOKHandler();
    });
  }

  handleSupervisorFeeChange = (id, value) => {
    this.setState(prevState => ({
      supervisorPenalties: {
        ...prevState.supervisorPenalties,
        [id]: value,
      },
    }));
  }

  handleContractorPenaltyChange = (value) => {
    this.setState({
      contractorPenalty: value,
    });
  };

  render() {
    const {
      onOKHandler,
      onCancelHandler,
      supervisorIDs,
      supervisorFees,
      open,
      contractorID,
      contractorName,
      contractorFee,
    } = this.props;
    const {
      supervisorPenalties,
      contractorPenalty,
    } = this.state;
    
    console.log(supervisorFees);
    return (
      <div>
        <Dialog
          fullWidth keepMounted
          open                = {open}
          TransitionComponent = {Transition}
          onClose          = {this.handleClose}
          aria-labelledby  = "alert-dialog-slide-title"
          aria-describedby = "alert-dialog-slide-description">
          <DialogTitle id="alert-dialog-slide-title">
            Refund Client
          </DialogTitle>
          <p style={{ textAlign: 'center', width: '100%', }}>Supervisors at fault</p>
          <div style={{ padding: 25 }}>
            {supervisorIDs.map((id, i) => (
              <div key={id}
                style={{
                  display       : 'flex',
                  flexFlow      : 'row',
                  justifyContent: 'space-between',
                  alignItems    : 'center',
                }}>
                <span>{id}</span>
                <Checkbox
                  checked  = {supervisorPenalties.hasOwnProperty(id) && supervisorPenalties[id] !== '0.00' }
                  onChange = {evt => this.handleSupervisorFeeChange(id, evt.target.checked ? supervisorFees[i].label.substring(1) : '0.00')} />
              </div>
            ))}
            <p style={{ textAlign: 'center', width: '100%', }}>Contractor at fault</p>
            <div
              style={{
                display       : 'flex',
                flexFlow      : 'row',
                justifyContent: 'space-between',
                alignItems    : 'center',
              }}>
              <span>{contractorName && contractorName.value}</span>
              <Checkbox
                checked  = {contractorPenalty && contractorPenalty !== '0.00'}
                onChange = {evt => this.handleContractorPenaltyChange(evt.target.checked ? contractorFee : '0.00')} />
            </div>
          </div>
          <DialogActions>
            {onCancelHandler && <Button style={{color: '#FF0000'}} onClick={() => onCancelHandler()}>Cancel</Button>}
            {onOKHandler && <Button style={{color: '#00FF00'}} onClick={this.doOK}>REFUND</Button>}
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default RefundDialog;