// ------------- DEPENDENCIES -------------- //

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import {
  withStyles,
  Paper,
  Button,
} from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import Select from 'react-select';

// ------------- LOCAL DEPENDENCIES -------------- //

import withAuthorization from './withAuthorization';
import { Db } from '../firebase';
import * as Actions from '../reducers/action_types';
import * as Routes from '../constants/routes';
import ContractorsList from './ContractorsList';

// ------------- CONSTANTS & GLOBAL VARS -------------- //

const styles = ({
  root: {
    width : '100%',
    height: '100%',
  },
  center: {
    display       : 'flex',
    justifyContent: 'center',
    alignItems    : 'center',
    margin        : '0 auto',
  },
  hCenter: {
    textAlign: 'center',
  },
  filterWrapper: {
    padding: 10,
  },
  button: {
    fontSize  : 15,
    marginLeft: 20,
  },
  select: {
    width: 150,
  },
});

// ------------- MAIN -------------- //

class ContractorsPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      contractors    : null,
      contractorNames: [],
      filterValue    : '',
      filterSelect   : '',
    };

    this.applyFilter           = this.applyFilter.bind(this);
    this.handleContractorClick = this.handleContractorClick.bind(this);
  }

  componentDidMount() {
    Db.doDownloadContractors().then((_Contractors) => {
      if (_Contractors !== null) {
        this.setState({
          contractors: _Contractors,
        });
      }
    });
    Db.doDownloadContractorNames().then((contractorNames) => {
      contractorNames = contractorNames.map(c => {
        return {
          label: c,
          value: c.split(' - ')[1],
        };
      });

      this.setState({
        contractorNames,
      });
    });
  }

  handleContractorClick(_Contractors) {
    const { onSetActiveContractor, history, } = this.props;
    onSetActiveContractor(_Contractors);
    history.push(Routes.CONTRACTOR_DATA);
  }

  applyFilter() {
    this.setState({
      contractors: null,
    });

    const {
      filterValue,
    } = this.state;

    if (filterValue === '') {
      Db.doDownloadContractors().then((_Contractors) => {
        if (_Contractors !== null) {
          this.setState({
            contractors: _Contractors,
          });
        }
      });
    } else {
      const filterField = `contractorID`;

      Db.doDownloadFilteredContractors([{
        filter: filterField,
        value : filterValue.toLowerCase(),
      }]).then((results) => {
        if (results !== null) {
          this.setState({
            contractors: results,
          });
        }
      });
    }
  }

  onFilterSelect = (val) => {
    if (val === undefined || val === null) { return; }

    this.setState({
      filterValue : val.value,
      filterSelect: val,
    });
  }

  clearFilter = () => {
    this.setState({
      filterValue : '',
      filterSelect: {
        value: '',
        label: 'Search'
      }
    }, () => {
      this.applyFilter();
    });
  }
  
  render() {
    const {
      contractors,
      contractorNames,
      filterSelect,
    } = this.state;
    const { classes, } = this.props;

    return (
      <div
        className={classes.root}>
        <Paper
          className={ [classes.filterWrapper, classes.center].join(' ') }>
          <Button
            className = {[classes.margin, classes.button].join(' ')}
            size      = 'large'
            onClick   = {() => this.clearFilter()}
            color     = 'primary'
            disabled  = {false}>
            Clear
          </Button>
          <Select
            placeholder = 'Search'
            className   = {[classes.textField, classes.padding, classes.select].join(' ')}
            value       = {filterSelect}
            options     = {contractorNames}
            onChange    = {this.onFilterSelect} />
          <Button
            className = {[classes.margin, classes.button].join(' ')}
            size      = 'large'
            onClick   = {() => this.applyFilter()}
            color     = 'primary'
            disabled  = {false}>
            Apply
          </Button>
        </Paper>
        <ContractorsList
          onItemClick = {this.handleContractorClick}
          contractors = {contractors} />
      </div>
    );
  }
}

const mapStateToProps = (_State) => ({
  authUser: _State.sessionState.authUser,
});

const mapDispatchToProps = (_Dispatch) => ({
  onSetActiveContractor: (activeContractor) => _Dispatch({ type: Actions.ACTIVE_CONTRACTOR_SET, activeContractor }),
});

const authCondition = (authUser) => !!authUser;

export default compose(
  withAuthorization(authCondition),
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
  withRouter
)(ContractorsPage);