// ------------- DEPENDENCIES -------------- //

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import {
  withStyles,
  List,
  ListItem,
  ListItemText,
} from '@material-ui/core';

// ------------- LOCAL DEPENDENCIES -------------- //

// ------------- CONSTANTS & GLOBAL VARS -------------- //

const styles = ({
  root: {
    width : '100%',
    height: '100%',
  },
  center: {
    display       : 'flex',
    justifyContent: 'center',
    alignItems    : 'center',
    margin        : '0 auto',
  },
  hCenter: {
    textAlign: 'center',
  },
  supervisorField: {
    color     : '#000000',
    fontSize  : '14px !important',
    fontWeight: 400,
  },
});

// ------------- MAIN -------------- //

class SupervisorsList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      supervisors: [],
    };
  }

  static parseJSON(_JSON) {
    const parsedData = [];
    
    for (const key in _JSON) {
      parsedData.push({
        id           : _JSON[key].id,
        supervisorID : _JSON[key].supervisorID,
        supervisorFee: _JSON[key].supervisorFee,
        valueToDate  : _JSON[key].valueToDate,
        name         : _JSON[key].supervisorName,
        penalty      : _JSON[key].penalty,
      });
    }

    return parsedData;
  }

  static getDerivedStateFromProps(_Props, _CurrentState) {
    if (_CurrentState.supervisors !== _Props.supervisors) {
      return {
        supervisors: SupervisorsList.parseJSON(_Props.supervisors),
      };
    }

    return null;
  }

  onItemClick(_Item) {
    const { onItemClick, } = this.props;

    if (onItemClick !== null && onItemClick !== undefined) {
      onItemClick(_Item);
    }
  }
  
  render() {
    const { classes, }     = this.props;
    const { supervisors, } = this.state;

    return (
      <div
        className={classes.root}>
        <List
          className={classes.list}>
          {
            supervisors.map((supervisor, index) => {
              return (
                <ListItem button
                  key       = {index}
                  className = { classes.supervisorItem }
                  onClick   = {() => this.onItemClick(supervisor)}>
                  <ListItemText
                    classes   = {{ primary: classes.supervisorField }}
                    primary   = {`${supervisor.name}`}
                    secondary = {`${supervisor.supervisorID}`}/>
                </ListItem>
              );
            })
          }
        </List>
      </div>
    );
  }
}

const mapStateToProps = (_State) => ({
  authUser: _State.sessionState.authUser,
});

export default compose(
  connect(mapStateToProps),
  withStyles(styles),
)(SupervisorsList);