// ------------- DEPENDENCIES -------------- //

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import {
  Card,
  CardHeader,
  withStyles,
  FormControl,
  Input,
  InputLabel,
  Button,
  InputAdornment,
} from '@material-ui/core';

// ------------- LOCAL DEPENDENCIES -------------- //

import withAuthorization from './withAuthorization';
import { Db } from '../firebase';
import SnackbarAlert from './SnackbarAlert';

import '../css/AssignmentUploadPage.css';

// ------------- CONSTANTS & GLOBAL VARS -------------- //

const styles = ({
  margin: {
    margin: 10,
  },
  card: {
    padding: 30,
  },
  root: {
    marginTop: '3%',
    width    : '100%',
    padding  : '50px 10% 30px 10%',
  },
  title: {
    marginBottom: 5,
    fontSize    : 16,
    color       : '#707070',
  },
  textField: {
    marginLeft : 20,
    marginRight: 20,
    fontSize   : 14,
  },
  button: {
    fontSize: 15,
  },
  noPadding: {
    padding: '0px !important',
  },
  dateInput: {
    marginLeft  : '0px !important',
    paddingRight: '20px',
  },
});

// ------------- MAIN -------------- //

class CreateSupervisorPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      supervisorName        : '',
      supervisorNotes       : '',
      supervisorID          : 'N/A',
      supervisorFee         : 0,
      supervisorCreateStatus: null,
      creatingSupervisor    : false,
    };

    this.handleCreateButtonClick             = this.handleCreateButtonClick.bind(this);
    this.renderSupervisorCreateAlert         = this.renderSupervisorCreateAlert.bind(this);
    this.assertSupervisorCreateNotInProgress = this.assertSupervisorCreateNotInProgress.bind(this);
    this.getSupervisorFeeValidationState     = this.getSupervisorFeeValidationState.bind(this);
  }

  // Field validation states.
  
  getSupervisorNameValidationState() {
    const {
      supervisorName,
    } = this.state;

    if (supervisorName === null
        || supervisorName === undefined
        || supervisorName.length === 0) {
      return 'error';
    } else {
      return 'success';
    }
  }

  getSupervisorFeeValidationState() {
    const {
      supervisorFee,
    } = this.state;

    if (supervisorFee === null
        || supervisorFee === undefined) {
      return 'error';
    } else {
      return 'success';
    }
  }

  assertSupervisorCreateNotInProgress() {
    const {
      creatingSupervisor,
    } = this.state;

    if (creatingSupervisor === null
      || creatingSupervisor === undefined
      || creatingSupervisor) {
      return 'error';
    } else {
      return 'success';
    }
  }

  handleCreateButtonClick(_Evt) {
    _Evt.preventDefault();

    const {
      supervisorName,
      supervisorNotes,
      supervisorFee,
    } = this.state;
    const { authUser } = this.props;

    this.setState({
      creatingSupervisor: true,
    });

    Db.doCreateSupervisor(authUser.uid, {
      supervisorName: supervisorName.toLowerCase(),
      supervisorFee : supervisorFee / 100,
      notes         : supervisorNotes,
    }).then((newID) => {
      console.log(newID);
      this.setState({
        supervisorCreateStatus: true,
        supervisorID          : newID,
        supervisorName        : '',
        supervisorFee         : 0,
        supervisorNotes       : '',
        creatingSupervisor    : false,
      });
    });
  }

  renderSupervisorCreateAlert() {
    const { supervisorCreateStatus, } = this.state;

    return (
      <SnackbarAlert
        open    = { supervisorCreateStatus !== null }
        message = { supervisorCreateStatus
          ? `The supervisor has been successfully created!`
          : `There was an error, please try again later.` } />
    );
  }

  render() {
    const {
      supervisorName,
      supervisorNotes,
      supervisorID,
      supervisorFee
    } = this.state;
    const { classes, } = this.props;

    const validationState = this.getSupervisorNameValidationState() === 'success'
                            && this.assertSupervisorCreateNotInProgress() === 'success'
                            && this.getSupervisorFeeValidationState() === 'success';

    return (
      <div className={ classes.root }>
        { this.renderSupervisorCreateAlert() }
        <Card
          elevation = { 8 }
          className = { classes.card }>
          <CardHeader
            classes = {{ title: classes.title, }}
            title   = 'Create a New Supervisor' />
          <div className={ [ classes.container, classes.margin ].join(' ') }>
            <FormControl fullWidth>
              <InputLabel htmlFor='supervisor-name-field'>Supervisor Name</InputLabel>
              <Input
                className = {[classes.textField, classes.margin].join(' ')}
                id        = 'supervisor-name-field'
                value     = {supervisorName}
                onChange  = {evt => this.setState({ supervisorName: evt.target.value, })}>
              </Input>
            </FormControl>
            <FormControl fullWidth>
              <InputLabel htmlFor='supervisor-id-field'>Supervisor ID</InputLabel>
              <Input
                className    = {[classes.textField, classes.margin].join(' ')}
                id           = 'supervisor-id-field'
                value        = {supervisorID}
                defaultValue = 'N/A'
                disabled     = {true}
                InputProps   = {{
                  readOnly: true,
                }}>
              </Input>
            </FormControl>
            <FormControl fullWidth>
              <InputLabel htmlFor='fee-field'>Supervisor Fee</InputLabel>
              <Input
                endAdornment = {<InputAdornment position="end">%</InputAdornment>}
                type         = 'number'
                className    = {[classes.textField, classes.margin].join(' ')}
                id           = 'fee-field'
                value        = {supervisorFee}
                onChange     = {evt => this.setState({ supervisorFee: parseInt(evt.target.value), })}>
              </Input>
            </FormControl>
            <FormControl fullWidth>
              <InputLabel htmlFor='notes-field'>Notes</InputLabel>
              <Input
                rows = {5}
                multiline
                className = {[classes.textField, classes.margin].join(' ')}
                id        = 'notes-field'
                value     = {supervisorNotes}
                onChange  = {evt => this.setState({ supervisorNotes: evt.target.value, })}>
              </Input>
            </FormControl>
          </div>
          <div className='submit-buttons-wrapper'>
            <Button
              className = {[classes.margin, classes.button].join(' ')}
              size      = 'large'
              onClick   = {this.handleCreateButtonClick}
              color     = 'primary'
              disabled  = {!validationState}>
              Create
            </Button>
          </div>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = (_State) => ({
  authUser: _State.sessionState.authUser,
});

const mapDispatchToProps = (_Dispatch) => ({
});

const authCondition = (authUser) => !!authUser;

export default compose(
  withAuthorization(authCondition),
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
  withStyles(styles),
)(CreateSupervisorPage);