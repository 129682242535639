// ------------- DEPENDENCIES -------------- //

import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';

// ------------- LOCAL DEPENDENCIES -------------- //

import { Firebase } from '../firebase';

import * as Routes from '../constants/routes';

// ------------- MAIN -------------- //

const withAuthorization = (_AuthCondition) => (Component) => {
  class WithAuthorization extends React.Component {
    componentDidMount() {
      Firebase.Auth.onAuthStateChanged(authUser => {
        if (!_AuthCondition(authUser)) {
          this.props.history.push(Routes.SIGN_IN);
        }
      });
    }

    render() {
      return this.props.authUser ? <Component /> : null;
    }
  }

  const mapStateToProps = (_State) => ({
    authUser: _State.sessionState.authUser,
  });

  return compose(
    withRouter,
    connect(mapStateToProps),
  )(WithAuthorization);
}

export default withAuthorization;