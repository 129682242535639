// ------------- DEPENDENCIES -------------- //

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import Select from 'react-select';
import {
  Card,
  withStyles,
  FormControl,
  FormControlLabel,
  Input,
  InputLabel,
  Button,
  TextField,
  InputAdornment,
  Checkbox,
  FormGroup,
  Typography,
} from '@material-ui/core';
import {
  FilePond,
  registerPlugin,
} from 'react-filepond';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';

// ------------- LOCAL DEPENDENCIES -------------- //

import withAuthorization from './withAuthorization';
import {
  Db,
  Storage,
} from '../firebase';
import AlertDialogSlide from './AlertDialogSlide';
import RefundDialog from './RefundDialog';
import SnackbarAlert from './SnackbarAlert';
import * as Utils from '../Utils';
import FileList from './FileList';

import '../css/AssignmentDataPage.css';
import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';
registerPlugin(FilePondPluginImagePreview);

// ------------- CONSTANTS & GLOBAL VARS -------------- //

const CONTRACTOR = 0;
const CLIENT     = 1;
const SUPERVISOR = 2;
const APPROVAL   = 3;

/**
 * Enum to describe payment status, and the states
 * during the process of updating the status.
 */
const paidAlertState = {
  hidden    : 1,
  shown     : 2,
  successful: 3,
  failed    : 4,
};

/**
 * Enum to describe refund status, and the states
 * during the process of updating the status.
 */
const refundDialogState = {
  hidden    : 1,
  shown     : 2,
  successful: 3,
  failed    : 4,
};

const styles = ({
  margin: {
    margin: 10,
  },
  card: {
    padding: 30,
  },
  root: {
    marginTop: '3%',
    width    : '100%',
    padding  : '50px 10% 30px 10%',
  },
  cardHeaderWrapper: {
    padding       : 10,
    display       : 'flex',
    flexFlow      : 'row nowrap',
    justifyContent: 'space-between',
    alignItems    : 'center',
  },
  title: {
    marginBottom: 5,
    fontSize    : 16,
    color       : '#707070',
    fontWeight  : 700,
  },
  textField: {
    marginLeft : 20,
    marginRight: 20,
    fontSize   : 14,
  },
  button: {
    fontSize: 15,
  },
  noPadding: {
    padding: '0px !important',
  },
  dateInput: {
    marginLeft  : '0px !important',
    paddingRight: '20px',
  },
  fillContainerRemainder: {
    flex: 1,
  },
  vCenterPaidDates: {
    lineHeight: '53px',
  },
  label: {
    color     : '#00000088',
    fontSize  : '1rem',
    fontWeight: 'bold',
  },
});

// ------------- MAIN -------------- //

class AssignmentDataPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      initAssignmentClientID         : '',
      assignmentClientName           : '',
      assignmentClientID             : '',
      assignmentContractorName       : '',
      assignmentContractorID         : '',
      assignmentDueDate              : '',
      assignmentNotes                : '',
      assignmentValue                : '',
      assignmentContractorFee        : '',
      assignmentClientWeChatID       : '',
      assignmentClientPaid           : false,
      assignmentContractorPaid       : false,
      assignmentPaymentApproval      : false,
      assignmentSavedStatus          : null,
      assignmentFileDeleteStatus     : null,
      supervisorPaidStateChangeTarget: null,
      assignmentPaymentApprovalDate  : '',
      clientNames                    : [],
      contractorNames                : [],
      assignmentContractorPaidFiles  : [],
      assignmentSupervisorPaidFiles  : [],
      assignmentClientPaidFiles      : [],
      assignmentCriteriaFiles        : [],
      assignmentFiles                : [],
      assignmentSupervisorFees       : [],
      assignmentSupervisorIDs        : [],
      assignmentSupervisorsPaid      : [],
      assignmentSupervisorsPaidDates : [],
      clientPaidAlertState           : paidAlertState.hidden,
      contractorPaidAlertState       : paidAlertState.hidden,
      supervisorPaidAlertState       : paidAlertState.hidden,
      paymentApprovalAlertState      : paidAlertState.hidden,
      refundState                    : refundDialogState.hidden,
    };

    this.handleSaveButtonClick                 = this.handleSaveButtonClick.bind(this);
    this.renderAssignmentSavedStatusAlert      = this.renderAssignmentSavedStatusAlert.bind(this);
    this.renderAssignmentFileDeleteStatusAlert = this.renderAssignmentFileDeleteStatusAlert.bind(this);
    this.handleCheckboxChange                  = this.handleCheckboxChange.bind(this);
    this.uploadPaidState                       = this.uploadPaidState.bind(this);
    this.handleFileUpdate                      = this.handleFileUpdate.bind(this);
    this.handleCriteriaFileUpdate              = this.handleCriteriaFileUpdate.bind(this);
    this.handleClientPaidFileUpdate            = this.handleClientPaidFileUpdate.bind(this);
    this.handleContractorPaidFileUpdate        = this.handleContractorPaidFileUpdate.bind(this);
    this.handleSupervisorPaidFileUpdate        = this.handleSupervisorPaidFileUpdate.bind(this);
    this.mapFiles                              = this.mapFiles.bind(this);
    this.openFileInNewTab                      = this.openFileInNewTab.bind(this);
    this.openCriteriaFileInNewTab              = this.openCriteriaFileInNewTab.bind(this);
    this.openClientPaidFileInNewTab            = this.openClientPaidFileInNewTab.bind(this);
    this.openSupervisorPaidFileInNewTab        = this.openSupervisorPaidFileInNewTab.bind(this);
    this.openContractorPaidFileInNewTab        = this.openContractorPaidFileInNewTab.bind(this);
    this.deleteFile                            = this.deleteFile.bind(this);
    this.deleteContractorPaidFile              = this.deleteContractorPaidFile.bind(this);
    this.deleteSupervisorPaidFile              = this.deleteSupervisorPaidFile.bind(this);
    this.deleteClientPaidFile                  = this.deleteClientPaidFile.bind(this);
    this.deleteCriteriaFile                    = this.deleteCriteriaFile.bind(this);
    this.onClientSelect                        = this.onClientSelect.bind(this);
  }

  /**
   * Downloads assignent data based on 'activeAssignmentID'
   * set in global redux state. Assignment ID is the assignment's
   * document ID in Firebase Firestore, not the ID used in operations.
   */
  componentDidMount() {
    const { activeAssignmentID, }    = this.props;
    const { savedAssignmentLoaded, } = this.state;

    Db.doDownloadClientNames().then((clientNames) => {
      clientNames = clientNames.map(c => {
        return {
          label: c,
          value: c,
        };
      });

      this.setState({
        clientNames: clientNames,
      });
    });
    Db.doDownloadContractorNames().then((contractorNames) => {
      contractorNames = contractorNames.map(c => {
        return {
          label: c,
          value: c,
        };
      });

      this.setState({
        contractorNames: contractorNames,
      });
    });
    Db.doDownloadSupervisorNames().then((supervisorNames) => {
      if (supervisorNames === null) return;
      supervisorNames = supervisorNames.map(c => {
        return {
          label: c,
          value: c,
        };
      });

      this.setState({
        supervisorNames: supervisorNames,
      });
    });

    if (!savedAssignmentLoaded) {
      Db.doDownloadSavedAssignment(activeAssignmentID).then((savedData) => {
        if (savedData !== null && savedData !== undefined) {
          const parsedDueDate = Utils.timestampToReadableWithHM(savedData.dueDate);

          var parsedClientDate          = 'N/A';
          var parsedContractorDate      = 'N/A';
          let parsedPaymentApprovalDate = 'N/A';

          if (savedData.clientPaidDate !== undefined && savedData.clientPaidDate !== null) {
            parsedClientDate = Utils.timestampToReadable(savedData.clientPaidDate);
          }

          if (savedData.contractorPaidDate !== undefined && savedData.contractorPaidDate !== null) {
            parsedContractorDate = Utils.timestampToReadable(savedData.contractorPaidDate);
          }
          if (savedData.paymentApprovalDate !== undefined && savedData.paymentApprovalDate !== null) {
            parsedPaymentApprovalDate = Utils.timestampToReadable(savedData.paymentApprovalDate);
          }

          let parsedSupervisorsPaidDates = [];
          if (savedData.supervisorsPaidDates !== null && savedData.supervisorsPaidDates !== undefined) {
            parsedSupervisorsPaidDates = savedData.supervisorsPaidDates.map(d => {
              return d === null || d === undefined ? 'N/A': Utils.timestampToReadable(savedData.contractorPaidDate);
            });
          }

          let parsedFiles = [];
          if (savedData.fileNames !== undefined && savedData.fileNames !== null) {
            parsedFiles = savedData.fileNames.map(fileName => {
              return {
                fileName: fileName,
                file    : null,
              };
            });
          }

          let parsedCriteriaFiles = [];
          if (savedData.criteriaFileNames !== undefined && savedData.criteriaFileNames !== null) {
            parsedCriteriaFiles = savedData.criteriaFileNames.map(fileName => {
              return {
                fileName: fileName,
                file    : null,
              };
            });
          }

          let parsedSupervisorPaidFiles = [];
          if (savedData.supervisorPaidFileNames !== undefined && savedData.supervisorPaidFileNames !== null) {
            parsedSupervisorPaidFiles = savedData.supervisorPaidFileNames.map(fileName => {
              return {
                fileName: fileName,
                file    : null,
              };
            });
          }
          
          let parsedContractorPaidFiles = [];
          if (savedData.contractorPaidFileNames !== undefined && savedData.contractorPaidFileNames !== null) {
            parsedContractorPaidFiles = savedData.contractorPaidFileNames.map(fileName => {
              return {
                fileName: fileName,
                file    : null,
              };
            });
          }

          let parsedClientPaidFiles = [];
          if (savedData.clientPaidFileNames !== undefined && savedData.clientPaidFileNames !== null) {
            parsedClientPaidFiles = savedData.clientPaidFileNames.map(fileName => {
              return {
                fileName: fileName,
                file    : null,
              };
            });
          }

          let parsedClientName = {
            value: '',
            label: '',
          }
          if (savedData.clientName !== undefined
              && savedData.clientID !== undefined) {
            parsedClientName = {
              value: `${savedData.clientName} - ${savedData.clientID}`,
              label: `${savedData.clientName} - ${savedData.clientID}`,
            }
          }

          let parsedContractorName = {
            value: '',
            label: '',
          }
          if (savedData.contractorName !== undefined
              && savedData.contractorID !== undefined) {
                parsedContractorName = {
              value: `${savedData.contractorName} - ${savedData.contractorID}`,
              label: `${savedData.contractorName} - ${savedData.contractorID}`,
            }
          }

          let parsedSupervisorNames = [];
          if (savedData.supervisorNames !== undefined
              && savedData.supervisorIDs !== undefined) {
                parsedSupervisorNames = savedData.supervisorNames.map((s, i) => {
                  return {
                    value: `${s} - ${savedData.supervisorIDs[i]}`,
                    label: `${s} - ${savedData.supervisorIDs[i]}`,
                  };
                });
          }

          this.setState({
            assignmentRefunded           : !!savedData.refunded,
            initAssignmentClientID       : savedData.clientID !== undefined ? savedData.clientID            : '',
            assignmentClientID           : savedData.clientID !== undefined ? savedData.clientID            : '',
            assignmentClientName         : parsedClientName,
            assignmentContractorName     : parsedContractorName,
            assignmentSupervisorNames    : parsedSupervisorNames,
            assignmentContractorID       : savedData.contractorID !== undefined ? savedData.contractorID    : '',
            assignmentDueDate            : parsedDueDate,
            assignmentID                 : savedData.assignmentID !== undefined ? savedData.assignmentID    : '',
            assignmentClientWeChatID     : savedData.clientWeChatID !== undefined ? savedData.clientWeChatID: '',
            assignmentNotes              : savedData.notes !== undefined ? savedData.notes                  : '',
            assignmentClientPaid         : savedData.clientPaid !== undefined ? savedData.clientPaid        : false,
            assignmentContractorPaid     : savedData.contractorPaid !== undefined ? savedData.contractorPaid: false,
            assignmentValue              : savedData.value !== undefined ? savedData.value                  : '',
            assignmentContractorFee      : savedData.contractorFee !== undefined ? savedData.contractorFee  : '',
            assignmentFiles              : parsedFiles,
            assignmentContractorPaidFiles: parsedContractorPaidFiles,
            assignmentSupervisorPaidFiles: parsedSupervisorPaidFiles,
            assignmentClientPaidFiles    : parsedClientPaidFiles,
            assignmentCriteriaFiles      : parsedCriteriaFiles,
            assignmentClientPaidDate     : parsedClientDate,
            assignmentContractorPaidDate : parsedContractorDate,
            assignmentPaymentApprovalDate: parsedPaymentApprovalDate,
            assignmentPaymentApproval    : savedData.paymentApproval,
            savedAssignmentLoaded        : true,
            assignmentSupervisorFees     : savedData.supervisorFees.map(f =>
              this.calculatePercentFromFee(f, savedData.value, savedData.contractorFee)),
            assignmentSupervisorsPaid     : savedData.supervisorsPaid,
            assignmentSupervisorIDs       : savedData.supervisorIDs,
            assignmentSupervisorsPaidDates: parsedSupervisorsPaidDates,
          });
        } else {
          this.setState({
            savedAssignmentLoaded: true,
          });
        }
        this.mapFiles();
      });
    }
  }

  /**
   * Updated the assignment data in the database at the request of the user.
   * Callback to "Update" button click.
   *
   * @param {JSON} _Evt -> JS DOM click event object.
   */
  handleSaveButtonClick(_Evt) {
    _Evt.preventDefault();

    const {
      assignmentClientName,
      assignmentContractorName,
      assignmentDueDate,
      assignmentNotes,
      assignmentContractorFee,
      assignmentValue,
      assignmentFiles,
      assignmentCriteriaFiles,
      assignmentSupervisorPaidFiles,
      assignmentContractorPaidFiles,
      assignmentClientPaidFiles,
      assignmentID,
      assignmentClientWeChatID,
      initAssignmentClientID,
      assignmentSupervisorFees,
      assignmentSupervisorsPaid,
      assignmentSupervisorIDs,
      assignmentSupervisorsPaidDates,
      assignmentSupervisorNames,
    } = this.state;
    const { activeAssignmentID, } = this.props;

    Storage.doCheckFilesExist(activeAssignmentID, assignmentFiles.map(f => f.fileName)).then(doFilesExist => {
      Storage.doUploadFiles(assignmentID, assignmentFiles.filter(f => !doFilesExist.fileNames[f.fileName]))
        .then(() => {
          if (doFilesExist.contractorPaidFileNames !== undefined
              && doFilesExist.contractorPaidFileNames.length === 0
              && doFilesExist.supervisorPaidFileNames !== undefined
              && doFilesExist.supervisorPaidFileNames.length === 0
              && doFilesExist.clientPaidFileNames !== undefined
              && doFilesExist.clientPaidFileNames.length === 0) return;
          return Storage.doUploadContractorPaidFiles(
            assignmentID,
            assignmentContractorPaidFiles.filter(f => !doFilesExist.contractorPaidFileNames[f.fileName])).then(() => {
              return Storage.doUploadSupervisorPaidFiles(
                assignmentID,
                assignmentSupervisorPaidFiles.filter(f => !doFilesExist.supervisorPaidFileNames[f.fileName])).then(() => {
                  return Storage.doUploadClientPaidFiles(
                    assignmentID,
                    assignmentClientPaidFiles.filter(f => !doFilesExist.clientPaidFileNames[f.fileName]));
                });
          });
        })
        .then(() => {
          if (doFilesExist.criteriaFileNames !== undefined && doFilesExist.criteriaFileNames.length === 0) return;
          return Storage.doUploadCriteriaFiles(assignmentID, assignmentCriteriaFiles.filter(f => !doFilesExist.criteriaFileNames[f.fileName]));
        })
        .then(() => {
          new Promise((resolve, reject) => {
            if (assignmentClientName.value !== null
                && assignmentClientName.value !== undefined
                && assignmentClientName.value.split(' - ').length > 1
                && assignmentClientName.value.split(' - ')[1] !== initAssignmentClientID) {
              Db.doUpdateAssignmentClient(activeAssignmentID, {
                clientName    : assignmentClientName.value.split(' - ')[0],
                clientID      : assignmentClientName.value.split(' - ')[1],
                clientWeChatID: assignmentClientWeChatID,
              }).then(resolve).catch(reject);
            } else {
              resolve(null);
            }
          }).then((newAssignmentID) => {
            if (newAssignmentID === false) {
              this.setState({
                assignmentSavedStatus: false,
              });
              return;
            } else if (newAssignmentID !== null
                      && newAssignmentID !== undefined) {
              this.setState({
                assignmentID: newAssignmentID,
              });
            }
  
            Db.doUpdateAssignment(activeAssignmentID, {
              contractorName         : assignmentContractorName.value.split(' - ')[0],
              contractorID           : assignmentContractorName.value.split(' - ')[1],
              clientWeChatID         : assignmentClientWeChatID,
              dueDate                : Utils.readableToTimestamp(assignmentDueDate),
              notes                  : assignmentNotes,
              contractorFee          : assignmentContractorFee,
              value                  : assignmentValue,
              fileNames              : assignmentFiles.map(file => { return file.fileName }),
              supervisorPaidFileNames: assignmentSupervisorPaidFiles.map(file => { return file.fileName }),
              contractorPaidFileNames: assignmentContractorPaidFiles.map(file => { return file.fileName }),
              clientPaidFileNames    : assignmentClientPaidFiles.map(file => { return file.fileName }),
              criteriaFileNames      : assignmentCriteriaFiles.map(file => { return file.fileName }),
              supervisorFees         : assignmentSupervisorFees.map(f => this.calculateFeeFromPercent(f)),
              supervisorsPaid        : assignmentSupervisorsPaid,
              supervisorIDs          : assignmentSupervisorIDs,
              supervisorsPaidDates   : assignmentSupervisorsPaidDates,
              supervisorNames        : assignmentSupervisorNames.map(s => s.label.split(' - ')[0]),
            }).then(() => {
              this.setState({
                assignmentSavedStatus: true,
              });
            }).catch(() => {
              this.setState({
                assignmentSavedStatus: false,
              });
            });
          });
        });
    });
  }

  /**
   * Returns alert to refund assignment.
   * Handles display state based on parent state.
   *
   * @return {SnackbarAlert} -> Snackbar alert component to display.
   */
  renderAssignmentRefundedAlert() {
    const { refundState, } = this.state;

    return (
      <SnackbarAlert
        open    = { refundState !== refundDialogState.hidden && refundState !== refundDialogState.shown }
        message = { refundState === refundDialogState.successful
          ? `The assignment has been successfully refunded.`
          : `There was an error, please try again later.` } />
    );
  }

  /**
   * Returns alert to display the assignment update process status.
   * Handles display state based on parent state.
   *
   * @return {SnackbarAlert} -> Snackbar alert component to display.
   */
  renderAssignmentSavedStatusAlert() {
    const { assignmentSavedStatus, } = this.state;

    return (
      <SnackbarAlert
        open    = { assignmentSavedStatus !== null }
        message = { assignmentSavedStatus
          ? `The assignment has been successfully saved!`
          : `There was an error, please try again later.` } />
    );
  }

  /**
   * Returns alert to display the assignment file delete process status.
   * Handles display state based on parent state.
   *
   * @return {SnackbarAlert} -> Snackbar alert component to display.
   */
  renderAssignmentFileDeleteStatusAlert() {
    const { assignmentFileDeleteStatus, } = this.state;

    return (
      <SnackbarAlert
        open    = { assignmentFileDeleteStatus !== null }
        message = { assignmentFileDeleteStatus
          ? `File has been successfully deleted!`
          : `There was an error, please try again later.` } />
    );
  }

  /**
   * Updates paid update state to display relevant alerts to user.
   *
   * @param {int} _Target -> Whether the relevant user is the client or the contractor.
   *                         Encoded by int, declared as constants at top of file.
   */
  handleCheckboxChange(_Target, _SupervisorID) {
    let target = null;
    switch(_Target) {
      case    CLIENT: target     = 'clientPaidAlertState'; break;
      case    CONTRACTOR: target = 'contractorPaidAlertState'; break;
      case    SUPERVISOR: target = 'supervisorPaidAlertState'; break;
      case    APPROVAL: target   = 'paymentApprovalAlertState'; break;
      default: target            = null;                                // Warnings are annoying.
    }
    if (target !== null) {
      this.setState({
        [target]                       : paidAlertState.shown,
        supervisorPaidStateChangeTarget: _Target === SUPERVISOR ? _SupervisorID: null,
      });
    }
  };

  /**
   * Updates client/contractor paid state in database.
   *
   * @param {int} _Target -> Whether the relevant user is the client or the contractor.
   *                         Encoded by int, declared as constants at top of file.
   */
  uploadPaidState(_Target, _SupervisorID) {
    const { activeAssignmentID, } = this.props;

    if (_Target === CLIENT || _Target === CONTRACTOR) {
      const localTarget          = _Target === CONTRACTOR ? 'assignmentContractorPaid': 'assignmentClientPaid';
      const localPaidStateTarget = _Target === CONTRACTOR ? 'contractorPaidAlertState': 'clientPaidAlertState';
      Db.doSetPaidState(activeAssignmentID, _Target === CONTRACTOR).then((success) => {
        if (success) {
          this.setState({
            [localTarget]         : true,
            [localPaidStateTarget]: AlertDialogSlide.success,
            [`${localTarget}Date`]: Utils.timestampToReadable(Date.now()),
          });
        }
      });
    } else if (_Target === APPROVAL) {
      Db.doSetPaymentApprovalState(activeAssignmentID).then(() => {
        this.setState({
          assignmentPaymentApproval    : true,
          assignmentPaymentApprovalDate: Utils.timestampToReadable(Date.now()),
          paymentApprovalAlertState    : paidAlertState.success,
        });
      });
    } else {
      const { assignmentSupervisorIDs } = this.state;
      const supervisorI                 = assignmentSupervisorIDs.indexOf(_SupervisorID);
      if (supervisorI !== -1) {
        Db.doSetSupervisorPaidState(activeAssignmentID, supervisorI).then(success => {
          if (success) {
            this.setState(prevState => {
              return {
                assignmentSupervisorsPaid      : prevState.assignmentSupervisorsPaid.map((p, i) => i === supervisorI ? true : p),
                assignmentSupervisorsPaidDates : prevState.assignmentSupervisorsPaidDates.map((p, i) => i === supervisorI ? Utils.timestampToReadable(Date.now()) : p),
                supervisorPaidAlertState       : paidAlertState.success,
                supervisorPaidStateChangeTarget: null,
              };
            });
          }
        });
      }
    }
  }

  /**
   * Updates files in local component state.
   *
   * @param {Array<JSON>} _FileItems -> Array of File objects.
   */
  handleFileUpdate(_FileItems) {
    this.setState({
      assignmentFiles: _FileItems.map( fileItem => {
        return {
          file    : fileItem.file,
          fileName: fileItem.filename,
        };
      }),
    });
  }

  /**
   * Updates files in local component state.
   *
   * @param {Array<JSON>} _FileItems -> Array of File objects.
   */
  handleSupervisorPaidFileUpdate(_FileItems) {
    this.setState(prevState => ({
      assignmentSupervisorPaidFiles: [
        ...prevState.assignmentSupervisorPaidFiles.filter(f => f.file === undefined),
        ..._FileItems.map((fileItem, i) => ({
          file    : fileItem.file,
          fileName: `Supervisor Payment File ${i + prevState.assignmentSupervisorPaidFiles.length + 1}${fileItem.filename.substring(fileItem.filename.lastIndexOf('.'))}`,
        }))
      ],
    }));
  }

  /**
   * Updates files in local component state.
   *
   * @param {Array<JSON>} _FileItems -> Array of File objects.
   */
  handleContractorPaidFileUpdate(_FileItems) {
    this.setState(prevState => ({
      assignmentContractorPaidFiles: [
        ...prevState.assignmentContractorPaidFiles.filter(f => f.file === undefined),
        ..._FileItems.map((fileItem, i) => ({
          file    : fileItem.file,
          fileName: `Contractor Payment File ${i + prevState.assignmentContractorPaidFiles.length + 1}${fileItem.filename.substring(fileItem.filename.lastIndexOf('.'))}`,
        }))
      ],
    }));
  }

  /**
   * Updates files in local component state.
   *
   * @param {Array<JSON>} _FileItems -> Array of File objects.
   */
  handleClientPaidFileUpdate(_FileItems) {
    this.setState(prevState => ({
      assignmentClientPaidFiles: [
        ...prevState.assignmentClientPaidFiles.filter(f => f.file === undefined),
        ..._FileItems.map((fileItem, i) => ({
          file    : fileItem.file,
          fileName: `Client Payment File ${i + prevState.assignmentClientPaidFiles.length + 1}${fileItem.filename.substring(fileItem.filename.lastIndexOf('.'))}`,
        }))
      ],
    }));
  }

  /**
   * Updates files in local component state.
   *
   * @param {Array<JSON>} _FileItems -> Array of File objects.
   */
  handleCriteriaFileUpdate(_FileItems) {
    this.setState(prevState => ({
      assignmentCriteriaFiles: [
        ...prevState.assignmentCriteriaFiles.filter(f => f.file === undefined),
        ..._FileItems.map((fileItem, i) => ({
          file    : fileItem.file,
          fileName: `Criteria File ${i + prevState.assignmentCriteriaFiles.length + 1}${fileItem.filename.substring(fileItem.filename.lastIndexOf('.'))}`,
        }))
      ],
    }));
  }

  /**
   * Opens file in new tab.
   *
   * @param {JSON} _File -> File object.
   */
  openFileInNewTab(_File) {
    const { assignmentID, } = this.state;
    Storage.doGetAssignmentFileURL(assignmentID, _File.fileName).then(url => {
      window.open(url, '_blank');
    });
  }

  /**
   * Opens file in new tab.
   *
   * @param {JSON} _File -> File object.
   */
  openSupervisorPaidFileInNewTab(_File) {
    const { assignmentID, } = this.state;
    Storage.doGetAssignmentSupervisorPaidFileURL(assignmentID, _File.fileName).then(url => {
      window.open(url, '_blank');
    });
  }

  /**
   * Opens file in new tab.
   *
   * @param {JSON} _File -> File object.
   */
  openContractorPaidFileInNewTab(_File) {
    const { assignmentID, } = this.state;
    Storage.doGetAssignmentContractorPaidFileURL(assignmentID, _File.fileName).then(url => {
      window.open(url, '_blank');
    });
  }

  /**
   * Opens file in new tab.
   *
   * @param {JSON} _File -> File object.
   */
  openClientPaidFileInNewTab(_File) {
    const { assignmentID, } = this.state;
    Storage.doGetAssignmentClientPaidFileURL(assignmentID, _File.fileName).then(url => {
      window.open(url, '_blank');
    });
  }
  
  /**
   * Opens file in new tab.
   *
   * @param {JSON} _File -> File object.
   */
  openCriteriaFileInNewTab(_File) {
    const { assignmentID, } = this.state;
    Storage.doGetAssignmentCriteriaFileURL(assignmentID, _File.fileName).then(url => {
      window.open(url, '_blank');
    });
  }

  /**
   * Deletes file in database.
   *
   * @param {JSON} _File -> File object with file metadata to delete.
   */
  deleteFile(_File) {
    const {
      assignmentID,
      assignmentFiles,
    } = this.state;
    const { activeAssignmentID, } = this.props;

    const newFiles = assignmentFiles.filter(f => f.fileName !== _File.fileName);

    Storage.doDeleteAssignmentFile(activeAssignmentID, assignmentID, _File.fileName, newFiles.map(f => f.fileName)).then(() => {
      this.setState({
        assignmentFileDeleteStatus: true,
        assignmentFiles           : newFiles,
      });
    }).catch((_Error) => {
      console.log(_Error);
      this.setState({
        assignmentFileDeleteStatus: false,
      });
    });
  }

  /**
   * Deletes paid file in database.
   *
   * @param {JSON} _File -> File object with file metadata to delete.
   */
  deleteContractorPaidFile(_File) {
    const {
      assignmentID,
      assignmentContractorPaidFiles,
    } = this.state;
    const { activeAssignmentID, } = this.props;

    const newFiles = assignmentContractorPaidFiles.filter(f => f.fileName !== _File.fileName);

    Storage.doDeleteContractorPaidFile(activeAssignmentID, assignmentID, _File.fileName, newFiles.map(f => f.fileName))
      .then(() => {
      this.setState({
        assignmentFileDeleteStatus   : true,
        assignmentContractorPaidFiles: newFiles,
      });
    }).then(() => {
      return Db.doUpdateAssignmentContractorPaidFiles(activeAssignmentID, newFiles); // TODO: Update database function.
    }).catch((_Error) => {
      console.log(_Error);
      this.setState({
        assignmentFileDeleteStatus: false,
      });
    });
  }

  /**
   * Deletes paid file in database.
   *
   * @param {JSON} _File -> File object with file metadata to delete.
   */
  deleteSupervisorPaidFile(_File) {
    const {
      assignmentID,
      assignmentSupervisorPaidFiles,
    } = this.state;
    const { activeAssignmentID, } = this.props;

    const newFiles = assignmentSupervisorPaidFiles.filter(f => f.fileName !== _File.fileName);

    Storage.doDeleteSupervisorPaidFile(activeAssignmentID, assignmentID, _File.fileName, newFiles.map(f => f.fileName))
      .then(() => {
      this.setState({
        assignmentFileDeleteStatus   : true,
        assignmentSupervisorPaidFiles: newFiles,
      });
    }).then(() => {
      return Db.doUpdateAssignmentSupervisorPaidFiles(activeAssignmentID, newFiles);
    }).catch((_Error) => {
      console.log(_Error);
      this.setState({
        assignmentFileDeleteStatus: false,
      });
    });
  }

  /**
   * Deletes paid file in database.
   *
   * @param {JSON} _File -> File object with file metadata to delete.
   */
  deleteClientPaidFile(_File) {
    const {
      assignmentID,
      assignmentClientPaidFiles,
    } = this.state;
    const { activeAssignmentID, } = this.props;

    const newFiles = assignmentClientPaidFiles.filter(f => f.fileName !== _File.fileName);

    Storage.doDeleteClientPaidFile(activeAssignmentID, assignmentID, _File.fileName, newFiles.map(f => f.fileName))
      .then(() => {
      this.setState({
        assignmentFileDeleteStatus   : true,
        assignmentClientPaidFiles: newFiles,
      });
    }).then(() => {
      return Db.doUpdateAssignmentClientPaidFiles(activeAssignmentID, newFiles);
    }).catch((_Error) => {
      console.log(_Error);
      this.setState({
        assignmentFileDeleteStatus: false,
      });
    });
  }

  /**
   * Deletes criteria file in database.
   *
   * @param {JSON} _File -> File object with file metadata to delete.
   */
  deleteCriteriaFile(_File) {
    const {
      assignmentID,
      assignmentCriteriaFiles,
    } = this.state;
    const { activeAssignmentID, } = this.props;

    const newFiles = assignmentCriteriaFiles.filter(f => f.fileName !== _File.fileName);

    Storage.doDeleteAssignmentCriteriaFile(activeAssignmentID, assignmentID, _File.fileName, newFiles.map(f => f.fileName)).then(() => {
      this.setState({
        assignmentFileDeleteStatus: true,
        assignmentCriteriaFiles   : newFiles,
      });
    }).then(() => {
      return Db.doUpdateAssignmentCriteriaFiles(activeAssignmentID, newFiles);
    }).catch((_Error) => {
      console.log(_Error);
      this.setState({
        assignmentFileDeleteStatus: false,
      });
    });
  }

  mapFiles() {
    const {
      assignmentFiles,
      assignmentCriteriaFiles,
      assignmentContractorPaidFiles,
      assignmentSupervisorPaidFiles,
    } = this.state;

    this.setState({
      assignmentContractorPaidFiles: assignmentContractorPaidFiles.map((file, i) => {
        return {
          fileName: file.fileName,
        };
      }),
      assignmentSupervisorPaidFiles: assignmentSupervisorPaidFiles.map((file, i) => {
        return {
          fileName: file.fileName,
        };
      }),
      assignmentCriteriaFiles: assignmentCriteriaFiles.map((file, i) => {
        return {
          fileName: file.fileName,
        };
      }),
      assignmentFiles: assignmentFiles.map((file, i) => {
        return {
          fileName: file.fileName,
        };
      })
    });
  }

  /**
   * Updates local component state with selected 'clientName'
   * field, and populates WeChat ID field with downloaded value
   * associated with that client.
   *
   * @param {String} _ClientName -> Selected client name.
   */
  onClientSelect(_ClientName) {
    if (_ClientName === undefined || _ClientName === null) { return; }

    const clientID = _ClientName.value.split(' - ');

    this.setState({
      assignmentClientName: _ClientName,
    });

    if (clientID.length > 1) {
      Db.doDownloadClientData(clientID[1]).then((clientData) => {
        if (clientData !== null
            && clientData.clientWeChatID !== undefined
            && clientData.clientWeChatID !== null) {
          this.setState({
            assignmentClientWeChatID: clientData.clientWeChatID,
          });
        }
      });
    }
  }

  onSupervisorSelect = (_SupervisorNames) => {
    if (_SupervisorNames === undefined || _SupervisorNames === null) { return; }

    const newIDs = _SupervisorNames.map(s => s.label.split(' - ')[1]);
    this.setState(prevState => ({
      assignmentSupervisorNames       : _SupervisorNames,
      assignmentSupervisorIDs         : newIDs,
      assignmentSupervisorsPaid       : prevState.assignmentSupervisorsPaid.filter((p, i) => newIDs.includes(prevState.assignmentSupervisorIDs[i])),
      assignmentSupervisorsPaidDates  : prevState.assignmentSupervisorsPaidDates.filter((p, i) => newIDs.includes(prevState.assignmentSupervisorIDs[i])),
    }));

    const promises = _SupervisorNames.map((s, i) => {
      const supervisorID = s.value.split(' - ')[1];

      return Db.doDownloadSupervisorFee(supervisorID);
    });

    Promise.all(promises).then((fees) => {
      this.setState({
        assignmentSupervisorFees: fees,
      });
    });
  }

  renderSupervisorPaidCheckboxes = () => {
    const {
      assignmentSupervisorIDs,
      assignmentSupervisorsPaid,
      assignmentSupervisorsPaidDates,
      assignmentSupervisorPaidFiles,
      assignmentPaymentApproval,
    } = this.state;
    const { classes } = this.props;

    if (assignmentSupervisorsPaidDates === undefined) return;
    if (assignmentSupervisorIDs === undefined) return;

    return assignmentSupervisorIDs.map((id, i) => (
      <FormGroup row>
        <FormControlLabel
          control = {
            <Checkbox
              disabled = {!assignmentPaymentApproval || assignmentSupervisorsPaid[i] || assignmentSupervisorPaidFiles.length === 0}
              checked  = {assignmentSupervisorsPaid[i]}
              onChange = {() => this.handleCheckboxChange(SUPERVISOR, id)}
              value    = {`Supervisor ${id} Paid`}
            />
          }
          label={`Supervisor ID ${id} Paid`} />
          <Typography
            align     = 'right'
            className = { classes.fillContainerRemainder }
            variant   = 'body1'
            classes   = {{ body1: [classes.textField, classes.vCenterPaidDates].join(' ') }}>
            {`Date: ${assignmentSupervisorsPaidDates[i]}`}
          </Typography>
      </FormGroup>
    ));
  }

  calculatePercentFromFee = (_Fee, _AssignmentValue, _AssignmentContractorFee) => {
    const {
      assignmentValue,
      assignmentContractorFee,
    } = this.state;

    let val     = _AssignmentValue === undefined ? assignmentValue : _AssignmentValue;
    let contFee = _AssignmentContractorFee === undefined ? assignmentContractorFee : _AssignmentContractorFee;

    const fee = typeof _Fee === 'string' ? parseFloat(_Fee) : _Fee;
    val       = typeof val === 'string' ? parseFloat(val) : val;
    contFee   = typeof contFee === 'string' ? parseFloat(contFee) : contFee;

    return (_Fee / (val - contFee));
  }

  calculateFeeFromPercent = (_Percent) => {
    const {
      assignmentValue,
      assignmentContractorFee,
    } = this.state;
    return ((assignmentValue - assignmentContractorFee) * _Percent).toFixed(2);
  }

  render() {
    const {
      assignmentClientName,
      assignmentContractorName,
      assignmentDueDate,
      assignmentNotes,
      assignmentClientPaid,
      assignmentContractorPaid,
      assignmentValue,
      assignmentID,
      assignmentContractorFee,
      assignmentContractorPaidDate,
      assignmentClientPaidDate,
      contractorPaidAlertState,
      clientPaidAlertState,
      assignmentSupervisorPaidFiles,
      assignmentContractorPaidFiles,
      assignmentCriteriaFiles,
      assignmentClientWeChatID,
      clientNames,
      contractorNames,
      assignmentSupervisorNames,
      supervisorNames,
      assignmentSupervisorFees,
      supervisorPaidStateChangeTarget,
      supervisorPaidAlertState,
      paymentApprovalAlertState,
      assignmentClientPaidFiles,
      refundState,
      assignmentSupervisorIDs,
      assignmentContractorID,
      assignmentRefunded,
      assignmentPaymentApprovalDate,
      assignmentPaymentApproval,
    } = this.state;
    const {
      classes,
      activeAssignmentID,
    } = this.props;

    const supervisorFees = assignmentSupervisorFees.map((f, i) => ({
      value: i,
      label: `$${this.calculateFeeFromPercent(f)}`,
    }));

    return (
      <div className={ classes.root }>
        { this.renderAssignmentSavedStatusAlert() }
        { this.renderAssignmentFileDeleteStatusAlert() }
        { this.renderAssignmentRefundedAlert() }
        <Card
          elevation = { 8 }
          className = { classes.card }>
          <div className={classes.cardHeaderWrapper}>
            <h2 className={classes.title}>Update Assignment</h2>
            <h2 className={classes.title} style={{ color: '#FF0000', }}>{assignmentRefunded && 'REFUNDED'}</h2>
          </div>
          <div className={ [ classes.container, classes.margin ].join(' ') }>
            <FormControl fullWidth>
              <InputLabel htmlFor='assignment-id-field'>Assignment ID</InputLabel>
              <Input
                className    = {[classes.textField, classes.margin].join(' ')}
                id           = 'assignment-id-field'
                value        = {assignmentID}
                defaultValue = 'N/A'
                disabled     = {true}>
              </Input>
            </FormControl>
            <span className={classes.label}>Client</span>
            <Select fullWidth
              placeholder = 'Search for client'
              className   = { classes.margin }
              value       = {assignmentClientName}
              options     = {clientNames}
              onChange    = {value => this.onClientSelect(value)} />
            <span className={classes.label}>Contractor</span>
            <Select fullWidth
              placeholder = 'Search for contractor'
              className   = { classes.margin }
              value       = {assignmentContractorName}
              options     = {contractorNames}
              onChange    = {value => this.setState({ assignmentContractorName: value, })} />
            <span className={classes.label}>Supervisors</span>
            <Select fullWidth isMulti
              placeholder = 'Search for supervisor(s)'
              className   = { classes.margin }
              value       = {assignmentSupervisorNames}
              options     = {supervisorNames}
              onChange    = {this.onSupervisorSelect} />
            <span className={classes.label}>Supervisors Fees</span>
            <Select fullWidth isMulti
              placeholder = 'Supervisors Fees'
              className   = { classes.margin }
              value       = {supervisorFees}
              menuIsOpen  = {false}
              options     = {[]}
              onChange    = {(values) => console.log(values)} />
            <FormControl fullWidth>
              <InputLabel htmlFor='client-wechat-id-field'>Client WeChat ID</InputLabel>
              <Input
                disabled  = {true}
                className = {[classes.textField, classes.margin].join(' ')}
                id        = 'client-wechat-id-field'
                value     = {assignmentClientWeChatID}
                onChange  = {evt => this.setState({ assignmentClientWeChatID: evt.target.value, })}>
              </Input>
            </FormControl>
            <FormControl fullWidth>
              <InputLabel htmlFor='value-field'>Assignment Value</InputLabel>
              <Input
                disabled       = {assignmentClientPaid}
                startAdornment = {<InputAdornment position="start">$</InputAdornment>}
                type           = 'number'
                className      = {[classes.textField, classes.margin].join(' ')}
                id             = 'value-field'
                value          = {assignmentValue}
                onChange       = {evt => this.setState({ assignmentValue: evt.target.value, })}>
              </Input>
            </FormControl>
            <FormControl fullWidth>
              <InputLabel htmlFor='contractor-fee-field'>Contractor Fee</InputLabel>
              <Input
                disabled       = {assignmentContractorPaid}
                startAdornment = {<InputAdornment position="start">$</InputAdornment>}
                type           = 'number'
                className      = {[classes.textField, classes.margin].join(' ')}
                id             = 'contractor-fee-field'
                value          = {assignmentContractorFee}
                onChange       = {evt => this.setState({ assignmentContractorFee: evt.target.value, })}>
              </Input>
            </FormControl>
            <TextField
              fullWidth
              id              = "date"
              label           = "Due date"
              type            = "datetime-local"
              className       = {[classes.textField, classes.dateInput].join(' ')}
              InputLabelProps = {{
                shrink: true,
              }}
              value    = {assignmentDueDate}
              onChange = {evt => this.setState({ assignmentDueDate: evt.target.value, })}/>
            <FormGroup row>
              <FormControlLabel
                control = {
                  <Checkbox
                    disabled = {assignmentClientPaid}
                    checked  = {assignmentClientPaid}
                    onChange = {() => this.handleCheckboxChange(CLIENT)}
                    value    = "Client Paid"
                  />
                }
                label='Client Paid' />
              <Typography
                align     = 'right'
                className = { classes.fillContainerRemainder }
                variant   = 'body1'
                classes   = {{ body1: [classes.textField, classes.vCenterPaidDates].join(' ') }}>
                {`Date: ${assignmentClientPaidDate}`}
              </Typography>
            </FormGroup>
            <FormGroup row>
              <FormControlLabel
                control = {
                  <Checkbox
                    disabled = {assignmentPaymentApproval}
                    checked  = {assignmentPaymentApproval}
                    onChange = {() => this.handleCheckboxChange(APPROVAL)}
                    value    = "Contractors and Supervisors Payment Approval"
                  />
                }
                label='Contractors and Supervisors Payment Approval' />
              <Typography
                align     = 'right'
                className = { classes.fillContainerRemainder }
                variant   = 'body1'
                classes   = {{ body1: [classes.textField, classes.vCenterPaidDates].join(' ') }}>
                {`Date: ${assignmentPaymentApprovalDate}`}
              </Typography>
            </FormGroup>
            <FormGroup row>
              <FormControlLabel
                control = {
                  <Checkbox
                    disabled = {!assignmentPaymentApproval || assignmentContractorPaid || assignmentContractorPaidFiles.length === 0}
                    checked  = {assignmentContractorPaid}
                    onChange = {() => this.handleCheckboxChange(CONTRACTOR)}
                    value    = "Contractor Paid"
                  />
                }
                label='Contractor Paid' />
                <Typography
                  align     = 'right'
                  className = { classes.fillContainerRemainder }
                  variant   = 'body1'
                  classes   = {{ body1: [classes.textField, classes.vCenterPaidDates].join(' ') }}>
                  {`Date: ${assignmentContractorPaidDate}`}
                </Typography>
            </FormGroup>
            {this.renderSupervisorPaidCheckboxes()}
            <span className={classes.label}>Criteria Files</span>
            <FilePond
              isMulti
              allowMultiple = {true}
              onupdatefiles = {this.handleCriteriaFileUpdate}>
            </FilePond>
            <FileList
              files                = { assignmentCriteriaFiles }
              onItemClick          = { this.openCriteriaFileInNewTab }
              onItemSecondaryClick = { this.deleteCriteriaFile } />
            <span className={classes.label}>Client Payment Files</span>
            <FilePond
              isMulti
              allowMultiple = {true}
              onupdatefiles = {this.handleClientPaidFileUpdate}>
            </FilePond>
            <FileList
              files                = { assignmentClientPaidFiles }
              onItemClick          = { this.openClientPaidFileInNewTab }
              onItemSecondaryClick = { this.deleteClientPaidFile } />
            <span className={classes.label}>Contractor Payment Files</span>
            <FilePond
              isMulti
              allowMultiple = {true}
              onupdatefiles = {this.handleContractorPaidFileUpdate}>
            </FilePond>
            <FileList
              files                = { assignmentContractorPaidFiles }
              onItemClick          = { this.openContractorPaidFileInNewTab }
              onItemSecondaryClick = { this.deleteContractorPaidFile } />
            <span className={classes.label}>Supervisor Payment Files</span>
            <FilePond
              isMulti
              allowMultiple = {true}
              onupdatefiles = {this.handleSupervisorPaidFileUpdate}>
            </FilePond>
            <FileList
              files                = { assignmentSupervisorPaidFiles }
              onItemClick          = { this.openSupervisorPaidFileInNewTab }
              onItemSecondaryClick = { this.deleteSupervisorPaidFile } />
            <FormControl fullWidth>
              <InputLabel htmlFor='notes-field'>Notes</InputLabel>
              <Input
                rows = {5}
                multiline
                className = {[classes.textField, classes.margin].join(' ')}
                id        = 'notes-field'
                value     = {assignmentNotes}
                onChange  = {evt => this.setState({ assignmentNotes: evt.target.value, })}>
              </Input>
            </FormControl>
          </div>
          <div className='assignment-data-actions-buttons-wrapper'>
            <Button
              className = {[classes.margin, classes.button].join(' ')}
              size      = 'large'
              onClick   = {this.handleSaveButtonClick}
              color     = 'primary'>
              Update
            </Button>
            <Button
              disabled  = {assignmentRefunded}
              className = {[classes.margin, classes.button].join(' ')}
              size      = 'large'
              style     = {{ backgroundColor: '#FF0000', color: '#FFFFFF', opacity: assignmentRefunded ? 0.5 : 1 }}
              onClick   = {() => this.setState({ refundState: refundDialogState.shown, })}>
              Refund
            </Button>
          </div>
        </Card>
        <AlertDialogSlide
          title           = {`Client Paid State Change Confirmation`}
          message         = {`Are you sure you would like to set the clients's paid state to true? You cannot undo this.`}
          open            = {clientPaidAlertState === paidAlertState.shown}
          onOKHandler     = {() => this.uploadPaidState(CLIENT)}
          onCancelHandler = {() => this.setState({ clientPaidAlertState: paidAlertState.hidden })} />
        <AlertDialogSlide
          title           = {`Contractor Paid State Change Confirmation`}
          message         = {`Are you sure you would like to set the contractor's paid state to true? You cannot undo this.`}
          open            = {contractorPaidAlertState === paidAlertState.shown}
          onOKHandler     = {() => this.uploadPaidState(CONTRACTOR)}
          onCancelHandler = {() => this.setState({ contractorPaidAlertState: paidAlertState.hidden })} />
        <AlertDialogSlide
          title           = {`Supervisor Paid State Change Confirmation`}
          message         = {`Are you sure you would like to set supervisor ID ${supervisorPaidStateChangeTarget} paid state to true? You cannot undo this.`}
          open            = {supervisorPaidAlertState === paidAlertState.shown}
          onOKHandler     = {() => this.uploadPaidState(SUPERVISOR, supervisorPaidStateChangeTarget)}
          onCancelHandler = {() => this.setState({ supervisorPaidAlertState: paidAlertState.hidden })} />
        <AlertDialogSlide
          title           = {`Payment Approval State Change Confirmation`}
          message         = {`Are you sure you would like to approve compensation of supervisors and contractors? You cannot undo this.`}
          open            = {paymentApprovalAlertState === paidAlertState.shown}
          onOKHandler     = {() => this.uploadPaidState(APPROVAL)}
          onCancelHandler = {() => this.setState({ paymentApprovalAlertState: paidAlertState.hidden })} />
        <RefundDialog
          assignmentDocID = {activeAssignmentID}
          supervisorFees  = {supervisorFees}
          supervisorIDs   = {assignmentSupervisorIDs}
          contractorFee   = {assignmentContractorFee}
          contractorID    = {assignmentContractorID}
          contractorName  = {assignmentContractorName}
          open            = {refundState === refundDialogState.shown}
          onOKHandler     = {() => this.setState({ refundState: refundDialogState.successful, assignmentRefunded: true, })}
          onCancelHandler = {() => this.setState({ refundState: refundDialogState.hidden, })} />
      </div>
    );
  }
}

const mapStateToProps = (_State) => ({
  authUser          : _State.sessionState.authUser,
  activeAssignmentID: _State.assignmentState.activeAssignmentID,
});

const mapDispatchToProps = (_Dispatch) => ({
});

const authCondition = (authUser) => !!authUser;

export default compose(
  withAuthorization(authCondition),
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
  withStyles(styles),
)(AssignmentDataPage);
