// ------------- DEPENDENCIES ------------- //

import React from 'react';
import { Button } from 'react-bootstrap';

// ------------- LOCAL DEPENDENCIES ------------- //

import { Auth } from '../firebase';

// ------------- MAIN ------------- //

const SignOutButton = () =>
  <Button
    style   = {{ marginTop: '5px' }}
    bsStyle = "danger"
    onClick = {Auth.doSignOut}
  >
    Sign Out
  </Button>

export default SignOutButton;