// ------------- DEPENDENCIES -------------- //

import React, { Component } from 'react';
import {
  Menu,
  MenuItem,
  withStyles,
  Button,
} from '@material-ui/core';

// ------------- CONSTANTS & GLOBAL VARS -------------- //

const dropdownStyles = ({
  dropdownMenu: {
    maxHeight: 500,
  },
  button: {
    fontSize: 13,
    width   : 100,
  },
  dropdownWrapper: {
    padding  : 15,
    width    : 100,
    textAlign: 'center',
  },
});

// ------------- MAIN -------------- //

class Dropdown extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      anchorEl  : null,
      categories: props.categories,
      buttonText: props.defaultText,
    };
  }

  handleClick = event => {
    this.setState({
      anchorEl: event.currentTarget,
    });
  }

  handleClose = (_Category) => {
    const {
      onChange,
      defaultText,
      defaultValue,
    } = this.props;
    if (_Category === null || _Category === undefined) { return; }

    this.setState({
      anchorEl  : null,
      buttonText: _Category === '' ? defaultText: _Category,
    });

    if (onChange !== null && onChange !== undefined) {
      onChange(_Category === '' ? (defaultValue ? defaultValue : _Category) : _Category);
    }
  }

  render() {
    const {
      anchorEl,
      categories,
      buttonText,
    } = this.state;
    const {
      classes,
      disabled,
    } = this.props;

    return (
      <div
        className={ classes.dropdownWrapper }>
        <Button
          disabled      = {disabled}
          className     = { classes.button }
          aria-owns     = {anchorEl ? 'category-menu' : null}
          aria-haspopup = "true"
          onClick       = {this.handleClick}>
          { buttonText }
        </Button>
        <Menu
          className = { classes.dropdownMenu }
          id        = "category-menu"
          anchorEl  = {anchorEl}
          open      = {Boolean(anchorEl)}
          onClose   = {() => this.handleClose(null)}>
          { [...categories, 'clear'].map((item, i, arr) => {
            return (
              <MenuItem key={i} onClick={() => this.handleClose(i === arr.length - 1 ? '' : item)}>{item}</MenuItem>
            );
          }) }
        </Menu>
      </div>
    );
  }
}

export default withStyles(dropdownStyles)(Dropdown);