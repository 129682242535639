// ------------- DEPENDENCIES -------------- //

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import {
  withStyles,
  Paper,
  Button,
} from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import ReactLoading from 'react-loading';
import Select from 'react-select';

// ------------- LOCAL DEPENDENCIES -------------- //

import withAuthorization from './withAuthorization';
import { Db } from '../firebase';
import * as Actions from '../reducers/action_types';
import * as Routes from '../constants/routes';
import ClientsList from './ClientsList';

// ------------- CONSTANTS & GLOBAL VARS -------------- //

const styles = ({
  root: {
    width : '100%',
    height: '100%',
  },
  center: {
    display       : 'flex',
    justifyContent: 'center',
    alignItems    : 'center',
    margin        : '0 auto',
  },
  hCenter: {
    textAlign: 'center',
  },
  filterWrapper: {
    padding: 10,
  },
  button: {
    fontSize  : 15,
    marginLeft: 20,
  },
  select: {
    width: 150,
  },
});

// ------------- MAIN -------------- //

class ClientsPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      clients     : null,
      clientNames : [],
      filterValue : '',
      filterSelect: '',
    };

    this.handleClientClick = this.handleClientClick.bind(this);
    this.applyFilter       = this.applyFilter.bind(this);
  }

  componentDidMount() {
    Db.doDownloadClients().then((_Clients) => {
      if (_Clients !== null) {
        this.setState({
          clients: _Clients,
        });
      }
    });
    Db.doDownloadClientNames().then((clientNames) => {
      clientNames = clientNames.map(c => {
        return {
          label: c,
          value: c.split(' - ')[1],
        };
      });

      this.setState({
        clientNames,
      });
    });
  }

  handleClientClick(_Client) {
    const { onSetActiveClient, history, } = this.props;
    onSetActiveClient(_Client);
    history.push(Routes.CLIENT_DATA);
  }

  applyFilter() {
    this.setState({
      clients: null,
    });

    const {
      filterValue,
    } = this.state;

    if (filterValue === '') {
      Db.doDownloadClients().then((_Clients) => {
        if (_Clients !== null) {
          this.setState({
            clients: _Clients,
          });
        }
      });
    } else {
      const filterField = `clientID`;

      Db.doDownloadFilteredClients([{
        filter: filterField,
        value : filterValue.toLowerCase(),
      }]).then((results) => {
        if (results !== null) {
          this.setState({
            clients: results,
          });
        }
      });
    }
  }

  onFilterSelect = (val) => {
    if (val === undefined || val === null) { return; }

    this.setState({
      filterValue : val.value,
      filterSelect: val,
    });
  }

  clearFilter = () => {
    this.setState({
      filterValue : '',
      filterSelect: {
        value: '',
        label: 'Search'
      }
    }, () => {
      this.applyFilter();
    });
  }
  
  render() {
    const {
      clients,
      clientNames,
      filterSelect
    } = this.state;
    const { classes, } = this.props;

    return (
      <div
        className={classes.root}>
        <Paper
          className={ [classes.filterWrapper, classes.center].join(' ') }>
          <Button
            className = {[classes.margin, classes.button].join(' ')}
            size      = 'large'
            onClick   = {() => this.clearFilter()}
            color     = 'primary'
            disabled  = {false}>
            Clear
          </Button>
          <Select
            placeholder = 'Search'
            className   = {[classes.textField, classes.padding, classes.select].join(' ')}
            value       = {filterSelect}
            options     = {clientNames}
            onChange    = {this.onFilterSelect} />
          <Button
            className = {[classes.margin, classes.button].join(' ')}
            size      = 'large'
            onClick   = {() => this.applyFilter()}
            color     = 'primary'
            disabled  = {false}>
            Apply
          </Button>
        </Paper>
        {
          clients === null
          ? <ReactLoading
              type  = 'bars'
              style = {{
                margin   : 'auto',
                width    : '20%',
                color    : "#29aafc",
                marginTop: '200px',
              }}/>
          :                  clients !== null && clients.length === 0
              ? <div>No matching results</div>
              : <ClientsList
                  onItemClick = {this.handleClientClick}
                  clients     = {clients} />
        }
      </div>
    );
  }
}

const mapStateToProps = (_State) => ({
  authUser: _State.sessionState.authUser,
});

const mapDispatchToProps = (_Dispatch) => ({
  onSetActiveClient: (activeClient) => _Dispatch({ type: Actions.ACTIVE_CLIENT_SET, activeClient }),
});

const authCondition = (authUser) => !!authUser;

export default compose(
  withAuthorization(authCondition),
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
  withRouter
)(ClientsPage);