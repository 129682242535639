// ------------- DEPENDENCIES ------------- //

import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';

// ------------- MAIN ------------- ///

const config = {
  apiKey: "AIzaSyA00yTvKDcMKIw2F1DflWhhUQshB4mQdlo",
  authDomain: "li-ops-production.firebaseapp.com",
  databaseURL: "https://li-ops-production.firebaseio.com",
  projectId: "li-ops-production",
  storageBucket: "li-ops-production.appspot.com",
  messagingSenderId: "1003707832591",
  appId: "1:1003707832591:web:b561d274b66e108",
};

if (!firebase.apps.length) {
  firebase.initializeApp(config);
}

const Auth    = firebase.auth();
const Db      = firebase.firestore();
const Storage = firebase.storage().ref();

const settings = { timestampsInSnapshots: true };
Db.settings(settings);

export {
  Auth,
  Db,
  Storage,
};
