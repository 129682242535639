// ------------- DEPENDENCIES -------------- //

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import {
  Card,
  CardHeader,
  withStyles,
  FormControl,
  Input,
  InputLabel,
  Button,
} from '@material-ui/core';

// ------------- LOCAL DEPENDENCIES -------------- //

import withAuthorization from './withAuthorization';
import { Db } from '../firebase';
import SnackbarAlert from './SnackbarAlert';

import '../css/AssignmentUploadPage.css';

// ------------- CONSTANTS & GLOBAL VARS -------------- //

const styles = ({
  margin: {
    margin: 10,
  },
  card: {
    padding: 30,
  },
  root: {
    marginTop: '3%',
    width    : '100%',
    padding  : '50px 10% 30px 10%',
  },
  title: {
    marginBottom: 5,
    fontSize    : 16,
    color       : '#707070',
  },
  textField: {
    marginLeft : 20,
    marginRight: 20,
    fontSize   : 14,
  },
  button: {
    fontSize: 15,
  },
  noPadding: {
    padding: '0px !important',
  },
  dateInput: {
    marginLeft  : '0px !important',
    paddingRight: '20px',
  },
});

// ------------- MAIN -------------- //

class CreateContractorPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      contractorName        : '',
      contractorNotes       : '',
      contractorID          : 'N/A',
      contractorCreateStatus: null,
      creatingContractor    : false,
    };

    this.handleCreateButtonClick             = this.handleCreateButtonClick.bind(this);
    this.renderContractorCreateAlert         = this.renderContractorCreateAlert.bind(this);
    this.assertContractorCreateNotInProgress = this.assertContractorCreateNotInProgress.bind(this);
  }

  // Field validation states.
  
  getContractorNameValidationState() {
    const {
      contractorName,
    } = this.state;

    if (contractorName === null
        || contractorName === undefined
        || contractorName.length === 0) {
      return 'error';
    } else {
      return 'success';
    }
  }

  assertContractorCreateNotInProgress() {
    const {
      creatingContractor,
    } = this.state;

    if (creatingContractor === null
      || creatingContractor === undefined
      || creatingContractor) {
      return 'error';
    } else {
      return 'success';
    }
  }

  handleCreateButtonClick(_Evt) {
    _Evt.preventDefault();

    const {
      contractorName,
      contractorNotes,
    } = this.state;
    const { authUser } = this.props;

    this.setState({
      creatingContractor: true,
    });

    Db.doCreateContractor(authUser.uid, {
      contractorName: contractorName.toLowerCase(),
      notes         : contractorNotes,
    }).then((newID) => {
      console.log(newID);
      this.setState({
        contractorCreateStatus: true,
        contractorID          : newID,
        contractorName        : '',
        contractorNotes       : '',
        creatingContractor    : false,
      });
    });
  }

  renderContractorCreateAlert() {
    const { contractorCreateStatus, } = this.state;

    return (
      <SnackbarAlert
        open    = { contractorCreateStatus !== null }
        message = { contractorCreateStatus
          ? `The contractor has been successfully created!`
          : `There was an error, please try again later.` } />
    );
  }

  render() {
    const {
      contractorName,
      contractorNotes,
      contractorID,
    } = this.state;
    const { classes, } = this.props;

    const validationState = this.getContractorNameValidationState() === 'success'
                            && this.assertContractorCreateNotInProgress() === 'success';

    return (
      <div className={ classes.root }>
        { this.renderContractorCreateAlert() }
        <Card
          elevation = { 8 }
          className = { classes.card }>
          <CardHeader
            classes = {{ title: classes.title, }}
            title   = 'Create a New Contractor' />
          <div className={ [ classes.container, classes.margin ].join(' ') }>
            <FormControl fullWidth>
              <InputLabel htmlFor='contractor-name-field'>Contractor Name</InputLabel>
              <Input
                className = {[classes.textField, classes.margin].join(' ')}
                id        = 'contractor-name-field'
                value     = {contractorName}
                onChange  = {evt => this.setState({ contractorName: evt.target.value, })}>
              </Input>
            </FormControl>
            <FormControl fullWidth>
              <InputLabel htmlFor='contractor-id-field'>Contractor ID</InputLabel>
              <Input
                className    = {[classes.textField, classes.margin].join(' ')}
                id           = 'contractor-id-field'
                value        = {contractorID}
                defaultValue = 'N/A'
                disabled     = {true}
                InputProps   = {{
                  readOnly: true,
                }}>
              </Input>
            </FormControl>
            <FormControl fullWidth>
              <InputLabel htmlFor='notes-field'>Notes</InputLabel>
              <Input
                rows = {5}
                multiline
                className = {[classes.textField, classes.margin].join(' ')}
                id        = 'notes-field'
                value     = {contractorNotes}
                onChange  = {evt => this.setState({ contractorNotes: evt.target.value, })}>
              </Input>
            </FormControl>
          </div>
          <div className='submit-buttons-wrapper'>
            <Button
              className = {[classes.margin, classes.button].join(' ')}
              size      = 'large'
              onClick   = {this.handleCreateButtonClick}
              color     = 'primary'
              disabled  = {!validationState}>
              Create
            </Button>
          </div>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = (_State) => ({
  authUser: _State.sessionState.authUser,
});

const mapDispatchToProps = (_Dispatch) => ({
});

const authCondition = (authUser) => !!authUser;

export default compose(
  withAuthorization(authCondition),
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
  withStyles(styles),
)(CreateContractorPage);