// ------------- DEPENDENCIES -------------- //

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import {
  Card,
  CardHeader,
  withStyles,
  FormControl,
  Input,
  InputLabel,
  Button,
} from '@material-ui/core';

// ------------- LOCAL DEPENDENCIES -------------- //

import withAuthorization from './withAuthorization';
import { Db } from '../firebase';
import SnackbarAlert from './SnackbarAlert';

import '../css/AssignmentUploadPage.css';

// ------------- CONSTANTS & GLOBAL VARS -------------- //

const styles = ({
  margin: {
    margin: 10,
  },
  card: {
    padding: 30,
  },
  root: {
    marginTop: '3%',
    width    : '100%',
    padding  : '50px 10% 30px 10%',
  },
  title: {
    marginBottom: 5,
    fontSize    : 16,
    color       : '#707070',
  },
  textField: {
    marginLeft : 20,
    marginRight: 20,
    fontSize   : 14,
  },
  button: {
    fontSize: 15,
  },
  noPadding: {
    padding: '0px !important',
  },
  dateInput: {
    marginLeft  : '0px !important',
    paddingRight: '20px',
  },
});

// ------------- MAIN -------------- //

class CreateClientPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      clientName        : '',
      clientNotes       : '',
      clientWeChatID    : '',
      clientID          : 'N/A',
      clientCreateStatus: null,
      creatingClient    : false,
    };

    this.handleCreateButtonClick          = this.handleCreateButtonClick.bind(this);
    this.renderClientCreateAlert          = this.renderClientCreateAlert.bind(this);
    this.assertClientCreateNotInProgress  = this.assertClientCreateNotInProgress.bind(this);
    this.getClientWeChatIDValidationState = this.getClientWeChatIDValidationState.bind(this);
  }

  // Field validation states.
  
  getClientNameValidationState() {
    const {
      clientName,
    } = this.state;

    if (clientName === null
        || clientName === undefined
        || clientName.length === 0) {
      return 'error';
    } else {
      return 'success';
    }
  }

  getClientWeChatIDValidationState() {
    const {
      clientWeChatID,
    } = this.state;

    if (clientWeChatID === null
        || clientWeChatID === undefined
        || clientWeChatID.length === 0) {
      return 'error';
    } else {
      return 'success';
    }
  }

  assertClientCreateNotInProgress() {
    const {
      creatingClient,
    } = this.state;

    if (creatingClient === null
      || creatingClient === undefined
      || creatingClient) {
      return 'error';
    } else {
      return 'success';
    }
  }

  handleCreateButtonClick(_Evt) {
    _Evt.preventDefault();

    const {
      clientName,
      clientNotes,
      clientWeChatID,
    } = this.state;
    const { authUser } = this.props;

    this.setState({
      creatingClient: true,
    });

    Db.doCreateClient(authUser.uid, {
      clientName    : clientName.toLowerCase(),
      clientWeChatID: clientWeChatID,
      notes         : clientNotes,
    }).then((newID) => {
      console.log(newID);
      this.setState({
        clientCreateStatus: true,
        clientID          : newID,
        clientName        : '',
        clientWeChatID    : '',
        clientNotes       : '',
        creatingClient    : false,
      });
    });
  }

  renderClientCreateAlert() {
    const { clientCreateStatus, } = this.state;

    return (
      <SnackbarAlert
        open    = { clientCreateStatus !== null }
        message = { clientCreateStatus
          ? `The client has been successfully created!`
          : `There was an error, please try again later.` } />
    );
  }

  render() {
    const {
      clientName,
      clientNotes,
      clientID,
      clientWeChatID,
    } = this.state;
    const { classes, } = this.props;

    const validationState = this.getClientNameValidationState() === 'success'
                            && this.assertClientCreateNotInProgress() === 'success'
                            && this.getClientWeChatIDValidationState() === 'success';

    return (
      <div className={ classes.root }>
        { this.renderClientCreateAlert() }
        <Card
          elevation = { 8 }
          className = { classes.card }>
          <CardHeader
            classes = {{ title: classes.title, }}
            title   = 'Create a New Client' />
          <div className={ [ classes.container, classes.margin ].join(' ') }>
            <FormControl fullWidth>
              <InputLabel htmlFor='client-name-field'>Client Name</InputLabel>
              <Input
                className = {[classes.textField, classes.margin].join(' ')}
                id        = 'client-name-field'
                value     = {clientName}
                onChange  = {evt => this.setState({ clientName: evt.target.value, })}>
              </Input>
            </FormControl>
            <FormControl fullWidth>
              <InputLabel htmlFor='client-id-field'>Client ID</InputLabel>
              <Input
                className    = {[classes.textField, classes.margin].join(' ')}
                id           = 'client-id-field'
                value        = {clientID}
                defaultValue = 'N/A'
                disabled     = {true}
                InputProps   = {{
                  readOnly: true,
                }}>
              </Input>
            </FormControl>
            <FormControl fullWidth>
              <InputLabel htmlFor='client-wechat-id-field'>Client WeChat ID</InputLabel>
              <Input
                className = {[classes.textField, classes.margin].join(' ')}
                id        = 'client-wechat-id-field'
                value     = {clientWeChatID}
                onChange  = {evt => this.setState({ clientWeChatID: evt.target.value, })}>
              </Input>
            </FormControl>
            <FormControl fullWidth>
              <InputLabel htmlFor='notes-field'>Notes</InputLabel>
              <Input
                rows = {5}
                multiline
                className = {[classes.textField, classes.margin].join(' ')}
                id        = 'notes-field'
                value     = {clientNotes}
                onChange  = {evt => this.setState({ clientNotes: evt.target.value, })}>
              </Input>
            </FormControl>
          </div>
          <div className='submit-buttons-wrapper'>
            <Button
              className = {[classes.margin, classes.button].join(' ')}
              size      = 'large'
              onClick   = {this.handleCreateButtonClick}
              color     = 'primary'
              disabled  = {!validationState}>
              Create
            </Button>
          </div>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = (_State) => ({
  authUser: _State.sessionState.authUser,
});

const mapDispatchToProps = (_Dispatch) => ({
});

const authCondition = (authUser) => !!authUser;

export default compose(
  withAuthorization(authCondition),
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
  withStyles(styles),
)(CreateClientPage);