// ------------- LOCAL DEPENDENCIES -------------- //

import * as Actions from './action_types';

// ------------- CONSTANTS & GLOBAL VARS -------------- //

const INITIAL_STATE = {
  activeAssignmentID: null,
};

// ------------- MAIN -------------- //

const applySetActiveAssignmentID = (_State, _Action) => ({
  ..._State,
  activeAssignmentID: _Action.activeAssignmentID,
});

function assignmentReducer(_State = INITIAL_STATE, _Action) {
  switch(_Action.type) {
    case Actions.ACTIVE_ASSIGNMENT_SET: {
      return applySetActiveAssignmentID(_State, _Action);
    }
    default: return _State;
  }
}

export default assignmentReducer;
