// ------------- LOCAL DEPENDENCIES -------------- //

import * as Actions from './action_types';

// ------------- CONSTANTS & GLOBAL VARS -------------- //

const INITIAL_STATE = {
  authUser: null,
};

// ------------- MAIN -------------- //

const applySetAuthUser = (_State, _Action) => ({
  ..._State,
  authUser: _Action.authUser,
});

function sessionReducer(_State = INITIAL_STATE, _Action) {
  switch(_Action.type) {
    case Actions.AUTH_USER_SET: {
      return applySetAuthUser(_State, _Action);
    }
    default: return _State;
  }
}

export default sessionReducer;
