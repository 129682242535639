// ------------- LOCAL DEPENDENCIES -------------- //

import * as Actions from './action_types';

// ------------- CONSTANTS & GLOBAL VARS -------------- //

const INITIAL_STATE = {
  activeSupervisor: null,
};

// ------------- MAIN -------------- //

const applySetActiveSupervisor = (_State, _Action) => ({
  ..._State,
  activeSupervisor: _Action.activeSupervisor,
});

function supervisorReducer(_State = INITIAL_STATE, _Action) {
  switch(_Action.type) {
    case Actions.ACTIVE_SUPERVISOR_SET: {
      return applySetActiveSupervisor(_State, _Action);
    }
    default: return _State;
  }
}

export default supervisorReducer;
