// ------------- DEPENDENCIES ------------- //

import React, { Component } from 'react';
import { compose, } from 'recompose';
import {
  Link,
  withRouter,
} from 'react-router-dom';
import {
  Card,
  CardHeader,
  withStyles,
  FormControl,
  Input,
  InputLabel,
  Button,
} from '@material-ui/core';

// ------------- LOCAL DEPENDENCIES ------------- //

import { Auth, Db } from '../firebase';
import * as Routes from '../constants/routes';

// ------------- CONSTANTS & GLOBAL VARS ------------- //

const INITIAL_STATE = {
  email      : '',
  passwordOne: '',
  passwordTwo: '',
  error      : null,
};

const styles = {
  title: {
    marginBottom: 20,
    fontSize    : 30,
  },
  card: {
    marginTop: '3%',
    width    : '100%',
    textAlign: 'center',
    padding  : '50px 10% 0px 10%',
  },
  margin: {
    margin: 10,
  }
}

const formStyles = {
  textField: {
    marginLeft : 20,
    marginRight: 20,
    fontSize   : 14,
  },
  margin: {
    margin: 10,
  },
  button: {
    fontSize: 15,
  }
}

// ------------- MAIN ------------- //

class SignUpPage extends Component {
  render() {
    const { history, classes, } = this.props;

    return (
      <div className={classes.card}>
        <Card
          elevation = {8}
          className = {classes.card}>
          <CardHeader
            classes={{
              title: classes.title,
            }}
            title='Welcome!' />
          <SignUpForm history={history}/>
        </Card>
      </div>
    );
  }
}

const byPropKey = (_PropertyName, _Value) => () => ({
  [_PropertyName]: _Value,
});

class SignUpFormComponent extends Component {
  constructor(_Props) {
    super(_Props);

    this.state = { ...INITIAL_STATE };
  }

  onSubmit = (_Event) => {
    const {
      email,
      passwordOne,
    } = this.state;

    const {
      history,
    } = this.props;

    Auth.doCreateUserWithEmailAndPassword(email, passwordOne)
      .then(authUser => {
        
        // Create user in Firebase Database.
        Db.doCreateUser(authUser.user.uid, email)
          .then(() => {
            this.setState({ ...INITIAL_STATE });
            history.push(Routes.HOME);
          })
          .catch(error => {
            this.setState(byPropKey('error', error));
          });
      })
      .then(error => {
        this.setState(byPropKey('error', error));
      });
    
    _Event.preventDefault();
  }

  render() {
    const {
      email,
      passwordOne,
      passwordTwo,
      error,
    } = this.state;
    const {
      classes,
    } = this.props;

    const isInvalid = 
      passwordOne !== passwordTwo
      || passwordOne === ''
      || email === '';

    return (
      <div className={[classes.container, classes.margin].join(' ')} onSubmit={this.onSubmit}>
        <FormControl fullWidth>
          <InputLabel htmlFor='email-input'>Email</InputLabel>
          <Input
            className = {[classes.textField, classes.margin].join(' ')}
            id        = 'email-input'
            value     = {email}
            onChange  = {evt => this.setState(byPropKey('email', evt.target.value))}>
          </Input>
        </FormControl>
        <FormControl fullWidth>
          <InputLabel htmlFor='password-input'>Password</InputLabel>
          <Input
            type      = 'password'
            className = {[classes.textField, classes.margin].join(' ')}
            id        = 'password-input'
            value     = {passwordOne}
            onChange  = {evt => this.setState(byPropKey('passwordOne', evt.target.value))}>
          </Input>
        </FormControl>
        <FormControl fullWidth>
          <InputLabel htmlFor='password-confirm-input'>Confirm Password</InputLabel>
          <Input
            type      = 'password'
            className = {[classes.textField, classes.margin].join(' ')}
            id        = 'password-confirm-input'
            value     = {passwordTwo}
            onChange  = {evt => this.setState(byPropKey('passwordTwo', evt.target.value))}>
          </Input>
        </FormControl>
        <Button
          className = {[classes.margin, classes.button].join(' ')}
          size      = 'large'
          onClick   = {this.onSubmit}
          color     = 'primary'
          disabled  = {isInvalid}>
          Sign Up
        </Button>
        { error && <p>{error.message}</p> }
      </div>
    );
  }
}

const SignUpLinkComponent = () =>
  <p>
    Don't have an account?
    {' '}
    <Link to={Routes.SIGN_UP}>Sign Up</Link>
  </p>

export default compose(
  withStyles(styles),
  withRouter,
)(SignUpPage);

const SignUpForm = withStyles(formStyles)(SignUpFormComponent);
const SignUpLink = withStyles(formStyles)(SignUpLinkComponent);

export {
  SignUpForm,
  SignUpLink,
};