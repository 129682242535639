// ------------- DEPENDENCIES -------------- //

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import {
  withStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';

// ------------- LOCAL DEPENDENCIES -------------- //

import * as Utils from '../Utils';

// ------------- CONSTANTS & GLOBAL VARS -------------- //

const styles = ({
  root: {
    width : '100%',
    height: '100%',
  },
  center: {
    display       : 'flex',
    justifyContent: 'center',
    alignItems    : 'center',
    margin        : '0 auto',
  },
  hCenter: {
    textAlign: 'center',
  },
  assignmentField: {
    color     : '#000000',
    fontSize  : '14px !important',
    fontWeight: 400,
  },
  red: {
    color: '#FF0000',
  },
  green: {
    color: '#49E20E',
  },
  paidState: {
    marginRight: '10px',
  },
  assignmentValue: {
    marginRight: '10px',
  },
  cellPadding:{
    padding: 5,
  },
  headerCell: {
    color: '#757575',
  }
});

// ------------- MAIN -------------- //

class AssignmentsList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      assignments: [],
    };
  }

  /**
   * Parses assignments collection data and presents them in an array.
   * Assignment document IDs are added to items with key "id".
   *
   * @param {JSON} _JSON -> Downloaded assignments data.
   *
   * @return {Array<JSON>} -> Parsed assignments data.
   */
  parseJSON(_JSON) {
    const parsedData = [];
    
    for (const key in _JSON) {
      parsedData.push({
        id             : _JSON[key].id,
        startDate      : _JSON[key].startDate,
        dueDate        : _JSON[key].dueDate,
        clientPaid     : _JSON[key].clientPaid,
        contractorPaid : _JSON[key].contractorPaid,
        clientName     : _JSON[key].clientName,
        contractorName : _JSON[key].contractorName,
        notes          : _JSON[key].notes,
        value          : _JSON[key].value,
        assignmentID   : _JSON[key].assignmentID,
        clientWeChatID : _JSON[key].clientWeChatID,
        supervisorsPaid: _JSON[key].supervisorsPaid,
        refunded       : _JSON[key].refunded,
      });
    }

    return parsedData;
  }

  /**
   * Updates component assignments state with passed prop "assignments".
   *
   * @param {JSON} _Props -> Updated props.
   * @param {JSON} _CurrentState -> Previous component state.
   *
   * @return {JSON} -> Next updated state.
   */
  static getDerivedStateFromProps(_Props, _CurrentState) {
    if (_CurrentState.assignments !== _Props.assignments) {
      return {
        assignments: _Props.assignments,
      };
    }

    return null;
  }

  /**
   * Listens for assignment item click, and calls passed "onItemClick" function
   * in component props with assignment data.
   *
   * @param {function(JSON)} _Item -> Assignment data.
   */
  onItemClick(_Item) {
    const { onItemClick, } = this.props;

    if (onItemClick !== null && onItemClick !== undefined) {
      onItemClick(_Item);
    }
  }

  perc2Color = (_Percent) => {
    return _Percent == 1 ? '#49E20E': '#FF0000';
  }
  
  render() {
    const { classes, }      = this.props;
    const { assignments, }  = this.state;
    const parsedAssignments = this.parseJSON(assignments);

    console.log(parsedAssignments);

    return (
      <div
        className={classes.root}>
        <Table
          padding='none'>
          <TableHead>
            <TableRow>
              <TableCell
                padding   = 'none'
                classes   = {{ paddingNone: classes.cellPadding }}
                className = { [classes.assignmentField, classes.headerCell].join(' ') }>
                Start Date
              </TableCell>
              <TableCell
                padding   = 'none'
                classes   = {{ paddingNone: classes.cellPadding }}
                className = { [classes.assignmentField, classes.headerCell].join(' ') }>
                ID
              </TableCell>
              <TableCell
                padding   = 'none'
                classes   = {{ paddingNone: classes.cellPadding }}
                className = { [classes.assignmentField, classes.headerCell].join(' ') }>
                Client
              </TableCell>
              <TableCell
                padding   = 'none'
                classes   = {{ paddingNone: classes.cellPadding }}
                className = { [classes.assignmentField, classes.headerCell].join(' ') }>
                Contractor
              </TableCell>
              <TableCell
                padding   = 'none'
                classes   = {{ paddingNone: classes.cellPadding }}
                className = { [classes.assignmentField, classes.headerCell].join(' ') }>
                S.P.S
              </TableCell>
              <TableCell
                padding   = 'none'
                classes   = {{ paddingNone: classes.cellPadding }}
                className = { [classes.assignmentField, classes.headerCell].join(' ') }>
                WeChat ID
              </TableCell>
              <TableCell
                padding   = 'none'
                classes   = {{ paddingNone: classes.cellPadding }}
                className = { [classes.assignmentField, classes.headerCell].join(' ') }>
                Value
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              parsedAssignments.map((assignment, index) => {
                let numSupervisorsPaid = null;
                if (assignment.supervisorsPaid !== null && assignment.supervisorsPaid !== undefined) {
                  numSupervisorsPaid = assignment.supervisorsPaid.reduce((total, p) => { return p ? total + 1 : total} , 0);
                }
                console.log(index, numSupervisorsPaid);
                return (
                  <TableRow
                    key     = {index}
                    onClick = { () => this.onItemClick(assignment) }>
                    <TableCell
                      padding   = 'none'
                      classes   = {{ paddingNone: classes.cellPadding }}
                      className = { classes.assignmentField }>
                      { Utils.timestampToReadable(assignment.startDate) }
                    </TableCell>
                    <TableCell
                      padding   = 'none'
                      classes   = {{ paddingNone: classes.cellPadding }}
                      className = { classes.assignmentField }>
                      { assignment.assignmentID }
                    </TableCell>
                    <TableCell
                      padding   = 'none'
                      classes   = {{ paddingNone: classes.cellPadding }}
                      className = { [classes.assignmentField, assignment.clientPaid ? classes.green : classes.red].join(' ') }>
                      { assignment.clientName }
                    </TableCell>
                    <TableCell
                      padding   = 'none'
                      classes   = {{ paddingNone: classes.cellPadding }}
                      className = { [classes.assignmentField, assignment.contractorPaid ? classes.green : classes.red].join(' ') }>
                      { assignment.contractorName }
                    </TableCell>
                    <TableCell
                      padding   = 'none'
                      classes   = {{ paddingNone: classes.cellPadding }}
                      className = { [classes.assignmentField, assignment.contractorPaid ? classes.green : classes.red].join(' ') }
                      style     = {{color: numSupervisorsPaid !== null ? this.perc2Color(numSupervisorsPaid / assignment.supervisorsPaid.length) : 'unset'}}>
                      { numSupervisorsPaid !== null ? `${numSupervisorsPaid}/${assignment.supervisorsPaid.length}` : 'N/A' }
                    </TableCell>
                    <TableCell
                      padding   = 'none'
                      classes   = {{ paddingNone: classes.cellPadding }}
                      className = { [classes.assignmentField].join(' ') }>
                      { assignment.clientWeChatID }
                    </TableCell>
                    <TableCell
                      padding   = 'none'
                      classes   = {{ paddingNone: classes.cellPadding }}
                      className = { [classes.assignmentField, assignment.refunded ? classes.red : classes.green].join(' ') }>
                      { `$${assignment.value}` }
                    </TableCell>
                  </TableRow>
                );
              })
            }
          </TableBody>
        </Table>
      </div>
    );
  }
}

const mapStateToProps = (_State) => ({
  authUser: _State.sessionState.authUser,
});

export default compose(
  connect(mapStateToProps),
  withStyles(styles),
)(AssignmentsList);