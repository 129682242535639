// ------------- LOCAL DEPENDENCIES -------------- //

import {
  Storage,
} from './firebase';
import {
  Db,
} from './index';

// ------------- MAIN -------------- //

export const doUploadFiles = (_AssignmentID, _Files) => {
  const promises = [];

  console.log(_Files);
  for (let i = 0 ; i < _Files.length; i++) {
    console.log(_Files[i].file);
    promises.push(Storage.child(`assignments/${_AssignmentID}/images/${_Files[i].fileName}`).put(_Files[i].file));
  }

  return Promise.all(promises);
}

export const doUploadCriteriaFiles = (_AssignmentID, _Files) => {
  const promises = [];

  console.log(_Files);
  for (let i = 0; i < _Files.length; i++) {
    console.log(_Files[i].file);
    if (_Files[i].file === null || _Files[i].file === undefined) continue;
    promises.push(Storage.child(`assignments/${_AssignmentID}/images/criteria/${_Files[i].fileName}`).put(_Files[i].file));
  }

  return Promise.all(promises);
}

export const doUploadSupervisorPaidFiles = (_AssignmentID, _Files) => {
  const promises = [];

  console.log(_Files);
  for (let i = 0; i < _Files.length; i++) {
    console.log(_Files[i].file);
    if (_Files[i].file === null || _Files[i].file === undefined) continue;
    promises.push(Storage.child(`assignments/${_AssignmentID}/images/paid/supervisors/${_Files[i].fileName}`).put(_Files[i].file));
  }

  return Promise.all(promises);
}

export const doUploadContractorPaidFiles = (_AssignmentID, _Files) => {
  const promises = [];

  console.log(_Files);
  for (let i = 0; i < _Files.length; i++) {
    console.log(_Files[i].file);
    if (_Files[i].file === null || _Files[i].file === undefined) continue;
    promises.push(Storage.child(`assignments/${_AssignmentID}/images/paid/contractor/${_Files[i].fileName}`).put(_Files[i].file));
  }

  return Promise.all(promises);
}

export const doUploadClientPaidFiles = (_AssignmentID, _Files) => {
  const promises = [];

  console.log(_Files);
  for (let i = 0; i < _Files.length; i++) {
    console.log(_Files[i].file);
    if (_Files[i].file === null || _Files[i].file === undefined) continue;
    promises.push(Storage.child(`assignments/${_AssignmentID}/images/paid/client/${_Files[i].fileName}`).put(_Files[i].file));
  }

  return Promise.all(promises);
}

export const doCheckFilesExist = (_AssignmentID, _Files) => {
  return Db.doDownloadAssignmentData(_AssignmentID).then(assignmentData => {
    if (assignmentData !== null) {
      const doFilesExist = {};
      console.table(assignmentData);

      if (assignmentData.hasOwnProperty('fileNames')) {
        doFilesExist['fileNames'] = {};
        for (let i = 0; i < _Files.length; i++) {
          doFilesExist['fileNames'][_Files[i]] = assignmentData.fileNames.includes(_Files[i]);
        }
      }

      if (assignmentData.hasOwnProperty('criteriaFileNames')) {
        doFilesExist['criteriaFileNames'] = {};
        for (let i = 0; i < _Files.length; i++) {
          doFilesExist['criteriaFileNames'][_Files[i]] = assignmentData.criteriaFileNames.includes(_Files[i]);
        }
      }

      if (assignmentData.hasOwnProperty('supervisorPaidFileNames')) {
        doFilesExist['supervisorPaidFileNames'] = {};
        for (let i = 0; i < _Files.length; i++) {
          doFilesExist['supervisorPaidFileNames'][_Files[i]] = assignmentData.supervisorPaidFileNames.includes(_Files[i]);
        }
      }

      if (assignmentData.hasOwnProperty('contractorPaidFileNames')) {
        doFilesExist['contractorPaidFileNames'] = {};
        for (let i = 0; i < _Files.length; i++) {
          doFilesExist['contractorPaidFileNames'][_Files[i]] = assignmentData.contractorPaidFileNames.includes(_Files[i]);
        }
      }

      if (assignmentData.hasOwnProperty('clientPaidFileNames')) {
        doFilesExist['clientPaidFileNames'] = {};
        for (let i = 0; i < _Files.length; i++) {
          doFilesExist['clientPaidFileNames'][_Files[i]] = assignmentData.clientPaidFileNames.includes(_Files[i]);
        }
      }
      console.table(doFilesExist);

      return doFilesExist;
    } else {
      return null;
    }
  });
}

export const doGetAssignmentFileURL = (_AssignmentID, _FileName) => {
  console.log(`${_AssignmentID}, ${_FileName}`);
  return Storage.child(`assignments/${_AssignmentID}/images/${_FileName}`).getDownloadURL();
}

export const doGetAssignmentCriteriaFileURL = (_AssignmentID, _FileName) => {
  console.log(`${_AssignmentID}, ${_FileName}`);
  return Storage.child(`assignments/${_AssignmentID}/images/criteria/${_FileName}`).getDownloadURL();
}

export const doGetAssignmentSupervisorPaidFileURL = (_AssignmentID, _FileName) => {
  console.log(`${_AssignmentID}, ${_FileName}`);
  return Storage.child(`assignments/${_AssignmentID}/images/paid/supervisors/${_FileName}`).getDownloadURL();
}

export const doGetAssignmentContractorPaidFileURL = (_AssignmentID, _FileName) => {
  console.log(`${_AssignmentID}, ${_FileName}`);
  return Storage.child(`assignments/${_AssignmentID}/images/paid/contractor/${_FileName}`).getDownloadURL();
}

export const doGetAssignmentClientPaidFileURL = (_AssignmentID, _FileName) => {
  console.log(`${_AssignmentID}, ${_FileName}`);
  return Storage.child(`assignments/${_AssignmentID}/images/paid/client/${_FileName}`).getDownloadURL();
}

export const doDeleteAssignmentFile = (_AssignmentIDRef, _AssignmentID, _FileName, _NewFiles) => {
  return Db.doUpdateAssignmentFiles(_AssignmentIDRef, _NewFiles).then(() => {
    return Storage.child(`assignments/${_AssignmentID}/images/${_FileName}`).delete();
  });
}

export const doDeleteAssignmentCriteriaFile = (_AssignmentIDRef, _AssignmentID, _FileName, _NewFiles) => {
  return Db.doUpdateAssignmentFiles(_AssignmentIDRef, _NewFiles).then(() => {
    return Storage.child(`assignments/${_AssignmentID}/images/criteria/${_FileName}`).delete();
  });
}

export const doDeleteSupervisorPaidFile = (_AssignmentIDRef, _AssignmentID, _FileName, _NewFiles) => {
  return Storage.child(`assignments/${_AssignmentID}/images/paid/supervisors/${_FileName}`).delete();
}

export const doDeleteContractorPaidFile = (_AssignmentIDRef, _AssignmentID, _FileName, _NewFiles) => {
  return Storage.child(`assignments/${_AssignmentID}/images/paid/contractor/${_FileName}`).delete();
}

export const doDeleteClientPaidFile = (_AssignmentIDRef, _AssignmentID, _FileName, _NewFiles) => {
  return Storage.child(`assignments/${_AssignmentID}/images/paid/client/${_FileName}`).delete();
}