// ------------- LOCAL DEPENDENCIES -------------- //

import { Db } from './firebase';
import * as Storage from './storage';

// ------------- MAIN -------------- //

export const doCreateUser = (_Id, _Email) =>
  Db.collection('users').doc(`${_Id}`).set({
    email: _Email,
  });

export const doDownloadAssignmentData = (_ID) =>
  Db.collection('assignments').doc(`${_ID}`).get().then((_AssignmentData) => {
    if (!_AssignmentData.exists) {
      return null;
    } else {
      return _AssignmentData.data();
    }
  });

export const doDownloadAssignments = () =>
  Db.collection('assignments').orderBy('startDate', 'desc').get().then((_Assignments) => {
    if (_Assignments.empty) {
      return null;
    } else {
      const parsedAssignments = {};

      for (let i = 0; i < _Assignments.docs.length; i++) {
        parsedAssignments[_Assignments.docs[i].id]    = _Assignments.docs[i].data();
        parsedAssignments[_Assignments.docs[i].id].id = _Assignments.docs[i].id;
      }

      return parsedAssignments;
    }
  });

export const doDownloadPaginatedAssignments = (_Next, _NumAssignments) => {
  let query = Db.collection('assignments').orderBy('startDate', 'desc');

  if (_Next !== null) {
    query = query.startAfter(_Next);
  }

  return query.limit(_NumAssignments).get().then((_Assignments) => {
    if (_Assignments.empty) {
      return {
        assignments: null,
        next       : null,
      };
    } else {
      const parsedAssignments = {};
      let   next              = null;

      for (let i = 0; i < _Assignments.docs.length; i++) {
        parsedAssignments[_Assignments.docs[i].id]    = _Assignments.docs[i].data();
        parsedAssignments[_Assignments.docs[i].id].id = _Assignments.docs[i].id;
        if (i === _Assignments.docs.length - 1) {
          next = parsedAssignments[_Assignments.docs[i].id].startDate;
        }
      }

      return {
        assignments: parsedAssignments,
        next,
      };
    }
  });
}

export const doDownloadContractors = () =>
  Db.collection('contractors').get().then((_Contractors) => {
    if (_Contractors.empty) {
      return null;
    } else {
      const parsedUsers = {};

      for (let i = 0; i < _Contractors.docs.length; i++) {
        parsedUsers[_Contractors.docs[i].id]    = _Contractors.docs[i].data();
        parsedUsers[_Contractors.docs[i].id].id = _Contractors.docs[i].id;
      }

      return parsedUsers;
    }
  });

export const doDownloadClients = () =>
  Db.collection('clients').get().then((_Clients) => {
    if (_Clients.empty) {
      return null;
    } else {
      const parsedUsers = {};

      for (let i = 0; i < _Clients.docs.length; i++) {
        parsedUsers[_Clients.docs[i].id]    = _Clients.docs[i].data();
        parsedUsers[_Clients.docs[i].id].id = _Clients.docs[i].id;
      }

      return parsedUsers;
    }
  });

export const doDownloadSupervisors = () =>
  Db.collection('supervisors').get().then((_Supervisors) => {
    if (_Supervisors.empty) {
      return null;
    } else {
      const parsedUsers = {};

      for (let i = 0; i < _Supervisors.docs.length; i++) {
        parsedUsers[_Supervisors.docs[i].id]    = _Supervisors.docs[i].data();
        parsedUsers[_Supervisors.docs[i].id].id = _Supervisors.docs[i].id;
      }

      return parsedUsers;
    }
  });

export const doDownloadClientValue = (_ClientID) => {
  return Db.collection('assignments').where('clientID', '==', _ClientID).get().then((_RelevantAssignments) => {
    if (!_RelevantAssignments.empty) {
      let sum = 0;

      for (let i = 0; i < _RelevantAssignments.size; i++) {
        const data = _RelevantAssignments.docs[i].data();
        if (!isNaN(parseInt(data.value))) {
          sum += parseInt(data.value);
        }
      }

      return sum;
    } else {
      return null;
    }
  });
}

export const doDownloadSupervisorTotalFees = (_SupervisorID) => {
  return Db.collection('assignments').where('supervisorIDs', 'array-contains', _SupervisorID).get().then((_RelevantAssignments) => {
    if (!_RelevantAssignments.empty) {
      let sum = 0;

      for (let i = 0; i < _RelevantAssignments.size; i++) {
        const data        = _RelevantAssignments.docs[i].data();
        const supervisorI = data.supervisorIDs.indexOf(_SupervisorID);
        if (supervisorI !== -1) {
          if (!isNaN(parseFloat(data.supervisorFees[supervisorI]))) {
            sum += parseFloat(data.supervisorFees[supervisorI]);
          }
        }
      }

      return sum;
    } else {
      return null;
    }
  });
}

export const doDownloadSupervisorData = (_SupervisorID) =>
  doDownloadFilteredSupervisors([{ filter: 'supervisorID', value: _SupervisorID }]).then((supervisors) => {
    if (supervisors !== null && supervisors.length > 0) {
      const supervisor = supervisors[0];
      return supervisor;
    } else {
      return null;
    }
  });

export const doDownloadSupervisorFee = (_SupervisorID) =>
  Db.collection('supervisors').where('supervisorID', '==', _SupervisorID).get()
  .then((_SupervisorData) => {
    if (!_SupervisorData.empty) {
      const supervisorData = _SupervisorData.docs[0].data();
      if (supervisorData.supervisorFee !== null
          && supervisorData.supervisorFee !== undefined) {
        return supervisorData.supervisorFee;
      } else {
        return null;
      }
    } else {
      return null;
    }
  });

export const doDownloadClientData = (_ClientID) => {
  return Db.collection('clients').where('clientID', '==', _ClientID).get().then((_RelevantClients) => {
    if (!_RelevantClients.empty) {
      return _RelevantClients.docs[0].data();
    } else {
      return null;
    }
  });
}

export const doDownloadContractorFees = (_ContractorID) => {
  return Db.collection('assignments').where('contractorID', '==', _ContractorID).get().then((_RelevantAssignments) => {
    if (!_RelevantAssignments.empty) {
      var sum = 0;

      for (let i = 0; i < _RelevantAssignments.size; i++) {
        const data = _RelevantAssignments.docs[i].data();
        if (!isNaN(parseInt(data.contractorFee))) {
          sum += parseInt(data.contractorFee);
        }
      }

      return sum;
    } else {
      return null;
    }
  });
}

export const doDownloadSavedAssignment = (_ID) =>
  Db.collection('assignments').doc(`${_ID}`).get().then((assignmentData) => {
    if (!assignmentData.exists) {
      return null;
    } else {
      return assignmentData.data();
    }
  });

export const doDownloadFilteredAssignments = (_Filters) => {
  let query = Db.collection('assignments');

  for (let i = 0; i < _Filters.length; i++) {
    if (_Filters[i].value === null) continue;
    query = query.where(_Filters[i].filter, _Filters[i].comp == undefined ? '==' : _Filters[i].comp, _Filters[i].value);
  }

  return query.get().then((filteredAssignments) => {
    if (!filteredAssignments.empty) {
      const parsedAssignments = [];
      
      for (let i = 0; i < filteredAssignments.size; i++) {
        const assignment = filteredAssignments.docs[i].data();

        assignment.id = filteredAssignments.docs[i].id;
        parsedAssignments.push(assignment);
      }
      
      return parsedAssignments;
    } else {
      return [];
    }
  });
}

export const doHomePageFilterAndSortAssignments = (_Filters, _SortField, _SortDirection) => {
  let query = Db.collection('assignments');

  for (let i = 0; i < _Filters.length; i++) {
    if (_Filters[i].value == null
        || _Filters[i].value === ''
        || _Filters[i].filter === null
        || _Filters[i].filter === '') continue;
    if (_Filters[i].filter === 'startDate') {
      query = query.where('startDate', '>=', _Filters[i].value);
    } else if (_Filters[i].filter === 'endDate') {
      query = query.where('startDate', '<=', _Filters[i].value);
    } else {
      query = query.where(_Filters[i].filter, '==', _Filters[i].value);
    }
  }

  if (_SortField !== null && _SortField !== undefined && _SortField !== '') {
    query = query.orderBy(_SortField, _SortDirection);
  }

  return query.get().then((filteredAssignments) => {
    if (!filteredAssignments.empty) {
      const parsedAssignments = [];
      
      for (let i = 0; i < filteredAssignments.size; i++) {
        const assignment = filteredAssignments.docs[i].data();

        assignment.id = filteredAssignments.docs[i].id;
        parsedAssignments.push(assignment);
      }
      
      return parsedAssignments;
    } else {
      return [];
    }
  });
}

export const doDownloadRangedAssignments = (_StartDate, _EndDate, _UserData) => {
  let query = Db.collection('assignments');

  if (_StartDate !== null && _StartDate !== undefined) {
    query = query.where('startDate', '>=', _StartDate);
  }
  if (_EndDate !== null && _EndDate !== undefined) {
    query = query.where('startDate', '<=', _EndDate + 86400000);
  }

  if (_UserData !== undefined) {
    query = query.where(_UserData.field, '==', _UserData.value);
  }

  return query.get().then((filteredAssignments) => {
    if (!filteredAssignments.empty) {
      const parsedAssignments = [];
      
      for (let i = 0; i < filteredAssignments.size; i++) {
        const assignment = filteredAssignments.docs[i].data();

        assignment.id = filteredAssignments.docs[i].id;
        parsedAssignments.push(assignment);
      }
      
      return parsedAssignments;
    } else {
      return [];
    }
  });
}

export const doDownloadRangedSupervisorAssignments = (_StartDate, _EndDate, _UserData) => {
  let query = Db.collection('assignments');

  if (_StartDate !== null && _StartDate !== undefined) {
    query = query.where('startDate', '>=', _StartDate);
  }
  if (_EndDate !== null && _EndDate !== undefined) {
    query = query.where('startDate', '<=', _EndDate + 86400000);
  }

  if (_UserData !== undefined) {
    query = query.where(_UserData.field, 'array-contains', _UserData.value);
  }

  return query.get().then((filteredAssignments) => {
    if (!filteredAssignments.empty) {
      const parsedAssignments = [];
      
      for (let i = 0; i < filteredAssignments.size; i++) {
        const assignment = filteredAssignments.docs[i].data();

        assignment.id = filteredAssignments.docs[i].id;
        parsedAssignments.push(assignment);
      }
      
      return parsedAssignments;
    } else {
      return [];
    }
  });
}

export const doBulkContractorPay = (_ContractorID, _Files, _ProgressCb, _Excluded) =>
  Db.collection('assignments')
  .where('contractorID', '==', _ContractorID)
  .where('contractorPaid', '==', false)
  .where('clientPaid', '==', true)
  .get().then((_RelevantDocs) => {
    if (!_RelevantDocs.empty) {
      const promises = [];

      for (let i = 0; i < _Files.length; i++) {
        _Files[i].fileName = `Bulk Paid ${i + 1}${_Files[i].fileName.substring( _Files[i].fileName.lastIndexOf('.'))}`;
      }

      console.table((_Files.map(f => f.fileName)));
      for (let i = 0; i < _RelevantDocs.docs.length; i++) {
        const assignData = _RelevantDocs.docs[i].data();
        if (_Excluded.includes(assignData.assignmentID)) continue;
        promises.push(Storage.doUploadContractorPaidFiles(assignData.assignmentID, _Files).then(() => {
          return doUpdateAssignmentContractorPaidFiles(_RelevantDocs.docs[i].id,[
            ..._Files.map(f => f.fileName),
            ...assignData.contractorPaidFileNames]).then(() => {
            return doSetPaidState(_RelevantDocs.docs[i].id, true);
          });
        }));
      }

      return allProgress(promises, _ProgressCb).then(() => promises.length).catch(() => 0);
    } else {
      return 0;
    }
  });
export const doBulkSupervisorPay = (_SupervisorID, _Files, _ProgressCb, _Excluded) =>
  Db.collection('assignments')
  .where('supervisorIDs', 'array-contains', _SupervisorID)
  .where('clientPaid', '==', true)
  .get().then((docs) => {
    if (!docs.empty) {
      const promises = [];

      for (let i = 0; i < _Files.length; i++) {
        _Files[i].fileName = `Bulk Paid ${i + 1}${_Files[i].fileName.substring( _Files[i].fileName.lastIndexOf('.'))}`;
      }

      const relevantDocs = docs.docs
        .map(d => ({
          ...d.data(),
          id: d.id,
        }))
        .filter(d => {
          const supervisorIndex = d.supervisorIDs.indexOf(_SupervisorID);
          console.log(_SupervisorID, d.supervisorIDs);

          if (supervisorIndex !== -1) {
            return !d.supervisorsPaid[supervisorIndex] && d.paymentApproval;
          }

          return false;
        });
      for (let i = 0; i < relevantDocs.length; i++) {
        const assignData = relevantDocs[i];
        if (_Excluded.includes(assignData.assignmentID)) continue;
        promises.push(Storage.doUploadSupervisorPaidFiles(assignData.assignmentID, _Files)
          .then(() => {
          return doUpdateAssignmentSupervisorPaidFiles(assignData.id, [
            ..._Files.map(f => f.fileName),
            ...assignData.supervisorPaidFileNames]).then(() => {
            return doSetSupervisorPaidState(assignData.id, _SupervisorID, true);
          });
        }));
      }

      return allProgress(promises, _ProgressCb).then(() => promises.length).catch(() => 0);
    } else {
      return 0;
    }
  });

export const doDownloadFilteredClients = (_Filters) => {
  let query = Db.collection('clients');

  for (let i = 0; i < _Filters.length; i++) {
    if (_Filters[i].value === null) continue;
    query = query.where(_Filters[i].filter, '==', _Filters[i].value);
  }

  return query.get().then((filteredClients) => {
    if (!filteredClients.empty) {
      const parsedClients = [];
      
      for (let i = 0; i < filteredClients.size; i++) {
        const client = filteredClients.docs[i].data();

        client.id = filteredClients.docs[i].id;
        parsedClients.push(client);
      }
      
      return parsedClients;
    } else {
      return [];
    }
  });
}

export const doDownloadFilteredSupervisors = (_Filters) => {
  console.log(_Filters);
  let query = Db.collection('supervisors');

  for (let i = 0; i < _Filters.length; i++) {
    if (_Filters[i].value === null) continue;
    query = query.where(_Filters[i].filter, '==', _Filters[i].value);
  }

  return query.get().then((filteredSupervisors) => {
    if (!filteredSupervisors.empty) {
      const parsedSupervisors = [];
      
      for (let i = 0; i < filteredSupervisors.size; i++) {
        const supervisor = filteredSupervisors.docs[i].data();

        supervisor.id = filteredSupervisors.docs[i].id;
        parsedSupervisors.push(supervisor);
      }
      
      return parsedSupervisors;
    } else {
      return [];
    }
  });
}

export const doDownloadFilteredContractors = (_Filters) => {
  console.log(_Filters);
  let query = Db.collection('contractors');

  for (let i = 0; i < _Filters.length; i++) {
    if (_Filters[i].value === null) continue;
    query = query.where(_Filters[i].filter, '==', _Filters[i].value);
  }

  return query.get().then((filteredContractors) => {
    if (!filteredContractors.empty) {
      const parsedContractors = [];
      
      for (let i = 0; i < filteredContractors.size; i++) {
        const contractor = filteredContractors.docs[i].data();

        contractor.id = filteredContractors.docs[i].id;
        parsedContractors.push(contractor);
      }
      
      return parsedContractors;
    } else {
      return [];
    }
  });
}

export const doUpdateAssignmentClient = (_ID, _UpdatedClientData) =>
  doFetchNewAssignmentID(_UpdatedClientData.clientID).then(newID => {
    return new Promise((resolve, reject) => {
      doUpdateAssignment(_ID, {
        assignmentID: newID,
        ..._UpdatedClientData,
      }).then(resolve).catch(reject);
    }).then(() => {
      return newID;
    }).catch(() => {
      return false;
    });
  });

export const doUpdateAssignment = (_ID, _AssignmentData) =>
  Db.collection('assignments').doc(`${_ID}`).set(_AssignmentData, {
    merge: true,
  });

export const doSetPaidState = (_ID, _IsContractor) =>
  Db.collection('assignments').doc(`${_ID}`).get().then((paidStateData) => {
    if (!paidStateData.exists) {
      return false;
    } else {
      paidStateData = paidStateData.data();

      if (_IsContractor && !paidStateData.contractorPaid) {
        return true;
      } else if (!_IsContractor && !paidStateData.clientPaid) {
        return true;
      } else {
        return false;
      }
    }
  }).then((validRequest) => {
    if (validRequest) {
      const targetString = _IsContractor ? 'contractorPaid' : 'clientPaid';
      return Db.collection('assignments').doc(`${_ID}`).update({
        [targetString]         : true,
        [`${targetString}Date`]: Date.now(),
      })
      .then(() => { return true; })
      .catch((err) => { return false; });
    } else {
    }
  }).catch((err) => {
    return false;
  });

export const doSetSupervisorPaidState = (assignmentDocumentID, supervisorID, newState) =>
  Db.collection('assignments').doc(`${assignmentDocumentID}`).get()
    .then(returnedDocument => {
      if (returnedDocument.exists) {
        const documentData = returnedDocument.data();
        const supervisorIndex = documentData.supervisorIDs.indexOf(supervisorID);

        if (supervisorIndex !== -1) {
          const updatedSupervisorPaidState = [...documentData.supervisorsPaid];
          updatedSupervisorPaidState[supervisorIndex] = newState;
          const updatedSupervisorPaidDates = [...documentData.supervisorsPaidDates];
          updatedSupervisorPaidDates[supervisorIndex] = Date.now();

          return Db.collection('assignments').doc(`${assignmentDocumentID}`)
            .update({
              supervisorsPaid: updatedSupervisorPaidState,
              supervisorsPaidDates: updatedSupervisorPaidDates,
            });
        }
      }

      return false;
    });

export const doFlagAssignmentAsRefunded = (_ID, _SupervisorPenalties, _ContractorPenalty) =>
  Db.collection('assignments').doc(`${_ID}`).update({
    refunded: true,
  }).then(() => {
    const promises = [];

    for (let [id, penalty] of Object.entries(_SupervisorPenalties)) {
      promises.push(doSetSupervisorPenalty(id, parseFloat(penalty)));
    }
    if (_ContractorPenalty) {
      promises.push(doSetContractorPenalty(_ContractorPenalty.id, parseFloat(_ContractorPenalty.penalty)));
    }

    return Promise.all(promises);
  });

export const doUpdateSupervisorFee = (supervisorID, supervisorFee) =>
  Db.collection('supervisors').doc(`${supervisorID}`).update({
    supervisorFee,
  });

export const doSetSupervisorPenalty = (_SupervisorID, _Penalty) => {
  if (_Penalty == null) return new Promise((res, rej) => res());
  return doDownloadFilteredSupervisors([{ filter: 'supervisorID', value: _SupervisorID }]).then((supervisors) => {
    if (supervisors !== null && supervisors.length > 0) {
      const supervisor = supervisors[0];
      return Db.collection('supervisors').doc(supervisor.id).update({
        penalty: supervisor.penalty === undefined ? _Penalty : supervisor.penalty + _Penalty,
      });
    } else {
      console.log(`ERR: Supervisors empty: ${supervisors}, ${_SupervisorID}`);
    }
  });
}

export const doSetContractorPenalty = (_ContractorID, _Penalty) => {
  if (_Penalty == null) return new Promise((res, rej) => res());
  return doDownloadFilteredContractors([{ filter: 'contractorID', value: _ContractorID }]).then((contractors) => {
    if (contractors !== null && contractors.length > 0) {
      const contractor = contractors[0];
      return Db.collection('contractors').doc(contractor.id).update({
        penalty: contractor.penalty === undefined ? _Penalty : contractor.penalty + _Penalty,
      });
    } else {
      console.log(`ERR: Contractors empty: ${contractors}, ${_ContractorID}`);
    }
  });
};

export const doReduceSupervisorPenalty = (_SupervisorID, _PenaltyRelief) =>
  doDownloadFilteredSupervisors([{ filter: 'supervisorID', value: _SupervisorID }]).then((supervisors) => {
    if (supervisors !== null && supervisors.length > 0) {
      const supervisor = supervisors[0];
      return Db.collection('supervisors').doc(`${supervisor.id}`).update({
        penalty: supervisor.penalty === undefined || _PenaltyRelief > supervisor.penalty ? 0 : supervisor.penalty - _PenaltyRelief,
      });
    } else {
      console.log(`ERR: Supervisors empty: ${supervisors}, ${_SupervisorID}`);
    }
  });

export const doReduceContractorPenalty = (_ContractorID, _PenaltyRelief) =>
  doDownloadFilteredContractors([{ filter: 'contractorID', value: _ContractorID }]).then((contractors) => {
    if (contractors !== null && contractors.length > 0) {
      const contractor = contractors[0];
      return Db.collection('contractors').doc(`${contractor.id}`).update({
        penalty: contractor.penalty === undefined || _PenaltyRelief > contractor.penalty ? 0 : contractor.penalty - _PenaltyRelief,
      });
    } else {
      console.log(`ERR: Contractors empty: ${contractors}, ${_ContractorID}`);
    }
  });

export const doSetPaymentApprovalState = (_ID) =>
  Db.collection('assignments').doc(`${_ID}`).update({
    paymentApproval    : true,
    paymentApprovalDate: Date.now(),
  });

export const doFetchNewAssignmentID = (_ClientID) =>
  Db.collection('assignments')
    .where('assignmentID', '>=', `${_ClientID}0000`)
    .where('assignmentID', '<=', `${parseInt(_ClientID.slice(0, 4)) + 1}0000`.padStart(8, '0'))
    .orderBy('assignmentID', 'desc').limit(1).get().then((results) => {
      var assignmentID = '0001';
      
      if (!results.empty) {
        assignmentID = `${parseInt(results.docs[0].data().assignmentID.slice(4)) + 1}`.padStart(4, 0);
      }

      return `${_ClientID}${assignmentID}`;
    });

export const doPublishAssignment = (_UID, _AssignmentData) => {
  return Db.collection('assignments')
    .where('assignmentID', '>=', `${_AssignmentData.clientID}0000`)
    .where('assignmentID', '<=', `${parseInt(_AssignmentData.clientID.slice(0, 4)) + 1}0000`.padStart(8, '0'))
    .orderBy('assignmentID', 'desc').limit(1).get().then((results) => {
      var assignmentID = '0001';
      
      if (!results.empty) {
        assignmentID = `${parseInt(results.docs[0].data().assignmentID.slice(4)) + 1}`.padStart(4, 0);
      }

      assignmentID = `${_AssignmentData.clientID}${assignmentID}`;

      return Db.collection('assignments').add({
        startDate      : Date.now(),
        clientName     : _AssignmentData.clientName.toLowerCase(),
        contractorName : _AssignmentData.contractorName.toLowerCase(),
        supervisorNames: _AssignmentData.supervisorNames.map(s => s.toLowerCase()),
        clientPaid     : _AssignmentData.clientPaid,
        contractorPaid : false,
        uploaderID     : _UID,
        assignmentID,
        ..._AssignmentData,
      }).then(() => {
        return assignmentID;
      });
    });
}

export const doDownloadUserData = (_UID) =>
  Db.collection('users').doc(`${_UID}`).get();

export const doCreateClient = (_UID, _ClientData) =>
  doFetchNewClientID().then(newID => {
    return Db.collection('clients').add({
      ..._ClientData,
      creationDate: Date.now(),
      creatorID   : _UID,
      clientID    : newID,
    }).then(() => {
      return newID;
    });
  });

export const doCreateSupervisor = (_UID, _SupervisorData) =>
  doFetchNewSupervisorID().then(newID => {
    return Db.collection('supervisors').add({
      ..._SupervisorData,
      creationDate: Date.now(),
      creatorID   : _UID,
      supervisorID: newID,
    }).then(() => {
      return newID;
    });
  });

export const doFetchNewContractorID = () =>
  Db.collection('contractors').orderBy('contractorID', 'desc').limit(1).get().then((result) => {
    var newID = '0001';
    if (!result.empty) {
      const greatestID = result.docs[0].data().contractorID;
      
      newID = `${parseInt(greatestID) + 1}`.padStart(4, '0');
    }
    return newID;
  });

export const doFetchNewClientID = () =>
  Db.collection('clients').orderBy('clientID', 'desc').limit(1).get().then((result) => {
    var newID = '0001';
    if (!result.empty) {
      const greatestID = result.docs[0].data().clientID;
      
      newID = `${parseInt(greatestID) + 1}`.padStart(4, '0');
    }
    return newID;
  });

export const doFetchNewSupervisorID = () =>
  Db.collection('supervisors').orderBy('supervisorID', 'desc').limit(1).get().then((result) => {
    var newID = '0001';
    if (!result.empty) {
      const greatestID = result.docs[0].data().supervisorID;
      
      newID = `${parseInt(greatestID) + 1}`.padStart(4, '0');
    }
    return newID;
  });

export const doCreateContractor = (_UID, _ContractorData) =>
  doFetchNewContractorID().then(newID => {
    return Db.collection('contractors').add({
      ..._ContractorData,
      creationDate: Date.now(),
      creatorID   : _UID,
      contractorID: newID,
    }).then(() => {
      return newID;
    });
  });

export const doDownloadAssignmentIDs = () => {
  return Db.collection('assignments').get().then((results) => {
    if (!results.empty) {
      const clientNames = [];

      for (var i = 0; i < results.size; i++) {
        clientNames.push(`${results.docs[i].data().assignmentID}`);
      }

      return clientNames;
    } else {
      return null;
    }
  });
}

export const doDownloadClientNames = () => {
  return Db.collection('clients').get().then((results) => {
    if (!results.empty) {
      const clientNames = [];

      for (var i = 0; i < results.size; i++) {
        clientNames.push(`${results.docs[i].data().clientName} - ${results.docs[i].data().clientID}`);
      }

      return clientNames;
    } else {
      return null;
    }
  });
}

export const doDownloadSupervisorNames = () => {
  return Db.collection('supervisors').get().then((results) => {
    if (!results.empty) {
      const supervisorNames = [];

      for (var i = 0; i < results.size; i++) {
        supervisorNames.push(`${results.docs[i].data().supervisorName} - ${results.docs[i].data().supervisorID}`);
      }

      return supervisorNames;
    } else {
      return null;
    }
  });
}

export const doDownloadContractorNames = () => {
  return Db.collection('contractors').get().then((results) => {
    if (!results.empty) {
      const contractorNames = [];

      for (var i = 0; i < results.size; i++) {
        contractorNames.push(`${results.docs[i].data().contractorName} - ${results.docs[i].data().contractorID}`);
      }

      return contractorNames;
    } else {
      return null;
    }
  });
}

export const doUpdateAssignmentFiles = (_AssignmentIDRef, _FileNames) => {
  return Db.collection('assignments').doc(`${_AssignmentIDRef}`).update({
    fileNames: _FileNames,
  });
}

export const doUpdateAssignmentCriteriaFiles = (_AssignmentIDRef, _FileNames) =>
  Db.collection('assignments').doc(`${_AssignmentIDRef}`).update({
    criteriaFileNames: _FileNames,
  });

export const doUpdateAssignmentSupervisorPaidFiles = (_AssignmentIDRef, _FileNames) =>
  Db.collection('assignments').doc(`${_AssignmentIDRef}`).update({
    supervisorPaidFileNames: _FileNames,
  });

export const doUpdateAssignmentContractorPaidFiles = (_AssignmentIDRef, _FileNames) =>
  Db.collection('assignments').doc(`${_AssignmentIDRef}`).update({
    contractorPaidFileNames: _FileNames,
  });

export const doUpdateAssignmentClientPaidFiles = (_AssignmentIDRef, _FileNames) =>
  Db.collection('assignments').doc(`${_AssignmentIDRef}`).update({
    clientPaidFileNames: _FileNames,
  });

const allProgress = (promises, prog_cb) => {
  let d = 0;
  prog_cb(0);
  promises.forEach((p) => {
    p.then(() => {
      d++;
      prog_cb((d * 100) / promises.length);
    });
  });
  return Promise.all(promises);
}
