// ------------- DEPENDENCIES -------------- //

import React from 'react';
import { connect } from 'react-redux';

// ------------- LOCAL DEPENDENCIES -------------- //

import * as Actions from '../reducers/action_types';
import { Firebase } from '../firebase';

// ------------- MAIN -------------- //

const withAuthentication = (Component) => {
  class WithAuthentication extends React.Component {
    componentDidMount() {
      const { onSetAuthUser } = this.props;

      Firebase.Auth.onAuthStateChanged(_AuthUser => {
        _AuthUser
          ? onSetAuthUser(_AuthUser)
          : onSetAuthUser(null);
      });
    }

    render() {
      return (
        <Component />
      );
    }
  }

  const mapDispatchToProps = (_Dispatch) => ({
    onSetAuthUser: (_AuthUser) => _Dispatch({ type: Actions.AUTH_USER_SET, authUser: _AuthUser }),
  });

  return connect(null, mapDispatchToProps)(WithAuthentication);
}

export default withAuthentication;