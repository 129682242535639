// ------------- DEPENDENCIES -------------- //

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import {
  withStyles,
  List,
  ListItem,
  ListItemText,
} from '@material-ui/core';

// ------------- LOCAL DEPENDENCIES -------------- //

// ------------- CONSTANTS & GLOBAL VARS -------------- //

const styles = ({
  root: {
    width : '100%',
    height: '100%',
  },
  center: {
    display       : 'flex',
    justifyContent: 'center',
    alignItems    : 'center',
    margin        : '0 auto',
  },
  hCenter: {
    textAlign: 'center',
  },
  contractorField: {
    color     : '#000000',
    fontSize  : '14px !important',
    fontWeight: 400,
  },
});

// ------------- MAIN -------------- //

class ContractorsList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      contractors: [],
    };
  }

  static parseJSON(_JSON) {
    const parsedData = [];
    
    for (const key in _JSON) {
      parsedData.push({
        id          : _JSON[key].id,
        contractorID: _JSON[key].contractorID,
        valueToDate : _JSON[key].valueToDate,
        name        : _JSON[key].contractorName,
        penalty     : _JSON[key].penalty,
      });
    }

    return parsedData;
  }

  static getDerivedStateFromProps(_Props, _CurrentState) {
    if (_CurrentState.contractors !== _Props.contractors) {
      return {
        contractors: ContractorsList.parseJSON(_Props.contractors),
      };
    }

    return null;
  }

  onItemClick(_Item) {
    const { onItemClick, } = this.props;

    if (onItemClick !== null && onItemClick !== undefined) {
      onItemClick(_Item);
    }
  }
  
  render() {
    const { classes, }     = this.props;
    const { contractors, } = this.state;

    return (
      <div
        className={classes.root}>
        <List
          className={classes.list}>
          {
            contractors.map((contractor, index) => {
              return (
                <ListItem button
                  key       = {index}
                  className = { classes.contractorItem }
                  onClick   = {() => this.onItemClick(contractor)}>
                  <ListItemText
                    classes   = {{ primary: classes.contractorField }}
                    primary   = {`${contractor.name}`}
                    secondary = {`${contractor.contractorID}`}/>
                </ListItem>
              );
            })
          }
        </List>
      </div>
    );
  }
}

const mapStateToProps = (_State) => ({
  authUser: _State.sessionState.authUser,
});

export default compose(
  connect(mapStateToProps),
  withStyles(styles),
)(ContractorsList);
