// ------------- LOCAL DEPENDENCIES -------------- //

import * as Actions from './action_types';

// ------------- CONSTANTS & GLOBAL VARS -------------- //

const INITIAL_STATE = {
  activeClient: null,
};

// ------------- MAIN -------------- //

const applySetActiveClient = (_State, _Action) => ({
  ..._State,
  activeClient: _Action.activeClient,
});

function clientReducer(_State = INITIAL_STATE, _Action) {
  switch(_Action.type) {
    case Actions.ACTIVE_CLIENT_SET: {
      return applySetActiveClient(_State, _Action);
    }
    default: return _State;
  }
}

export default clientReducer;
