// ------------- DEPENDENCIES -------------- //

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import {
  withStyles,
} from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import ReactLoading from 'react-loading';
import BottomScrollListener from 'react-bottom-scroll-listener';

// ------------- LOCAL DEPENDENCIES -------------- //

import withAuthorization from './withAuthorization';
import { Db } from '../firebase';
import * as Actions from '../reducers/action_types';
import * as Routes from '../constants/routes';
import AssignmentsList from './AssignmentsList';
import homePageFilterCategories from '../constants/homePageFilterCategories';
import homePageSortCategories from '../constants/homePageSortCategories';
import HomePageHeader from './HomePageHeader';
import SnackbarAlert from './SnackbarAlert';

// ------------- CONSTANTS & GLOBAL VARS -------------- //

const styles = ({
  root: {
    width : '100%',
    height: '100%',
  },
  center: {
    display       : 'flex',
    justifyContent: 'center',
    alignItems    : 'center',
    margin        : '0 auto',
  },
  hCenter: {
    textAlign: 'center',
  },
  textField: {
    marginLeft : 20,
    marginRight: 20,
    fontSize   : 14,
  },
  filterWrapper: {
    padding: 10,
  },
  button: {
    fontSize  : 15,
    marginLeft: 20,
  },
  endReachedText: {
    width    : '100%',
    textAlign: 'center',
    padding  : 30,
    display  : 'block',
  },
});

// ------------- MAIN -------------- //

class HomePage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      assignments  : null,
      queryError   : null,
      isQuery      : false,
      pageNext     : null,
      isLoadingNext: false,
      endReached   : false,
    };

    this.handleAssignmentClick = this.handleAssignmentClick.bind(this);
  }

  componentDidMount() {
    this.loadMoreAssignments();
  }

  handleAssignmentClick(_Assignment) {
    const { onSetActiveAssignmentID, history, } = this.props;
    onSetActiveAssignmentID(_Assignment.id);
    history.push(Routes.ASSIGNMENT_DATA);
  }

  onQueryUpdate = (_Results, _IsQuery) => {
    this.setState({
      assignments: _Results,
      isQuery    : _IsQuery,
      endReached : _IsQuery,
    });
  }

  onQueryError = (err) => {
    this.setState({
      queryError: err,
    });
  }

  renderQueryErrorAlert() {
    const { queryError, } = this.state;

    return (
      <SnackbarAlert
        autoHideDuration = {6000}
        onClose          = {() => this.setState({ queryError: null })}
        open             = { queryError !== null }
        message          = { queryError} />
    );
  }

  loadMoreAssignments = () => {
    const {
      isQuery,
      pageNext,
      endReached,
    } = this.state;
    if (!isQuery && !endReached) {
      this.setState({
        isLoadingNext: true,
        endReached   : false,
      });

      Db.doDownloadPaginatedAssignments(pageNext, 13).then(({assignments, next}) => {
        console.log(assignments);
        if (assignments !== null) {
          this.setState(prevState => {
            return {
              assignments  : prevState.assignments !== null ? {...prevState.assignments, ...assignments}: assignments,
              pageNext     : next,
              isLoadingNext: false,
              endReached   : false,
            };
          });
        } else {
          this.setState({
            isLoadingNext: false,
            endReached   : true,
          });
        }
      });
    }
  }
  
  render() {
    const {
      assignments,
      isLoadingNext,
      endReached,
    } = this.state;
    const { classes, } = this.props;

    return (
      <div
        className={classes.root}>
        { this.renderQueryErrorAlert() }
        <HomePageHeader
          filterCategories = {homePageFilterCategories}
          sortCategories   = {homePageSortCategories}
          onQueryUpdate    = {this.onQueryUpdate}
          onError          = {this.onQueryError} />
        {assignments !== null && assignments.length === 0
          ? <div>No matching results</div>
          : <AssignmentsList
              onItemClick = {this.handleAssignmentClick}
              assignments = {assignments} />}
        {isLoadingNext && <ReactLoading
          type  = 'bars'
          style = {{
            margin   : 'auto',
            width    : '10%',
            color    : "#29aafc",
            marginTop: '5px',
          }}/>}
        {endReached && <span className={classes.endReachedText}>No more assignments to display</span>}
        <BottomScrollListener onBottom={this.loadMoreAssignments} />
      </div>
    );
  }
}

const mapStateToProps = (_State) => ({
  authUser: _State.sessionState.authUser,
});

const mapDispatchToProps = (_Dispatch) => ({
  onSetActiveAssignmentID: (activeAssignmentID) => _Dispatch({ type: Actions.ACTIVE_ASSIGNMENT_SET, activeAssignmentID }),
});

const authCondition = (authUser) => !!authUser;

export default compose(
  withAuthorization(authCondition),
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
  withRouter
)(HomePage);