// ------------- DEPENDENCIES -------------- //

import React, { Component, } from 'react';
import {
  Slide,
  Snackbar,
} from '@material-ui/core';

// ------------- MAIN -------------- //

function TransitionRight(props) {
  return <Slide direction='right' {...props} />;
}

class SnackbarAlert extends Component {
  state = {
    open: false,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.open !== nextProps.open) {
      return {
        open: nextProps.open,
      };
    }

    return null;
  }

  render() {
    const {
      message,
      onClose,
      autoHideDuration,
    } = this.props;

    return (
      <Snackbar
        autoHideDuration    = {autoHideDuration}
        anchorOrigin        = {{ vertical: 'bottom', horizontal: 'center' }}
        open                = {this.state.open}
        TransitionComponent = {TransitionRight}
        onClose             = {onClose}
        ContentProps        = {{
          'aria-describedby': "snackbar-message"
        }}
        message={<span id='snackbar-message'>{message}</span>} />
    );
  }
}

export default SnackbarAlert;