// ------------- DEPENDENCIES -------------- //

import React from 'react';
import { connect } from 'react-redux';
import { Navbar, Nav, NavItem, NavDropdown, } from 'react-bootstrap';
import { LinkContainer, IndexLinkContainer } from 'react-router-bootstrap';

// ------------- LOCAL DEPENDENCIES -------------- //

import SignOutButton from './SignOut';
import * as Routes from '../constants/routes';

import '../css/Navigation.css';

import logo from '../assets/logo.png';

// ------------- MAIN -------------- //

const Navigation = ({ authUser }) =>
  <Navbar collapseOnSelect>
    <Navbar.Header>
      <Navbar.Brand>
        <a className='navbar-logo-wrapper' href='/'><img src={logo} width={40} /></a>
      </Navbar.Brand>
      <Navbar.Toggle />
    </Navbar.Header>
    { authUser
        ? <NavigationAuth />
        :     <NavigationNonAuth />
    }
  </Navbar>

const NavigationAuth = () =>
  <Navbar.Collapse>
    <Nav>
      <IndexLinkContainer exact to={Routes.HOME}>
        <NavItem>Home</NavItem>
      </IndexLinkContainer>
      <LinkContainer exact to={Routes.ACCOUNT}>
        <NavItem>Account</NavItem>
      </LinkContainer>
      <LinkContainer exact to={Routes.ASSIGNMENT_UPLOAD}>
        <NavItem>Assignment Upload</NavItem>
      </LinkContainer>
      <NavDropdown title='Client'>
        <LinkContainer exact to={Routes.CLIENT_LIST}>
          <NavItem>Clients</NavItem>
        </LinkContainer>
        <LinkContainer exact to={Routes.CLIENT_CREATE}>
          <NavItem>Create Client</NavItem>
        </LinkContainer>
      </NavDropdown>
      <NavDropdown title='Contractor'>
        <LinkContainer exact to={Routes.CONTRACTOR_LIST}>
          <NavItem>Contractors</NavItem>
        </LinkContainer>
        <LinkContainer exact to={Routes.CONTRACTOR_CREATE}>
          <NavItem>Create Contractor</NavItem>
        </LinkContainer>
      </NavDropdown>
      <NavDropdown title='Supervisor'>
        <LinkContainer exact to={Routes.SUPERVISOR_LIST}>
          <NavItem>Supervisors</NavItem>
        </LinkContainer>
        <LinkContainer exact to={Routes.SUPERVISOR_CREATE}>
          <NavItem>Create Supervisors</NavItem>
        </LinkContainer>
      </NavDropdown>
    </Nav>
    
    <Nav pullRight>
      <SignOutButton />
    </Nav>
  </Navbar.Collapse>

const NavigationNonAuth = () =>
  <Navbar.Collapse>
    <Nav>
      <LinkContainer exact to={Routes.SIGN_IN}>
        <NavItem>Sign In</NavItem>
      </LinkContainer>
    </Nav>
  </Navbar.Collapse>

const mapStateToProps = (state) => ({
  authUser: state.sessionState.authUser,
});

export default connect(mapStateToProps, null, null, { pure: false, })(Navigation);