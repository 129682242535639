// ------------- DEPENDENCIES -------------- //

import { combineReducers } from 'redux';

// ------------- LOCAL DEPENDENCIES -------------- //

import sessionReducer from './session';
import assignmentReducer from './assignment';
import clientReducer from './client';
import contractorReducer from './contractor';
import supervisorReducer from './supervisor';

// ------------- MAIN -------------- //

const rootReducer = combineReducers({
  sessionState   : sessionReducer,
  assignmentState: assignmentReducer,
  clientState    : clientReducer,
  contractorState: contractorReducer,
  supervisorState: supervisorReducer,
});

export default rootReducer;