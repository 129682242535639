// ------------- DEPENDENCIES -------------- //

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import {
  withStyles,
  List,
  ListItem,
  ListItemText,
} from '@material-ui/core';

// ------------- LOCAL DEPENDENCIES -------------- //

// ------------- CONSTANTS & GLOBAL VARS -------------- //

const styles = ({
  root: {
    width : '100%',
    height: '100%',
  },
  center: {
    display       : 'flex',
    justifyContent: 'center',
    alignItems    : 'center',
    margin        : '0 auto',
  },
  hCenter: {
    textAlign: 'center',
  },
  clientField: {
    color     : '#000000',
    fontSize  : '14px !important',
    fontWeight: 400,
  },
});

// ------------- MAIN -------------- //

class ClientsList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      clients: [],
    };
  }

  static parseJSON(_JSON) {
    const parsedData = [];
    
    for (const key in _JSON) {
      parsedData.push({
        id         : _JSON[key].id,
        clientID   : _JSON[key].clientID,
        valueToDate: _JSON[key].valueToDate,
        name       : _JSON[key].clientName,
        weChatID   : _JSON[key].clientWeChatID,
      });
    }

    return parsedData;
  }

  static getDerivedStateFromProps(_Props, _CurrentState) {
    if (_CurrentState.clients !== _Props.clients) {
      return {
        clients: ClientsList.parseJSON(_Props.clients),
      };
    }

    return null;
  }

  onItemClick(_Item) {
    const { onItemClick, } = this.props;

    if (onItemClick !== null && onItemClick !== undefined) {
      onItemClick(_Item);
    }
  }
  
  render() {
    const { classes, } = this.props;
    const { clients, } = this.state;

    return (
      <div
        className={classes.root}>
        <List
          className={classes.list}>
          {
            clients.map((client, index) => {
              return (
                <ListItem button
                  key       = {index}
                  className = { classes.clientItem }
                  onClick   = {() => this.onItemClick(client)}>
                  <ListItemText
                    classes   = {{ primary: classes.clientField }}
                    primary   = {`${client.name}`}
                    secondary = {`${client.clientID}`}/>
                </ListItem>
              );
            })
          }
        </List>
      </div>
    );
  }
}

const mapStateToProps = (_State) => ({
  authUser: _State.sessionState.authUser,
});

export default compose(
  connect(mapStateToProps),
  withStyles(styles),
)(ClientsList);