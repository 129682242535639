// ------------- DEPENDENCIES -------------- //

import React, { Component } from 'react';
import { compose } from 'recompose';
import {
  withStyles,
  Paper,
  Button,
  TextField,
} from '@material-ui/core';
import Select from 'react-select';

// ------------- LOCAL DEPENDENCIES -------------- //

import Dropdown from './Dropdown';
import { Db } from '../firebase';
import * as Utils from '../Utils';

// ------------- CONSTANTS & GLOBAL VARS -------------- //

const homePageHeaderStyles = {
  homePageHeaderRoot: {
    display       : 'flex',
    flexFlow      : 'row wrap',
    justifyContent: 'space-between',
    alignItems    : 'center',
    padding       : '10px 25px 10px 25px',
  },
  filterWrapper: {
    display       : 'flex',
    flexFlow      : 'row nowrap',
    justifyContent: 'center',
    alignItems    : 'center',
    border        : 'solid 1px #303f9f',
    borderRadius  : 4,
    padding       : '5px 15px 5px 15px',
  },
  sortWrapper: {
    display       : 'flex',
    flexFlow      : 'row nowrap',
    justifyContent: 'center',
    alignItems    : 'center',
    border        : 'solid 1px #303f9f',
    borderRadius  : 4,
    padding       : '5px 20px 5px 5px',
  },
  rangeWrapper: {
    display       : 'flex',
    flexFlow      : 'row nowrap',
    justifyContent: 'center',
    alignItems    : 'center',
    border        : 'solid 1px #303f9f',
    borderRadius  : 4,
    padding       : 5,
    height        : '79px',
  },
  textField: {
    marginLeft : 20,
    marginRight: 20,
    fontSize   : 14,
  },
  applyButton: {
    fontSize: 15,
  },
  select: {
    width: 150,
  }
};

const INIT_STATE = {
  filterValue    : '',
  filterSelect   : { label: '', value: '', },
  filterBy       : '',
  sortBy         : '',
  filterStartDate: '',
  filterEndDate  : '',
  clientNames    : [],
  contractorNames: [],
  assignmentIDs  : [],
  sortDirection  : 'asc',
};

// ------------- MAIN -------------- //

const parseFilterBy = (_FilterBy) => {
  if (_FilterBy === 'Client Name') {
    return 'clientName';
  } else if (_FilterBy === 'Contractor Name') {
    return 'contractorName';
  } else if (_FilterBy === 'Assignment ID') {
    return 'assignmentID';
  } else {
    return _FilterBy;
  }
}

const parseSortBy = (_SortBy) => {
  if (_SortBy === 'Assignment ID') {
    return 'assignmentID';
  } else if (_SortBy === 'Start Date') {
    return 'startDate';
  } else if (_SortBy === 'Client Name') {
    return 'clientName';
  } else if (_SortBy === 'Contractor Name') {
    return 'contractorName';
  } else if (_SortBy === 'Client WeChat ID') {
    return 'clientWeChatID';
  } else {
    return _SortBy;
  }
}

class HomePageHeader extends Component {
  constructor(props) {
    super(props);

    this.state = INIT_STATE;
  }

  componentDidMount = () => {
    Db.doDownloadClientNames().then((clientNames) => {
      clientNames = clientNames.map(c => {
        return {
          label: c,
          value: c.split(' - ')[0],
        };
      });

      this.setState({
        clientNames: clientNames,
      });
    });
    Db.doDownloadContractorNames().then((contractorNames) => {
      contractorNames = contractorNames.map(c => {
        return {
          label: c,
          value: c.split(' - ')[0],
        };
      });

      this.setState({
        contractorNames,
      });
    });
    Db.doDownloadAssignmentIDs().then((assignmentIDs) => {
      if (assignmentIDs === undefined || assignmentIDs === null) return;
      assignmentIDs = assignmentIDs.map(c => {
        return {
          label: c,
          value: c,
        };
      });

      this.setState({
        assignmentIDs,
      });
    });
  }
  

  handleFilterByChange = (_FilterBy) => {
    this.setState({
      filterBy: parseFilterBy(_FilterBy),
    });
  }

  handleSortByChange = (_SortBy) => {
    this.setState({
      sortBy: parseSortBy(_SortBy),
    });
  }

  handleSortByDirectionChange = (_Dir) => {
    this.setState({
      sortDirection: _Dir.toLowerCase(),
    });
  }

  applyQuery = () => {
    const {
      filterValue,
      filterBy,
      sortBy,
      sortDirection,
      filterStartDate,
      filterEndDate,
    } = this.state;
    const {
      onQueryUpdate,
      onError,
      defaultFilters = [],
    } = this.props;
    console.table({
      filterValue,
      filterBy,
      sortBy,
      sortDirection,
      filterStartDate,
      filterEndDate,
    });

    onQueryUpdate(null);

    if (filterBy === '' && sortBy === '' && filterStartDate === '' && filterBy === '') {
      Db.doHomePageFilterAndSortAssignments(defaultFilters).then((_Assignments) => {
        onQueryUpdate(_Assignments === null ? [] : _Assignments, false);
      });
    } else if (/*filterBy !== '' && sortBy !== ''*/false) {
      onError("Due to Firebase infrastructure limitations, sort value and filter values must be the same if used at the same time.");
      onQueryUpdate([]);
    } else {
      Db.doHomePageFilterAndSortAssignments([
      ...defaultFilters, {
        filter: filterBy,
        value : filterValue.toLowerCase(),
      },{
        filter: 'startDate',
        value : filterStartDate !== '' ?  Utils.readableToTimestamp(filterStartDate): null,
      },{
        filter: 'endDate',
        value : filterEndDate !== '' ? Utils.readableToTimestamp(filterEndDate): null,
      }], sortBy, sortDirection).then((results) => {
        onQueryUpdate(results === null ? [] : results, true);
      });
    }
  }

  onFilterSelect = (val) => {
    if (val === undefined || val === null) { return; }

    this.setState({
      filterValue : val.value,
      filterSelect: val,
    });
  }

  render() {
    const {
      classes,
      filterCategories,
      sortCategories,
    } = this.props;
    const {
      filterSelect,
      filterBy,
      sortBy,
      filterStartDate,
      filterEndDate,
      clientNames,
      contractorNames,
      assignmentIDs,
    } = this.state;

    const isFilterAndSortDisabled = filterStartDate !== ''
                                    || filterEndDate !== '';

    const isDateRangeDisabled = filterBy !== ''
                                || sortBy !== '';

    let filterOptions = [];

    if (filterBy === 'clientName') {
      filterOptions = clientNames;
    } else if (filterBy === 'contractorName') {
      filterOptions = contractorNames;
    } else if (filterBy === 'assignmentID') {
      filterOptions = assignmentIDs;
    }

    return (
      <Paper
        className={classes.homePageHeaderRoot}>
        <div
          className={classes.filterWrapper}>
          <Select
            placeholder = 'Search'
            className   = {[classes.textField, classes.padding, classes.select].join(' ')}
            value       = {filterSelect}
            options     = {filterOptions}
            onChange    = {this.onFilterSelect} />
          <Dropdown
            defaultText = 'Filter By'
            categories  = {filterCategories}
            onChange    = {this.handleFilterByChange} />
        </div>
        <div
          className={classes.sortWrapper}>
          <Dropdown
            disabled    = {isFilterAndSortDisabled}
            defaultText = 'Sort By'
            categories  = {sortCategories}
            onChange    = {this.handleSortByChange} />
          <Dropdown
            defaultText  = 'ASC'
            defaultValue = 'ASC'
            categories   = {['ASC', 'DESC']}
            onChange     = {this.handleSortByDirectionChange} />
        </div>
        <div
          className={classes.rangeWrapper}>
          <TextField
            disabled        = {isDateRangeDisabled}
            id              = "dateStart"
            label           = "Start Date"
            type            = "date"
            className       = {[classes.textField, classes.dateInput].join(' ')}
            InputLabelProps = {{
              shrink: true,
            }}
            value    = {filterStartDate}
            onChange = {evt => this.setState({ filterStartDate: evt.target.value, })}/>
          <TextField
            disabled        = {isDateRangeDisabled}
            id              = "dateEnd"
            label           = "End Date"
            type            = "date"
            className       = {[classes.textField, classes.dateInput].join(' ')}
            InputLabelProps = {{
              shrink: true,
            }}
            value    = {filterEndDate}
            onChange = {evt => this.setState({ filterEndDate: evt.target.value, })}/>
        </div>
        <Button
          className = {classes.applyButton}
          onClick   = {this.applyQuery}>
          APPLY
        </Button>
      </Paper>
    );
  }
}

export default compose(
  withStyles(homePageHeaderStyles),
)(HomePageHeader);
