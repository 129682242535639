// ------------- DEPENDENCIES ------------- //

import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { compose, } from 'recompose';
import {
  Card,
  CardHeader,
  withStyles,
  FormControl,
  Input,
  InputLabel,
  Button,
} from '@material-ui/core';

// ------------- LOCAL DEPENDENCIES ------------- //

import { SignUpLink } from './SignUpPage';
import { PasswordForgetLink } from './PasswordForgetPage';
import { Auth } from '../firebase';

import * as Routes from '../constants/routes';

// ------------- CONSTANTS & GLOBAL VARS ------------- //

const INITIAL_STATE = {
  email: '',
  password: '',
  error: null,
};

const styles = {
  title: {
    marginBottom: 20,
    fontSize: 30,
  },
  card: {
    marginTop: '3%',
    width: '100%',
    textAlign: 'center',
    padding: '50px 10% 30px 10%',
  },
  margin: {
    margin: 10,
  }
}

const formStyles = {
  textField: {
    marginLeft: 20,
    marginRight: 20,
    fontSize: 14,
  },
  margin: {
    margin: 10,
  },
  button: {
    fontSize: 15,
  }
}

// ------------- MAIN ------------- //

class SignInPage extends Component {
  render() {
    const { classes, history, } = this.props;

    return (
      <div className={classes.card}>
        <Card
          elevation={8}
          className={classes.card}>
          <CardHeader
            classes={{ title: classes.title, }}
            title='Welcome Back!' />
          <SignInForm history={history} />
          <PasswordForgetLink />
          <SignUpLink />
        </Card>
      </div>
    );
  }
}

const byPropKey = (_PropertyName, _Value) => () => ({
  [_PropertyName]: _Value,
});

class SignInFormComponent extends Component {
  constructor(_Props) {
    super(_Props);

    this.state = { ...INITIAL_STATE };
  }

  onSubmit = (_Event) => {
    const {
      email,
      password,
    } = this.state;

    const {
      history,
    } = this.props;

    Auth.doSignInWithEmailAndPassword(email, password)
      .then(() => {
        this.setState({ ...INITIAL_STATE });
        history.push(Routes.HOME);
      })
      .catch(error => {
        this.setState(byPropKey('error', error));
      });
    
    _Event.preventDefault();
  }

  render() {
    const {
      email,
      password,
      error,
    } = this.state;
    const { classes } = this.props;

    const isInvalid =
      password === ''
      || email === '';
    
    return (
      <div className={[classes.container, classes.margin].join(' ')} onSubmit={this.onSubmit}>
        <FormControl fullWidth>
          <InputLabel htmlFor='email-input'>Email</InputLabel>
          <Input
            autoComplete='email'
            className={[classes.textField, classes.margin].join(' ')}
            id='email-input'
            value={email}
            onChange={evt => this.setState(byPropKey('email', evt.target.value))}>
          </Input>
        </FormControl>
        <FormControl fullWidth>
          <InputLabel htmlFor='password-input'>Password</InputLabel>
          <Input
            autoComplete='password'
            className={[classes.textField, classes.margin].join(' ')}
            type='password'
            id='password-input'
            value={password}
            onChange={evt => this.setState(byPropKey('password', evt.target.value))}>
          </Input>
        </FormControl>
        <Button
          className={[classes.margin, classes.button].join(' ')}
          size='large'
          onClick={this.onSubmit}
          color='primary'
          disabled={isInvalid}>
          Sign In
        </Button>
        { error && <p>{error.message}</p> }
      </div>
    );
  }
}

export default compose(
  withStyles(styles),
  withRouter,
)(SignInPage);

const SignInForm = withStyles(formStyles)(SignInFormComponent);

export {
  SignInForm
};