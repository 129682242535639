// ------------- DEPENDENCIES -------------- //

import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import {
  Card,
  CardHeader,
  CardContent,
  withStyles,
  Typography,
  Paper,
  TextField,
  Button,
} from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import ReactLoading from 'react-loading';

// ------------- LOCAL DEPENDENCIES -------------- //

import withAuthorization from './withAuthorization';
import { Db } from '../firebase';
import * as Actions from '../reducers/action_types';
import * as Routes from '../constants/routes';
import AssignmentsList from './AssignmentsList';
import * as Utils from '../Utils';

// ------------- LOCAL DEPENDENCIES -------------- //

const styles = ({
  assignmentsListItemDelete: {
    marginRight: '18px',
  },
  assignmentsListItem: {
    padding: '0px 5px 0px 5px !important',
  },
  assignmentsListItemTitle: {
    fontSize    : '17px',
    textOverflow: 'ellipsis',
  },
  assignmentsListItemSubtitle: {
    fontSize    : '10px',
    textOverflow: 'ellipsis',
  },
  dataContainer: {
    width    : '100%',
    padding  : '70px 5% 0px 5%',
    textAlign: 'center',
  },
  cardContainer: {
    margin: '20px',
  },
  title: {
    fontSize: 16,
    color   : '#707070'
  },
  leftAlign: {
    textAlign: 'left !important',
  },
  clientName: {
    fontSize: 25,
  },
  clientField: {
    margin: 10,
  },
  dateInput: {
    marginLeft  : '0px !important',
    paddingRight: '20px',
  },
  textField: {
    marginLeft : 20,
    marginRight: 20,
    fontSize   : 14,
  },
  filterWrapper: {
    padding: 10,
  },
});

// ------------- MAIN -------------- //

class ClientData extends React.Component {
  constructor(props) {
    super(props);
    
    this.state = {
      name           : null,
      id             : null,
      value          : null,
      assignments    : null,
      filterStartDate: null,
      filterEndDate  : null,
    }

    this.handleAssignmentClick  = this.handleAssignmentClick.bind(this);
    this.applyFilter            = this.applyFilter.bind(this);
    this.calculateFilteredValue = this.calculateFilteredValue.bind(this);
    this.parseJSON              = this.parseJSON.bind(this);
  }

  /**
   * Downloads client data and relevant assignments from database.
   */
  componentDidMount() {
    const {
      activeClient,
    } = this.props;

    if (activeClient !== null && activeClient !== undefined) {
      this.setState({
        name    : activeClient.name,
        id      : activeClient.clientID,
        weChatID: activeClient.weChatID,
      });

      Db.doDownloadClientValue(activeClient.clientID).then((value) => {
        this.setState({
          value: value,
        });
      });

      Db.doDownloadFilteredAssignments([{
        filter: 'clientID',
        value : activeClient.clientID,
      }]).then((results) => {
        if (results !== null) {
          this.setState({
            assignments: results,
          });
        }
      });
    }
  }

  /**
   * Handles assignment click event. Sets 'assignmentID' field in
   * global redux state, and switches to AssignmentData page
   * with relevant assignment data.
   *
   * @param {JSON} _Assignment -> Assignment data.
   */
  handleAssignmentClick(_Assignment) {
    const { onSetActiveAssignmentID, history, } = this.props;
    onSetActiveAssignmentID(_Assignment.id);
    history.push(Routes.ASSIGNMENT_DATA);
  }

  /**
   * Calculates total value of assignments stored in local
   * component state.
   *
   * @return {int} -> Total calculated client value.
   */
  calculateFilteredValue() {
    const {
      assignments,
    } = this.state;
    const parsedAssignments = this.parseJSON(assignments);

    let sum = 0;
    for (let i = 0; i < parsedAssignments.length; i++) {
      if (parsedAssignments[i].value && !parsedAssignments[i].clientPaid) {
        sum += parseInt(parsedAssignments[i].value);
      }
    }

    return sum;
  }

  /**
   * Parses assignments collection data and presents them in an array.
   * Assignment document IDs are added to items with key "id".
   *
   * @param {JSON} _JSON -> Downloaded assignments data.
   *
   * @return {Array<JSON>} -> Parsed assignments data.
   */
  parseJSON(_JSON) {
    const parsedData = [];
    
    for (const key in _JSON) {
      parsedData.push({
        id            : _JSON[key].id,
        startDate     : _JSON[key].startDate,
        dueDate       : _JSON[key].dueDate,
        clientPaid    : _JSON[key].clientPaid,
        contractorPaid: _JSON[key].contractorPaid,
        clientName    : _JSON[key].clientName,
        contractorName: _JSON[key].contractorName,
        notes         : _JSON[key].notes,
        value         : _JSON[key].value,
        contractorFee : _JSON[key].contractorFee,
      });
    }

    return parsedData;
  }

  /**
   * Applies date range filter and downloads valid
   * assignments associated with client from database.
   */
  applyFilter() {
    const {
      filterStartDate,
      filterEndDate,
    } = this.state;
    const {
      activeClient,
    } = this.props;

    Db.doDownloadRangedAssignments(
      Utils.readableToTimestamp(filterStartDate),
      Utils.readableToTimestamp(filterEndDate),{
        field: 'clientID',
        value: activeClient.clientID,
      }).then((results) => {
      if (results !== null) {
        this.setState({
          assignments: results,
        });
      }
    });
  }

  render() {
    const {
      name,
      value,
      id,
      assignments,
      filterStartDate,
      filterEndDate,
      weChatID,
    } = this.state;
    const { classes, } = this.props;

    return (
      <div className={classes.dataContainer}>
        <div>
          <Card
            className = {classes.cardContainer}
            elevation = {8}>
            <CardHeader
              classes = {{ title: [classes.leftAlign, classes.title].join(' ') }}
              title   = 'Client Data'/>
            <CardContent>
              <Typography
                className = { classes.clientName }
                variant   = 'headline'
                component = 'p'>
                {name}
              </Typography>
              <Typography
                className = { classes.clientField }
                variant   = 'headline'
                component = 'p'>
                ID: {id}
              </Typography>
              <Typography
                className = { classes.clientField }
                variant   = 'headline'
                component = 'p'>
                WeChat ID: {weChatID}
              </Typography>
              <Typography
                className = { classes.clientField }
                variant   = 'headline'
                component = 'p'>
                {name}
              </Typography>
              <Typography
                className = { classes.clientField }
                variant   = 'headline'
                component = 'p'>
                Total Value: ${value}
              </Typography>
              <Typography
                className = { classes.contractorField }
                variant   = 'headline'
                component = 'p'>
                Filtered Unpaid Value: ${this.calculateFilteredValue()}
              </Typography>
            </CardContent>
          </Card>
          <Paper
          className={ [classes.filterWrapper, classes.center].join(' ') }>
            <TextField
              id              = "dateStart"
              label           = "Start Date"
              type            = "date"
              className       = {[classes.textField, classes.dateInput].join(' ')}
              InputLabelProps = {{
                shrink: true,
              }}
              value    = {filterStartDate}
              onChange = {evt => this.setState({ filterStartDate: evt.target.value, })}/>
            <TextField
              id              = "dateEnd"
              label           = "End Date"
              type            = "date"
              className       = {[classes.textField, classes.dateInput].join(' ')}
              InputLabelProps = {{
                shrink: true,
              }}
              value    = {filterEndDate}
              onChange = {evt => this.setState({ filterEndDate: evt.target.value, })}/>
            <Button
              className = {[classes.margin, classes.button].join(' ')}
              size      = 'large'
              onClick   = {() => this.applyFilter()}
              color     = 'primary'
              disabled  = {false}>
              Apply
            </Button>
          </Paper>
          <Paper>
            {
              assignments === null
                ? <ReactLoading
                    type  = 'bars'
                    style = {{
                      margin   : 'auto',
                      width    : '20%',
                      color    : "#29aafc",
                      marginTop: '200px',
                    }}/>
                :                                              assignments !== null && assignments.length === 0
                    ? <div>No matching results</div>
                    : <AssignmentsList
                        onItemClick = {this.handleAssignmentClick}
                        assignments = {assignments} />
            }
          </Paper>
        </div>
      </div>
    );
  }
}

const authCondition = (authUser) => !!authUser;

const mapStateToProps = (_State) => ({
  authUser    : _State.sessionState.authUser,
  activeClient: _State.clientState.activeClient,
});

const mapDispatchToProps = (_Dispatch) => ({
  onSetActiveAssignmentID: (activeAssignmentID) => _Dispatch({ type: Actions.ACTIVE_ASSIGNMENT_SET, activeAssignmentID }),
});

export default compose(
  withAuthorization(authCondition),
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
  withStyles(styles),
)(ClientData);