// ------------- DEPENDENCIES ------------- //

import { Auth } from './firebase';

// ------------- MAIN ------------- //

/**
 * Creates a Firebase user.
 *
 * @type {function(!string, !string)}
 *
 * @public
 *
 * @param {string} _Email -> User email string.
 * @param {string} _Password -> User password string.
 */
export const doCreateUserWithEmailAndPassword = (_Email, _Password) =>
  Auth.createUserWithEmailAndPassword(_Email, _Password);

/**
 * Signs in a Firebase user.
 *
 * @type {function(!string, !string)}
 *
 * @public
 *
 * @param {string} _Email -> User email string.
 * @param {string} _Password -> User password string.
 */
export const doSignInWithEmailAndPassword = (_Email, _Password) =>
  Auth.signInWithEmailAndPassword(_Email, _Password);

/**
 * Signs out a Firebase user.
 *
 * @type {function()}
 *
 * @public
 */
export const doSignOut = () =>
  Auth.signOut();

/**
 * Sends user a password reset email.
 *
 * @type {function(!string)}
 *
 * @public
 *
 * @param {string} _Email -> User email string.
 */
export const doPasswordReset = (_Email) =>
  Auth.sendPasswordResetEmail(_Email);

/**
 * Changes Firebase user's password.
 *
 * @type {function(!string)}
 *
 * @public
 *
 * @param {string} _Password -> User new password string.
 */
export const doPasswordUpdate = (_Password) =>
  Auth.currentUser.updatePassword(_Password);