// ------------- DEPENDENCIES -------------- //

import { createStore } from 'redux';

// ------------- LOCAL DEPENDENCIES -------------- //

import rootReducer from '../reducers';

// ------------- MAIN -------------- //

const store = createStore(
  rootReducer,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
);

export default store;
