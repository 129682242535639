export const SIGN_UP = '/signup';
export const SIGN_IN           = '/signin';
export const ACCOUNT           = '/account';
export const PASSWORD_FORGET   = '/pw-forget';
export const HOME              = '/';
export const ASSIGNMENT_UPLOAD = '/assignment-upload';
export const ASSIGNMENT_DATA   = '/assignment-data';
export const CLIENT_LIST       = '/client-list';
export const CLIENT_DATA       = '/client-data';
export const CLIENT_CREATE     = '/client-create';
export const CONTRACTOR_LIST   = '/contractor-list';
export const CONTRACTOR_DATA   = '/contractor-data';
export const CONTRACTOR_CREATE = '/contractor-create';
export const SUPERVISOR_LIST   = '/supervisor-list';
export const SUPERVISOR_DATA   = '/supervisor-data';
export const SUPERVISOR_CREATE = '/supervisor-create';