// ------------- DEPENDENCIES -------------- //

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import {
  withStyles,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
} from '@material-ui/core';
import {
  Clear,
} from '@material-ui/icons';

// ------------- LOCAL DEPENDENCIES -------------- //

// ------------- CONSTANTS & GLOBAL VARS -------------- //

const styles = ({
  root: {
    width : '100%',
    height: '100%',
  },
  center: {
    display       : 'flex',
    justifyContent: 'center',
    alignItems    : 'center',
    margin        : '0 auto',
  },
  hCenter: {
    textAlign: 'center',
  },
  fileField: {
    color     : '#000000',
    fontSize  : '14px !important',
    fontWeight: 400,
  },
});

// ------------- MAIN -------------- //

class FileList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      files: [],
    };
  }

  static getDerivedStateFromProps(_Props, _CurrentState) {
    if (_CurrentState.files !== _Props.files) {
      return {
        files: _Props.files,
      };
    }

    return null;
  }

  onItemClick(_Item) {
    const { onItemClick, } = this.props;

    if (onItemClick !== null && onItemClick !== undefined) {
      onItemClick(_Item);
    }
  }

  onItemSecondaryClick(_Item) {
    const { onItemSecondaryClick, } = this.props;

    if (onItemSecondaryClick !== null && onItemSecondaryClick !== undefined) {
      onItemSecondaryClick(_Item);
    }
  }
  
  render() {
    const { classes, } = this.props;
    const { files, }   = this.state;

    return (
      <div
        className={classes.root}>
        <List
          className={classes.list}>
          {
            files.map((file, index) => {
              return (
                <ListItem button
                  key       = {index}
                  className = { classes.fileItem }
                  onClick   = {() => this.onItemClick(file)}>
                  <ListItemText
                    classes = {{ primary: classes.fileField }}
                    primary = {`${file.fileName}`}/>
                  <ListItemSecondaryAction>
                    <IconButton
                      aria-label = "Clear"
                      onClick    = {() => this.onItemSecondaryClick(file) }>
                      <Clear />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              );
            })
          }
        </List>
      </div>
    );
  }
}

const mapStateToProps = (_State) => ({
  authUser: _State.sessionState.authUser,
});

export default compose(
  connect(mapStateToProps),
  withStyles(styles),
)(FileList);