import DF from 'dateformat';

export const timestampToReadable = (_Timestamp) => {
  const date  = new Date(_Timestamp);
  const month = date.getMonth() + 1;
  const day   = date.getDate();
  return `${date.getFullYear()}-${month < 10 ? `0${month}` : month}-${day < 10 ? `0${day}` : day}`;
};

export const timestampToReadableWithHM = (_Timestamp) => {
  const date    = new Date(_Timestamp);
  const hours   = date.getUTCHours();
  const minutes = date.getUTCMinutes();
  return `${DF(date, 'yyyy-mm-dd')}T${hours < 10 ? '0' : ''}${hours}:${minutes < 10 ? '0' : ''}${minutes}`;
};

export const readableToTimestamp = (_Readable) => {
  if (_Readable === '' || _Readable === null || _Readable === undefined) return null;
  const parts = _Readable.match(/(\d+)/g);
  console.log(parts, _Readable);
  if (parts.length === 5) {
    return (new Date(`${_Readable}:00Z`)).getTime();
  } else {
    return (new Date(parseInt(parts[0]), parseInt(parts[1]) - 1, parseInt(parts[2]))).getTime();
  }
}