// ------------- DEPENDENCIES -------------- //

import React from 'react';
import {
  BrowserRouter as Router,
  Route
} from 'react-router-dom';

// ------------- LOCAL DEPENDENCIES -------------- //

import '../css/App.css';

import Navigation from './Navigation';
//import SignUpPage from './SignUpPage';
import SignInPage from './SignInPage';
import PasswordForgetPage from './PasswordForgetPage';
import AccountPage from './AccountPage';
import HomePage from './HomePage';
import AssignmentUploadPage from './AssignmentUploadPage';
import AssignmentDataPage from './AssignmentDataPage';
import ClientsPage from './ClientsPage';
import ClientData from './ClientData';
import CreateClientPage from './CreateClientPage.js';
import ContractorsPage from './ContractorsPage';
import ContractorData from './ContractorData';
import CreateContractorPage from './CreateContractorPage.js';
import SupervisorsPage from './SupervisorsPage';
import SupervisorData from './SupervisorData';
import CreateSupervisorPage from './CreateSupervisorPage.js';

import * as Routes from '../constants/routes';
import withAuthentication from './withAuthentication';

// ------------- MAIN -------------- //

/*
<Route
  exact path = {Routes.SIGN_UP}

  component = {SignUpPage}
/>
*/

const App = () =>
  <Router>
    <div>
      <Navigation />
      
      <Route
        exact path      = {Routes.HOME}
              component = {HomePage}
      />
      <Route
        exact path      = {Routes.SIGN_IN}
              component = {SignInPage}
      />
      <Route
        exact path      = {Routes.PASSWORD_FORGET}
              component = {PasswordForgetPage}
      />
      <Route
        exact path      = {Routes.ACCOUNT}
              component = {AccountPage}
      />
      <Route
        exact path      = {Routes.ASSIGNMENT_UPLOAD}
              component = {AssignmentUploadPage}
      />
      <Route
        exact path      = {Routes.ASSIGNMENT_DATA}
              component = {AssignmentDataPage}
      />
      <Route
        exact path      = {Routes.CLIENT_LIST}
              component = {ClientsPage}
      />
      <Route
        exact path      = {Routes.CLIENT_DATA}
              component = {ClientData}
      />
      <Route
        exact path      = {Routes.CLIENT_CREATE}
              component = {CreateClientPage}
      />
      <Route
        exact path      = {Routes.CONTRACTOR_LIST}
              component = {ContractorsPage}
      />
      <Route
        exact path      = {Routes.CONTRACTOR_DATA}
              component = {ContractorData}
      />
      <Route
        exact path      = {Routes.CONTRACTOR_CREATE}
              component = {CreateContractorPage}
      />
      <Route
        exact path      = {Routes.SUPERVISOR_LIST}
              component = {SupervisorsPage}
      />
      <Route
        exact path      = {Routes.SUPERVISOR_DATA}
              component = {SupervisorData}
      />
      <Route
        exact path      = {Routes.SUPERVISOR_CREATE}
              component = {CreateSupervisorPage}
      />
    </div>
  </Router>

export default withAuthentication(App);