// ------------- DEPENDENCIES -------------- //

import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';

// ------------- MAIN -------------- //

/**
 * Function to display component with slide-up transition.
 *
 * @param {JSON} props -> Parent props.
 */
function Transition(props) {
  return <Slide direction="up" {...props} />;
}

class AlertDialogSlide extends React.Component {
  state = {
    open: false,
  };

  /**
   * Monitors "open" prop, and displays component if true.
   *
   * @param {JSON} nextProps -> Updated props.
   * @param {JSON} prevState -> Previous component state.
   */
  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.open !== nextProps.open) {
      return {
        open: nextProps.open,
      };
    }

    return null;
  }

  render() {
    const {
      title,
      message,
      onCancelHandler,
      onOKHandler,
    } = this.props;

    return (
      <div>
        <Dialog
          open                = {this.state.open}
          TransitionComponent = {Transition}
          keepMounted
          onClose          = {this.handleClose}
          aria-labelledby  = "alert-dialog-slide-title"
          aria-describedby = "alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">
            {title}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              {message}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            { onCancelHandler ? <Button onClick={onCancelHandler} color="primary">Cancel</Button> : null }
            { onOKHandler ? <Button onClick={onOKHandler} color="primary">OK</Button> : null }
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default AlertDialogSlide;