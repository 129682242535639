// ------------- DEPENDENCIES -------------- //

import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import {
  Card,
  CardHeader,
  CardContent,
  withStyles,
  Typography,
} from '@material-ui/core';
import { withRouter } from 'react-router-dom';

// ------------- LOCAL DEPENDENCIES -------------- //

import { PasswordForgetForm } from './PasswordForgetPage';
import PasswordChangeForm from './PasswordChangePage';
import withAuthorization from './withAuthorization';
import { Db } from '../firebase';

// ------------- LOCAL DEPENDENCIES -------------- //

const styles = ({
  userDecksListItemDelete: {
    marginRight: '18px',
  },
  userDecksListItem: {
    padding: '0px 5px 0px 5px !important',
  },
  userDecksListItemTitle: {
    fontSize    : '17px',
    textOverflow: 'ellipsis',
  },
  userDecksListItemSubtitle: {
    fontSize    : '10px',
    textOverflow: 'ellipsis',
  },
  dataContainer: {
    width    : '100%',
    padding  : '70px 5% 0px 5%',
    textAlign: 'center',
  },
  cardContainer: {
    margin: '20px',
  },
  title: {
    fontSize: 16,
    color   : '#707070'
  },
  leftAlign: {
    textAlign: 'left !important',
  },
  welcomeMessage: {
    fontSize: 20,
  }
});

// ------------- MAIN -------------- //

class AccountPage extends React.Component {
  constructor(props) {
    super(props);
    
    this.state = {
      username : null,
      email    : null,
      userDecks: null,
    }
  }

  componentDidMount() {
    const { authUser } = this.props;

    Db.doDownloadUserData(authUser.uid).then((userData) => {
      if (!userData.exists) {
        console.log(`User does not exist.`);
      } else {
        userData = userData.data();
        this.setState({
          username: userData.username,
          email   : userData.email,
        });
      }
    });
  }

  render() {
    const {
      username,
      email,
    } = this.state;
    const { classes, } = this.props;

    return (
      <div className={classes.dataContainer}>
        <div>
          <Card
            className = {classes.cardContainer}
            elevation = {8}>
            <CardHeader
              classes = {{ title: [classes.leftAlign, classes.title].join(' ') }}
              title   = 'Account Information'/>
            <CardContent>
              <Typography
                className = { classes.welcomeMessage }
                variant   = 'headline'
                component = 'p'>
                Hello {username}!
              </Typography>
              <Typography
                variant   = 'headline'
                component = 'p'>
                Email: {email}
              </Typography>
            </CardContent>
          </Card>
          <Card
            className = {classes.cardContainer}
            elevation = {8}>
            <PasswordForgetForm />
          </Card>
          <Card
            className = {classes.cardContainer}
            elevation = {8}>
            <PasswordChangeForm />
          </Card>
        </div>
      </div>
    );
  }
}

const authCondition = (authUser) => !!authUser;

const mapStateToProps = (_State) => ({
  authUser: _State.sessionState.authUser,
});

export default compose(
  withAuthorization(authCondition),
  connect(mapStateToProps),
  withRouter,
  withStyles(styles),
)(AccountPage);