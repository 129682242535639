// ------------- DEPENDENCIES -------------- //

import React, { Component } from 'react';
import {
  withStyles,
  FormControl,
  Input,
  InputLabel,
  Button,
} from '@material-ui/core';

// ------------- LOCAL DEPENDENCIES -------------- //

import { Auth } from '../firebase';

// ------------- CONSTANTS & GLOBAL VARS -------------- //

const INITIAL_STATE = {
  passwordOne: '',
  passwordTwo: '',
  error      : null,
};

const styles = ({
  button: {
    fontSize: 13,
  },
  padding: {
    padding: 10,
  },
  margin: {
    margin: 10,
  },
  title: {
    textAlign: 'left',
    color    : '#707070',
  }
});

// ------------- MAIN -------------- //

const byPropKey = (_PropertyName, _Value) => () => ({
  [_PropertyName]: _Value,
});

class PasswordChangeForm extends Component {
  constructor(props) {
    super(props);
    
    this.state = { ...INITIAL_STATE };
  }
  
  onSubmit = (_Event) => {
    const { passwordOne } = this.state;

    Auth.doPasswordUpdate(passwordOne)
      .then(() => {
        this.setState({ ...INITIAL_STATE });
      })
      .catch(error => {
        this.setState(byPropKey('error', error));
      });
    
    _Event.preventDefault();
  }

  render() {
    const {
      passwordOne,
      passwordTwo,
      error,
    } = this.state;
    const { classes, } = this.props;

    const isInvalid = 
      passwordOne !== passwordTwo
      || passwordOne === '';
    
    return (
      <form
        className={ classes.padding }>
        <h5
          className={ classes.title }>
          Would you like to change your password?
        </h5>
        <FormControl fullWidth>
          <InputLabel htmlFor='password-change-one'>New Password</InputLabel>
          <Input
            type      = 'password'
            className = {[classes.margin].join(' ')}
            id        = 'password-change-one'
            value     = {passwordOne}
            onChange  = {event => this.setState(byPropKey('passwordOne', event.target.value))}>
          </Input>
        </FormControl>
        <FormControl fullWidth>
          <InputLabel htmlFor='password-change-two'>Confirm New Password</InputLabel>
          <Input
            type      = 'password'
            className = {[classes.margin].join(' ')}
            id        = 'password-change-two'
            value     = {passwordTwo}
            onChange  = {event => this.setState(byPropKey('passwordTwo', event.target.value))}>
          </Input>
        </FormControl>
        <Button
          className = {[classes.margin, classes.button].join(' ')}
          size      = 'large'
          onClick   = {this.onSubmit}
          color     = 'primary'
          disabled  = {isInvalid}>
          Change My Password
        </Button>
        { error && <p>{error.message}</p> }
      </form>
    );
  }
}

export default withStyles(styles)(PasswordChangeForm);