// ------------- DEPENDENCIES -------------- //

import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import {
  Card,
  CardHeader,
  CardContent,
  withStyles,
  Typography,
  Paper,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Input,
  InputAdornment,
} from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import ReactLoading from 'react-loading';

// ------------- LOCAL DEPENDENCIES -------------- //

import withAuthorization from './withAuthorization';
import HomePageHeader from './HomePageHeader';
import HomePageFilterCategories from '../constants/homePageFilterCategories';
import HomePageSortCategories from '../constants/homePageSortCategories';
import { Db } from '../firebase';
import * as Actions from '../reducers/action_types';
import * as Routes from '../constants/routes';
import AssignmentsList from './AssignmentsList';
import * as Utils from '../Utils';
import SnackbarAlert from './SnackbarAlert';
import BulkPayDialog from './BulkPaySupervisorsDialog';

// ------------- LOCAL DEPENDENCIES -------------- //

const styles = ({
  assignmentsListItemDelete: {
    marginRight: '18px',
  },
  assignmentsListItem: {
    padding: '0px 5px 0px 5px !important',
  },
  assignmentsListItemTitle: {
    fontSize    : '17px',
    textOverflow: 'ellipsis',
  },
  assignmentsListItemSubtitle: {
    fontSize    : '10px',
    textOverflow: 'ellipsis',
  },
  dataContainer: {
    width    : '100%',
    padding  : '70px 5% 0px 5%',
    textAlign: 'center',
  },
  cardContainer: {
    margin: '20px',
  },
  title: {
    fontSize: 16,
    color   : '#707070'
  },
  leftAlign: {
    textAlign: 'left !important',
  },
  supervisorName: {
    fontSize: 25,
  },
  supervisorField: {
    margin: 10,
  },
  dateInput: {
    marginLeft  : '0px !important',
    paddingRight: '20px',
  },
  textField: {
    marginLeft : 20,
    marginRight: 20,
    fontSize   : 14,
  },
  filterWrapper: {
    padding: 10,
  },
});

// ------------- MAIN -------------- //

class SupervisorData extends React.Component {
  constructor(props) {
    super(props);
    
    this.state = {
      name                : null,
      id                  : null,
      totalFees           : null,
      fee                 : 0,
      assignments         : null,
      filterStartDate     : null,
      bulkPayOpen         : false,
      bulkPayBalance      : 0,
      excluded            : [],
      filterEndDate       : null,
      clearPenaltiesStatus: null,
      feeUpdateStatus     : null,
      isQuery: false,
      endReached: false,
    }

    this.handleAssignmentClick  = this.handleAssignmentClick.bind(this);
    this.applyFilter            = this.applyFilter.bind(this);
    this.calculateFilteredValue = this.calculateFilteredValue.bind(this);
    this.parseJSON              = this.parseJSON.bind(this);
  }

  /**
   * Downloads supervisor data and relevant assignments from database.
   */
  componentDidMount() {
    const {
      activeSupervisor,
    } = this.props;

    if (activeSupervisor !== null && activeSupervisor !== undefined) {
      this.setState({
        name: activeSupervisor.name,
        id  : activeSupervisor.supervisorID,
        fee : activeSupervisor.supervisorFee,
      });

      Db.doDownloadSupervisorTotalFees(activeSupervisor.supervisorID).then((fees) => {
        console.log(`FEES: ${fees}`);
        this.setState({
          totalFees: fees,
        });
      });

      Db.doDownloadFilteredAssignments([{
        filter: 'supervisorIDs',
        value : activeSupervisor.supervisorID,
        comp  : 'array-contains',
      }]).then((results) => {
        if (results !== null) {
          this.setState({
            assignments: results,
          });
        }
      });
    }
  }

  renderQueryErrorAlert() {
    const { queryError, } = this.state;

    return (
      <SnackbarAlert
        autoHideDuration = {6000}
        onClose          = {() => this.setState({ queryError: null })}
        open             = { queryError !== null }
        message          = { queryError} />
    );
  }

  calculateBulkPayBalance = () => {
    const {
      assignments,
      excluded,
      id,
    } = this.state;

    if (!assignments) { return 0; }

    const filteredAssignments = assignments.filter(a => {
      const supervisorIndex = a.supervisorIDs.findIndex(candidateID => candidateID === id);
      if (supervisorIndex !== -1) {
        const isPaid = a.supervisorsPaid[supervisorIndex];
        return !isPaid && !excluded.includes(a.assignmentID);
      }
      return false;
    });

    let sum = filteredAssignments.reduce((acc, curr) => {
      const supervisorIndex = curr.supervisorIDs.findIndex(candidateID => candidateID === id);
      if (supervisorIndex !== -1) {
        const fee = parseFloat(curr.supervisorFees[supervisorIndex]);
        return acc + fee;
      }
      return acc;
    }, 0);
    return sum;
  }

  openBulkPay = () => {
    this.setState({
      bulkPayOpen: true,
    });
  }

  bulkPaySupervisor = () => {
    const { history } = this.props;
    this.setState({
      bulkPayOpen: false,
    });
    history.push(Routes.SUPERVISOR_LIST);
  }

  /**
   * Handles assignment click event. Sets 'assignmentID' field in
   * global redux state, and switches to AssignmentData page
   * with relevant assignment data.
   *
   * @param {JSON} _Assignment -> Assignment data.
   */
  handleAssignmentClick(_Assignment) {
    const { onSetActiveAssignmentID, history, } = this.props;
    onSetActiveAssignmentID(_Assignment.id);
    history.push(Routes.ASSIGNMENT_DATA);
  }

  /**
   * Calculates total value of assignments stored in local
   * component state.
   *
   * @return {int} -> Total calculated supervisor value.
   */
  calculateFilteredValue() {
    const {
      assignments,
    } = this.state;
    const {
      activeSupervisor,
    } = this.props;
    if (activeSupervisor === null) return 0;

    const parsedAssignments = this.parseJSON(assignments);

    let sum = 0;
    for (let i = 0; i < parsedAssignments.length; i++) {
      const supervisorI = parsedAssignments[i].supervisorIDs.indexOf(activeSupervisor.supervisorID);

      console.log(parsedAssignments[i]);
      if (supervisorI !== -1 && !parsedAssignments[i].supervisorsPaid[supervisorI]) {
        sum += parseFloat(parsedAssignments[i].supervisorFees[supervisorI]);
      }
    }

    console.log(`SUM: ${sum}`);

    return Math.max(0, sum - (activeSupervisor.penalty === undefined ? 0 : activeSupervisor.penalty));
  }

  /**
   * Parses assignments collection data and presents them in an array.
   * Assignment document IDs are added to items with key "id".
   *
   * @param {JSON} _JSON -> Downloaded assignments data.
   *
   * @return {Array<JSON>} -> Parsed assignments data.
   */
  parseJSON(_JSON) {
    const parsedData = [];
    
    for (const key in _JSON) {
      parsedData.push({
        id             : _JSON[key].id,
        startDate      : _JSON[key].startDate,
        dueDate        : _JSON[key].dueDate,
        supervisorsPaid: _JSON[key].supervisorsPaid,
        contractorPaid : _JSON[key].contractorPaid,
        supervisorNames: _JSON[key].supervisorNames,
        contractorName : _JSON[key].contractorName,
        notes          : _JSON[key].notes,
        value          : _JSON[key].value,
        contractorFee  : _JSON[key].contractorFee,
        supervisorFees : _JSON[key].supervisorFees,
        supervisorIDs  : _JSON[key].supervisorIDs,
      });
    }

    return parsedData;
  }

  onQueryError = error => {
    this.setState({
      queryError: error,
    });
  }

  /**
   * Applies date range filter and downloads valid
   * assignments associated with supervisor from database.
   */
  applyFilter() {
    const {
      filterStartDate,
      filterEndDate,
    } = this.state;
    const {
      activeSupervisor,
    } = this.props;

    Db.doDownloadRangedSupervisorAssignments(
      Utils.readableToTimestamp(filterStartDate),
      Utils.readableToTimestamp(filterEndDate),{
        field: 'supervisorIDs',
        value: activeSupervisor.supervisorID,
      }).then((results) => {
      if (results !== null) {
        this.setState({
          assignments: results,
        });
      }
    });
  }

  clearPenalties = () => {
    const {
      activeSupervisor,
      history,
    } = this.props;
    const {
      id,
    } = this.state;

    if (activeSupervisor === null) return;

    Db.doReduceSupervisorPenalty(id, activeSupervisor.penalty + 10).then(() => {
      this.setState({
        clearPenaltiesStatus: true,
      });
      setTimeout(() => {
        history.goBack();
      }, 500);
    }).catch(() => {
      this.setState({
        clearPenaltiesStatus: false,
      });
    });
  };

  onQueryUpdate = (_Results, _IsQuery) => {
    const { activeSupervisor } = this.props;
    if (_Results === null) {
      this.applyFilter();
    } else {
      this.setState({
        assignments: _Results.filter(a => a.supervisorIDs
          .includes(activeSupervisor.supervisorID)) || [],
        isQuery    : _IsQuery,
        endReached : _IsQuery,
      });
    }
  }

  update = () => {
    const { activeSupervisor } = this.props;
    const { fee } = this.state;

    if (fee === null || fee === undefined || isNaN(fee)) {
      this.setState({ feeUpdateStatus: false, });
      return;
    }

    Db.doUpdateSupervisorFee(activeSupervisor.id, fee).then(() => {
      this.setState({ feeUpdateStatus: true, });
    }).catch(err => {
      console.log(err);
      this.setState({ feeUpdateStatus: false, });
    });
  }

  render() {
    const {
      name,
      totalFees,
      id,
      fee,
      assignments,
      filterStartDate,
      filterEndDate,
      clearPenaltiesStatus,
      feeUpdateStatus,
      bulkPayOpen,
      excluded,
    } = this.state;
    const {
      classes,
      activeSupervisor,
    } = this.props;

    return (
      <div className={classes.dataContainer}>
        <div>
          <Card
            className = {classes.cardContainer}
            elevation = {8}>
            <CardHeader
              classes = {{ title: [classes.leftAlign, classes.title].join(' ') }}
              title   = 'Supervisor Data'/>
            <CardContent>
              <Typography
                className = { classes.supervisorName }
                variant   = 'headline'
                component = 'p'>
                {name}
              </Typography>
              <Typography
                className = { classes.supervisorField }
                variant   = 'headline'
                component = 'p'>
                ID: {id}
              </Typography>
              <Typography
                className = { classes.supervisorField }
                variant   = 'headline'
                component = 'p'>
                {`Outstanding Penalties: $${activeSupervisor != null && activeSupervisor.penalty !== undefined ? activeSupervisor.penalty.toFixed(2) : 0}`}
              </Typography>
              <div style={{
                display       : 'flex',
                flexFlow      : 'row nowrap',
                alignItems    : 'center',
                justifyContent: 'center',
              }}>
                <FormControl fullWidth>
                  <InputLabel htmlFor='fee-field'>Fee</InputLabel>
                  <Input
                    endAdornment = {<InputAdornment position="end">%</InputAdornment>}
                    type         = 'number'
                    className    = {[classes.supervisorField, classes.textField].join(' ')}
                    id           = 'fee-field'
                    value        = {fee * 100}
                    onChange     = {evt => this.setState({ fee: (parseInt(evt.target.value) / 100), })}>
                  </Input>
                </FormControl>
                <Button
                  variant='contained'
                  style={{
                    backgroundColor: 'blue',
                    color          : 'white',
                  }}
                  onClick={() => this.update()}>
                    UPDATE
                </Button>
              </div>
              <Typography
                className = { classes.supervisorField }
                variant   = 'headline'
                component = 'p'>
                Total Fees: ${totalFees !== null && totalFees !== undefined ? totalFees.toFixed(2) : 0}
              </Typography>
              <Typography
                className = { classes.contractorField }
                variant   = 'headline'
                component = 'p'>
                Filtered Unpaid Fees: ${this.calculateFilteredValue()}
              </Typography>
              <Button
                className = {[classes.margin, classes.button].join(' ')}
                size      = 'large'
                onClick   = {() => this.openBulkPay()}
                color     = 'primary'
                disabled  = {false}>
                Bulk Pay
              </Button>
              <Button
                variant='contained'
                style={{
                  backgroundColor: 'red',
                  color          : 'white',
                  marginTop      : 20,
                }}
                onClick={this.clearPenalties}>
                CLEAR OUTSTANDING PENALTIES
              </Button>
            </CardContent>
          </Card>
          <Paper
          className={ [classes.filterWrapper, classes.center].join(' ') }>
            <TextField
              id              = "dateStart"
              label           = "Start Date"
              type            = "date"
              className       = {[classes.textField, classes.dateInput].join(' ')}
              InputLabelProps = {{
                shrink: true,
              }}
              value    = {filterStartDate}
              onChange = {evt => this.setState({ filterStartDate: evt.target.value, })}/>
            <TextField
              id              = "dateEnd"
              label           = "End Date"
              type            = "date"
              className       = {[classes.textField, classes.dateInput].join(' ')}
              InputLabelProps = {{
                shrink: true,
              }}
              value    = {filterEndDate}
              onChange = {evt => this.setState({ filterEndDate: evt.target.value, })}/>
            <Button
              className = {[classes.margin, classes.button].join(' ')}
              size      = 'large'
              onClick   = {() => this.applyFilter()}
              color     = 'primary'
              disabled  = {false}>
              Apply
            </Button>
          </Paper>
          <Paper>
            <HomePageHeader
              filterCategories={HomePageFilterCategories}
              sortCategories={HomePageSortCategories}
              onQueryUpdate={this.onQueryUpdate}
              onError={this.onQueryError}
            />
            {
              assignments === null
                ? <ReactLoading
                    type  = 'bars'
                    style = {{
                      margin   : 'auto',
                      width    : '20%',
                      color    : "#29aafc",
                      marginTop: '200px',
                    }}/>
                :                     assignments !== null && assignments.length === 0
                    ? <div>No matching results</div>
                    : <AssignmentsList
                        onItemClick = {this.handleAssignmentClick}
                        assignments = {assignments} />
            }
          </Paper>
        </div>
        <BulkPayDialog
          totalFees       = {`$${this.calculateBulkPayBalance()}`}
          excluded        = {excluded}
          supervisorID    = {id}
          open            = {bulkPayOpen}
          onCancelHandler = {() => this.setState({ bulkPayOpen: false, })}
          onOKHandler     = {this.bulkPaySupervisor} />
        <SnackbarAlert
          open    = { clearPenaltiesStatus !== null }
          message = { clearPenaltiesStatus
            ? `Penalties successfully cleared!`
            : `There was an error, please try again later.` } />
        <SnackbarAlert
          open    = { feeUpdateStatus !== null }
          message = { feeUpdateStatus
            ? `Supervisor fee successfully updated!`
            : `There was an error, please try again later.` } />
          {this.renderQueryErrorAlert()}
      </div>
    );
  }
}

const authCondition = (authUser) => !!authUser;

const mapStateToProps = (_State) => ({
  authUser        : _State.sessionState.authUser,
  activeSupervisor: _State.supervisorState.activeSupervisor,
});

const mapDispatchToProps = (_Dispatch) => ({
  onSetActiveAssignmentID: (activeAssignmentID) => _Dispatch({ type: Actions.ACTIVE_ASSIGNMENT_SET, activeAssignmentID }),
});

export default compose(
  withAuthorization(authCondition),
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
  withStyles(styles),
)(SupervisorData);
